/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'

import { useQuery } from 'react-query'
import api from '../../../services/api'
import { useCompanies } from '../../Companies/useCompanies'
import { ShowError } from '~/utils/errors/apiErrors'
import { TaskListProps } from './tasksStatusModel'
import { useFilterKanban } from './useFilterKanban'
import { useLocale } from '~/hooks/locale/useLocale'
import { useAuth } from '~/hooks/Auth/AuthContext'

interface TaskBreakContextData {
  taskBreak?: TaskListProps[]
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  tasksBreakTotal?: number
  loadDataBreak: () => void
  hasMore: boolean
}

const TaskBreakContext = createContext<TaskBreakContextData>(
  {} as TaskBreakContextData,
)

export const TaskBreakProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { paramsFilter, filterOptions } = useFilterKanban()
  const { locale } = useLocale()
  const { user } = useAuth()

  const [tasksBreakTotal, setTasksBreakTotal] = useState<number>()

  const [hasMore, setHasMore] = useState(true)

  const [limit, setLimit] = useState<number>(20)
  const limitDefault = 5

  const {
    data: taskBreak,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`tarefas pausadas${selectedCompany}`, selectedCompany, filterOptions],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/tasks`, {
          params: {
            offset: 0,
            limit,
            current_status: 'break',
            role: user?.role,
            rolePermissions: user?.rolePermission,
            responsibleContributors: paramsFilter?.responsibleContributors,
            responsibleTeams: paramsFilter?.responsibleTeams,
            address: paramsFilter?.address,
            customers_id: paramsFilter?.customers_id,
            internal_code: paramsFilter?.internal_code,
            external_code: paramsFilter?.external_code,
            task_type_id: paramsFilter?.task_type_id,
            priority: paramsFilter?.priority,
            fromDate: paramsFilter?.fromDate,
            toDate: paramsFilter?.toDate,
          },
        })

        const { data } = response

        setTasksBreakTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, 'Erro ao visualizar o status da tarefa.', locale)
      }
    },
  )

  function loadDataBreak() {
    setLimit(limit + limitDefault)
    setHasMore(false)

    refetch()
  }

  return (
    <TaskBreakContext.Provider
      value={{
        taskBreak,
        isLoading,
        refetch,
        isFetching,
        tasksBreakTotal,
        loadDataBreak,
        hasMore,
      }}
    >
      {children}
    </TaskBreakContext.Provider>
  )
}

export function useTaskBreak(): TaskBreakContextData {
  const context = useContext(TaskBreakContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
