import { FileOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tag, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ActivityTasksProps {
  taskId: string
  created: Date
  status?: string
  plan: string
}

interface ActivityInformationsProps {
  title: string
  system: string
  component: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  initialDate: string
  responsible: {
    id: string
    name: string
  }
  activity: string
  data_font: string
  competence: string
}

interface ActivityProps {
  _id: string
  company_id: string
  activity_id: string
  maintenance_id: string
  task_type_id: string
  is_automatic: boolean
  informations: ActivityInformationsProps
  tasks: ActivityTasksProps[]
}

interface Activity {
  data: ActivityProps
}
const TasksActivityMaintenance: React.FC<Activity> = ({ data }) => {
  const { maintenance, isFetching } = useShowMaintenance()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const columns: ColumnsType<ActivityTasksProps> = [
    {
      title: 'Id da tarefa',
      key: 'taskId',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityTasksProps) => (
        <Typography.Link
          copyable={{ text: record.taskId }}
          href={`/tarefas/visualizar/${record.taskId}`}
        >
          {record.taskId.substring(0, 8)}
        </Typography.Link>
      ),
    },
    {
      title: 'Data de Criação',
      key: 'create',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityTasksProps) => (
        <Typography.Text>
          {format(new Date(record.created), `dd/MM/yyyy`)}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => <Tag color="green">Tarefa Recebida</Tag>,
    },
    {
      title: 'Plano da Atividade',
      key: 'plan',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => <Typography.Text>{maintenance?.name}</Typography.Text>,
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title="Ver tarefas da atividade">
        <Button
          ghost
          type="primary"
          shape="circle"
          icon={<FileOutlined />}
          onClick={showModal}
          size="small"
        />
      </Tooltip>
      <Modal
        title={`${translate('MaintenanceSystemTab.TaskModalTitle')} - ${
          data.informations.title
        }`}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksCreateModalClient.closeButton')}
          </Button>,
        ]}
      >
        <Table
          rowKey={(record) => record?.taskId}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          columns={columns}
          pagination={{
            defaultPageSize: 5,
          }}
          size="middle"
          dataSource={data.tasks}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default TasksActivityMaintenance
