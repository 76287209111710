/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface UserGroup {
  id: string
  user: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface Permissions {
  id: string
  create: boolean
  delete: boolean
  edit: boolean
  view: boolean
  module: {
    id: string
    name: string
  }
}

interface ContribuitorsId {
  id: string
}

interface FilterOptionsProps {
  contributors_id?: ContribuitorsId[]
  type?: string
}

interface GroupProps {
  name: string
  id: string
  type: string
  userGroups: UserGroup[]
  permissions: Permissions[]
}

interface ListGroupContextData {
  group?: GroupProps[]
  createGroup(name: object | undefined): void
  updateGroup(name: object | undefined, groupId: string): void
  deleteGroup(groupId: string): void
  isLoading: boolean
  listGroupsRefetch: () => void
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  isFetching: boolean
  groupsTotal?: number
}

const ListGroupContext = createContext<ListGroupContextData>(
  {} as ListGroupContextData,
)

export const ListGroupProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [groupsTotal, setGroupsTotal] = useState<number>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:groupsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: group,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`Grupos${selectedCompany}`, selectedCompany, filterOptions],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/groups`, {
          params: {
            ...(filterOptions?.contributors_id &&
              filterOptions?.contributors_id?.join() !== '' && {
                contributors_id: filterOptions?.contributors_id?.join(),
              }),
            type: filterOptions?.type,
          },
        })

        const { data } = response

        setGroupsTotal(data.total)

        return data.results
      } catch (err: any) {}
    },
  )

  async function createGroup(name: object) {
    try {
      await api.post(`/company/${selectedCompany}/group`, name)

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListGroup.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateGroup(name: object, groupId: string) {
    try {
      await api.put(`/company/${selectedCompany}/group/${groupId}`, name)

      refetch()
      message.success(translate('useListGroup.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListGroup.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteGroup(groupId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/group/${groupId}`)

      refetch()
      message.success(translate('useListGroup.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListGroup.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contributors_id: newFilterOptions?.contributors_id,
      type: newFilterOptions?.type,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:groupsFilterOptions',
      JSON.stringify(newFilters),
    )
    // setNumberPage(1);
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:groupsFilterOptions')
    setFilterOptions({})
    // setNumberPage(1);
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ListGroupContext.Provider
      value={{
        group,
        createGroup,
        updateGroup,
        deleteGroup,
        isLoading,
        listGroupsRefetch: refetch,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        isFetching,
        groupsTotal,
      }}
    >
      {children}
    </ListGroupContext.Provider>
  )
}

export function useListGroup(): ListGroupContextData {
  const context = useContext(ListGroupContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
