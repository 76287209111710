/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { format } from 'date-fns'
import { translate } from '~/utils/locale'

function TaskPDF(
  task,
  reports,
  type,
  createdBy,
  taskEquipment,
  contributorInfo,
  clientInfo,
  logoURL,
  statusTime,
  formsInfo,
) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  if (task?.responsible?.type === 'team') {
    // eslint-disable-next-line
    contributorInfo = contributorInfo;
  }

  const reportHeader = [
    {
      columns: [
        {
          text: 'RAT_Gstor',
          alignment: 'left',
          fontSize: 9,
          margin: [20, 10, 0, 0],
        },
        {
          text: translate('TasksPrintPDF.headerTitle'),
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0],
        },
      ],
    },
  ]

  const formsData = formsInfo.map((form) => {
    const questionsData = form?.questions.map((question) => {
      return [
        {
          text: question?.title || 'n/a',
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
        {
          text: Array.isArray(question?.answer?.value)
            ? question?.answer?.value.join(' / ')
            : question?.answer?.value || 'n/a',
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
        {
          text: question.answer
            ? `Respondida por: ${
                question?.answer?.collaborator?.contact?.name
              } ${new Date(question?.answer?.updated_at).toLocaleString()}`
            : 'n/a',
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
      ]
    })

    return [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'Título do Formulário: ',
                noWrap: false,
                bold: true,
                fontSize: 10,
                fillColor: '#f3f3f3',
                alignment: 'left',
                margin: [0, 2, 0, 2],
              },
              {
                text: form?.title,
                noWrap: false,
                fontSize: 10,
                color: '#666666',
                fillColor: '#fff',
                alignment: 'left',
                margin: [0, 2, 0, 2],
              },
            ],
          ],
        },
      },
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'Título da Pergunta: ',
                noWrap: false,
                fontSize: 10,
                bold: true,
                fillColor: '#f3f3f3',
                alignment: 'left',
                margin: [0, 2, 0, 2],
              },
              {
                text: 'Resposta: ',
                noWrap: false,
                fontSize: 10,
                bold: true,
                fillColor: '#f3f3f3',
                alignment: 'left',
                margin: [0, 2, 0, 2],
              },
              {
                text: 'Dados da Resposta: ',
                noWrap: false,
                fontSize: 10,
                bold: true,
                fillColor: '#f3f3f3',
                alignment: 'left',
                margin: [0, 2, 0, 2],
              },
            ],
            ...questionsData,
          ],
        },
      },
    ]
  })

  const relatosData = reports.map((report) => {
    if (report?.type === 'notes') {
      return [
        {
          text: translate('TasksPrintPDF.noteText'),
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
        {
          text: report?.title || 'n/a',
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
        {
          text: report?.value,
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
      ]
    }
    if (report?.type === 'image') {
      return [
        {
          text: translate('TasksPrintPDF.archiveText'),
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
        {
          text: report?.title || 'n/a',
          noWrap: false,
          fontSize: 10,
          color: '#666666',
          margin: [0, 2, 0, 2],
        },
        {
          ...(report?.value
            ? { image: report?.value }
            : { text: 'Falha ao carregar a imagem' }),
          width: 150,
          margin: [0, 2, 0, 2],
        },
      ]
    }
    return [
      {
        text: translate('TasksPrintPDF.archiveText'),
        noWrap: false,
        fontSize: 10,
        color: '#666666',
        margin: [0, 2, 0, 2],
      },
      {
        text: report?.title || 'n/a',
        noWrap: false,
        fontSize: 10,
        color: '#666666',
        margin: [0, 2, 0, 2],
      },
      {
        text: report?.value,
        noWrap: false,
        fontSize: 10,
        color: '#666666',
        margin: [0, 2, 0, 2],
      },
    ]
  })

  const equipamentosData = taskEquipment.map((equipment) => {
    return [
      {
        text: equipment?.name,
        noWrap: false,
        fontSize: 10,
        color: '#666666',
        margin: [0, 2, 0, 2],
      },
      {
        text: equipment?.identifier,
        noWrap: false,
        fontSize: 10,
        color: '#666666',
        margin: [0, 2, 0, 2],
      },
      {
        text: equipment?.category?.title,
        noWrap: false,
        fontSize: 10,
        color: '#666666',
        margin: [0, 2, 0, 2],
      },
    ]
  })

  const reportContent = [
    {
      table: {
        headerRows: 1,
        widths: [50, '*'],
        body: [
          [
            logoURL && {
              image: logoURL,
              width: 50,
            },
            {
              text: translate('TasksPrintPDF.contentTitle'),
              bold: true,
              noWrap: false,
              fontSize: 16,
              fillColor: '#cfe2f3',
              alignment: 'center',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: ' ',
              fontSize: 10,
              fillColor: '#fff',
              // alignment: 'center',
              margin: [0, 2, 0, 2],
            },
            {
              text: ' ',
              fontSize: 10,
              fillColor: '#fff',
              // alignment: 'center',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['auto', '*', 'auto', '*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.serviceNumber'),
              noWrap: false,
              bold: true,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?._id,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            }, // dado
            {
              text: translate('TasksPrintPDF.externalCode'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.external_code,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.taskDate'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: format(
                new Date(task?.date),
                `dd/MM/yyyy '${translate(
                  'TasksPrintPDF.dateString',
                )}' HH:mm'h'`,
              ),
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.priorityText'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text:
                task?.priority === 'low'
                  ? translate('TasksPrintPDF.priorityLow')
                  : task?.priority === 'medium'
                    ? translate('TasksPrintPDF.priorityMedium')
                    : translate('TasksPrintPDF.priorityHigh'),
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.createdBy'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: createdBy,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.taskType'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: type?.title,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.durationTime'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.duration_time,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.toleranceTime'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: type?.tolerance_time,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['auto', '*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.statusText'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: statusTime,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: ' ',
              noWrap: false,
              fontSize: 10,
              fillColor: '#fff',
              alignment: 'center',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.responsibleData'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.clientData'),
              bold: true,
              noWrap: false,
              fontSize: 10,
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['auto', '*', 'auto', '*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.companyName'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: contributorInfo?.company?.name,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.clientName'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: clientInfo?.contact?.name,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.addressText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: !contributorInfo?.contact?.contactAddresses
                ? ''
                : contributorInfo?.contact?.contactAddresses[0]
                  ? `${contributorInfo?.contact?.contactAddresses[0]?.address?.street}, ${contributorInfo?.contact?.contactAddresses[0]?.address?.number}`
                  : '',
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.addressText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: clientInfo?.contact?.contactAddresses[0]
                ? `${clientInfo?.contact?.contactAddresses[0]?.address?.street} ,${clientInfo?.contact?.contactAddresses[0]?.address?.number}`
                : '',
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.cnpjText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: contributorInfo?.contact?.cnpj,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.cnpjText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: clientInfo?.contact?.cnpj,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.contributorName'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: contributorInfo?.contact?.name,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.responsibleText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: clientInfo?.collaborator?.contact?.name,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.phoneText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: contributorInfo?.contact?.phone,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.phoneText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: clientInfo?.contact?.phone,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.emailText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: contributorInfo?.contact?.email,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.emailText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: clientInfo?.contact?.email,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: ' ',
              noWrap: false,
              fontSize: 10,
              fillColor: '#fff',
              alignment: 'center',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.taskAddress'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['auto', '*', 'auto', '*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.streetText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.address?.street,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.neighborhoodText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.address?.neighborhood,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.numberText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.address?.number,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.complementText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.address?.complement,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.cityText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.address?.city,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            }, // dado
            {
              text: translate('TasksPrintPDF.stateText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.address?.state,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          [
            {
              text: translate('TasksPrintPDF.zipcodeText'),
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: task?.address?.zip_code,
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            }, // dado
            {
              text: ' ',
              noWrap: false,
              fontSize: 10,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            }, // titulo
            {
              text: ' ',
              noWrap: false,
              fontSize: 10,
              color: '#666666',
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            }, // dado
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: ' ',
              noWrap: false,
              fontSize: 10,
              fillColor: '#fff',
              alignment: 'center',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.taskEquipments'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.equipmentName'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.equipmentIdentifier'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.equipmentCategory'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          ...equipamentosData,
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: ' ',
              noWrap: false,
              fontSize: 10,
              fillColor: '#fff',
              alignment: 'center',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.taskReportsText'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        dontBreakRows: true,
        keepWithHeaderRows: 1,
        widths: ['auto', 'auto', '*'],
        body: [
          [
            {
              text: translate('TasksPrintPDF.taskReportsType'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.taskReportsTitle'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
            {
              text: translate('TasksPrintPDF.taskReportsContent'),
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#f3f3f3',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
          ...relatosData,
        ],
      },
    },
    {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [
            {
              text: 'Formulários da Tarefa',
              noWrap: false,
              fontSize: 10,
              bold: true,
              fillColor: '#fff',
              alignment: 'left',
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
    },
    ...formsData.flat(),
  ]

  function reportFooter(currentPage, pageCount) {
    return [
      {
        columns: [
          {
            text: new Date().toLocaleString(),
            alignment: 'left',
            fontSize: 9,
            margin: [20, 10, 0, 0],
          },
          {
            text: translate('TasksPrintPDF.footerTitle'),
            alignment: 'center',
            fontSize: 9,
            margin: [20, 10, 20, 0],
          },
          {
            text: `${translate(
              'TasksPrintPDF.footerPageOne',
            )} ${currentPage} ${translate(
              'TasksPrintPDF.footerPageTwo',
            )} ${pageCount}`,
            alignment: 'right',
            fontSize: 9,
            margin: [0, 10, 20, 0],
          },
        ],
      },
    ]
  }

  const docRules = {
    pageSize: 'A4',
    pageMargins: [15, 50, 15, 40],

    header: [reportHeader],
    content: [reportContent],
    footer: reportFooter,
  }

  pdfMake
    .createPdf(docRules)
    .download(`RAT_gstor_${new Date().toLocaleString()}`)
}

export default TaskPDF
