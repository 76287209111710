import React from 'react'

import { Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { useHistory, useParams } from 'react-router-dom'

import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import DetailsMaintenanceTabs from './Details'
// import PlanningMaintenanceTableTabs from './PlanningTable'

import UpdateMaintenance from './Update'
import { translate } from '~/utils/locale'
import ActivityTab from './Activity'
import DocumentsTab from './Documents'
import PlanningMaintenanceTableTabs from './PlanningTable'
import CreateActivityMaintenance from './Activity/Create'

const MaintenanceTabs: React.FC = () => {
  const { maintenance, isEdit, isCreate } = useShowMaintenance()
  const { maintenanceId, tab } = useParams<{
    maintenanceId: string
    tab: string
  }>()

  const history = useHistory()

  const { TabPane } = Tabs

  const handleTabChange = (key: string) => {
    const path = window.location.pathname.split('/')

    if (key === 'detalhes') {
      history.replace({
        pathname: `/manutencao/${path[2]}/${maintenanceId}/`,
      })
    } else {
      history.replace({
        pathname: `/manutencao/${path[2]}/${maintenanceId}/${key}`,
      })
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('MaintenanceDetailsTab.MaintenanceManagement')}
        subTitle={maintenance?.customer?.name}
      />
      <Tabs
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane
          tab={translate('MaintenanceDetailsTab.Details')}
          key="detalhes"
        >
          {!isEdit ? <DetailsMaintenanceTabs /> : <UpdateMaintenance />}
        </TabPane>
        <TabPane tab="Documentos" key="databook">
          <DocumentsTab />
        </TabPane>
        <TabPane
          tab={translate('MaintenanceSystemTab.ActivityTab')}
          key="atividades"
        >
          {isCreate ? <ActivityTab /> : <CreateActivityMaintenance />}
        </TabPane>
        <TabPane
          tab={translate('MaintenancePlanningTableTab.planningTable')}
          key="planejamento"
        >
          <PlanningMaintenanceTableTabs />
        </TabPane>
      </Tabs>
    </>
  )
}

export default MaintenanceTabs
