/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import {
  Button,
  Modal,
  Space,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Tooltip,
  Radio,
  Select,
} from 'antd'

import React, { useState } from 'react'

import { translate } from '~/utils/locale'

import dayjs from 'dayjs'
import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import { useDatabook } from '~/hooks/Maintenance/Databook/useDatabok'

interface DocumentsProps {
  _id: string
  name: string
  provided_responsible: string
  renovation_responsible: string
  inclusion_date: Date
  renovation_date: Date
  attchment: string
  is_exclusive: boolean
  plan_id: string
  tag: string
}

interface Documents {
  data: DocumentsProps
}

const UpdateDatabookModal: React.FC<Documents> = ({ data }) => {
  const { updateDatabook } = useDatabook()
  const { maintenance, isLoading } = useMaintenance()

  const [isExclusive, setIsExclusive] = useState(!!data.is_exclusive)

  function onChangeRadio(value: any) {
    if (value.target.value === 'yes') {
      setIsExclusive(true)
    } else {
      setIsExclusive(false)
    }
  }

  const initialValues = {
    name: data.name,
    provided_responsible: data.provided_responsible,
    renovation_responsible: data.renovation_responsible,
    inclusion_date: dayjs(data.inclusion_date),
    renovation_date: dayjs(data.renovation_date),
    attchment: data.attchment,
    is_exclusive: data.is_exclusive ? 'yes' : 'no',
    plan_id: data.plan_id,
    tag: data.tag,
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  function AddDocument(valueForm: any) {
    const dataForm = {
      name: valueForm.name,
      provided_responsible: valueForm.provided_responsible,
      renovation_responsible: valueForm.renovation_responsible,
      inclusion_date: valueForm.inclusion_date,
      renovation_date: valueForm.renovation_date,
      attchment: valueForm.attchment,
      is_exclusive: isExclusive,
      plan_id: isExclusive ? valueForm.plan_id : 'undefined',
      tag: valueForm.tag,
    }

    try {
      updateDatabook(dataForm, data._id)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  return (
    <>
      <Tooltip title="Editar Documento">
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          type="primary"
          icon={<EditOutlined />}
        />
      </Tooltip>

      <Modal
        title={translate('MaintenanceDocumentsTab.AddDocuments')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="1000px"
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              form="updateDocumentMainForm"
              htmlType="submit"
            >
              {translate('MaintenanceDocumentsTab.Save')}
            </Button>
          </Space>,
        ]}
      >
        <Form
          layout="vertical"
          id="updateDocumentMainForm"
          onFinish={AddDocument}
          initialValues={initialValues}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.DocumentName')}
                name="name"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterDocumentName',
                  )}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.DocumentLink')}
                name="attchment"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterDocumentLink',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'MaintenanceDocumentsTab.SupplyResponsibility',
                )}
                name="provided_responsible"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterSupplyResponsibility',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'MaintenanceDocumentsTab.RenewalResponsibility',
                )}
                name="renovation_responsible"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterRenewalResponsibility',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.InclusionDate')}
                name="inclusion_date"
              >
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.RenewalDate')}
                name="renovation_date"
              >
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item label="Tag" name="tag">
                <Input placeholder="Informe a tag" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="Documento exclusivo de algum plano de manutençao?"
                name="is_exclusive"
              >
                <Radio.Group onChange={onChangeRadio}>
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {isExclusive ? (
              <Col span={12}>
                <Form.Item label="Escolha o plano" name="plan_id">
                  <Select
                    placeholder="Selecione o plano"
                    style={{ width: '100%' }}
                    loading={isLoading}
                  >
                    {maintenance?.map((m) => (
                      <Select.Option value={m._id} key={m._id}>
                        {m.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateDatabookModal
