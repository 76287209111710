/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Col, Form, Modal, Row, Select, Space, Tooltip } from 'antd'
import React, { useState } from 'react'

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'

import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'
import { translate } from '~/utils/locale'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'

// import { Container } from './styles';

interface ContactsProps {
  activityTaskId: string
}

const CreateContactsPlanning: React.FC<ContactsProps> = ({
  activityTaskId,
}) => {
  const { createContacts } = useActivityTasks()
  const { data } = useContributorsTask()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const [responsible, setResponsible] = useState<{
    id: string
    name: string
  } | null>()

  function AddContact(form: any) {
    const dataContact = {
      contacts: {
        phone: form.phone.replace(/[^0-9]/g, ''),
        responsible_id: responsible?.id,
        responsible_name: responsible?.name,
      },
    }

    try {
      createContacts(activityTaskId, dataContact)

      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }
  return (
    <>
      <Tooltip title="Adicionar Contato">
        <Button onClick={showModal} icon={<EditOutlined />} type="primary">
          Adicionar Contato
        </Button>
      </Tooltip>
      <Modal
        title="Adicionar novo contato"
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space size="middle" key="footer">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="addContactToPlanningTask"
            >
              Salvar
            </Button>
          </Space>,
        ]}
      >
        <Form
          layout="vertical"
          id="addContactToPlanningTask"
          onFinish={AddContact}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="Colaborador" name="responsible">
                <Select
                  placeholder="Informe o colaborador"
                  onChange={(value) => {
                    const selectedItem = data?.find((c) => c.id === value)
                    if (selectedItem) {
                      setResponsible({
                        id: selectedItem.id,
                        name: selectedItem.contact.name,
                      })
                    } else {
                      setResponsible(null)
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  {data?.map((c) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Número do Celular"
                name="phone"
                rules={[
                  () => ({
                    validator(rule, phone) {
                      if (phone === undefined || phone === null) {
                        return Promise.resolve()
                      }
                      if (phone && isValidPhoneNumber(phone)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(translate('NewClients.phoneRuleMessage')),
                      )
                    },
                  }),
                ]}
              >
                <PhoneInput
                  placeholder={translate('NewClients.phonePlaceholder')}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default CreateContactsPlanning
