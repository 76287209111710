/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import { Drawer, Form, Button, Col, Row, Input, Tooltip, Select } from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { translate } from '~/utils/locale'

import { useDatabook } from '~/hooks/Maintenance/Databook/useDatabok'
import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'

const DocumentsFilterModal: React.FC = () => {
  const { handleUpdateFilters, handleResetFilters, filterOptions, databook } =
    useDatabook()

  const { maintenance, isLoading } = useMaintenance()

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      name: filterOptions?.name,
      rovided_responsible: filterOptions?.provided_responsible,
      renovation_responsible: filterOptions?.renovation_responsible,
      plan_id: filterOptions?.plan_id,
      tag: filterOptions?.tag,
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!databook}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('TaskTypesFilter.cleanFilterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_documents_maintenance"
      >
        {isFilter
          ? translate('MaintenanceDocumentsTab.AppliedFilters')
          : translate('MaintenanceDocumentsTab.Filters')}
      </Button>
      <Drawer
        forceRender
        title={translate('MaintenanceDocumentsTab.DocumentFilters')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.DocumentName')}
                name="name"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterDocumentName',
                  )}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Tag" name="tag">
                <Input placeholder="Informe a tag" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'MaintenanceDocumentsTab.SupplyResponsibility',
                )}
                name="provided_responsible"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterSupplyResponsibility',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate(
                  'MaintenanceDocumentsTab.RenewalResponsibility',
                )}
                name="renovation_responsible"
              >
                <Input
                  placeholder={translate(
                    'MaintenanceDocumentsTab.EnterRenewalResponsibility',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item label="Plano de Manutenção" name="plan_id">
                <Select
                  placeholder="Selecione o plano"
                  style={{ width: '100%' }}
                  loading={isLoading}
                >
                  {maintenance?.map((m) => (
                    <Select.Option value={m._id} key={m._id}>
                      {m.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.InclusionDate')}
                name="inclusion_date"
              >
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceDocumentsTab.RenewalDate')}
                name="renovation_date"
              >
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('MaintenanceDocumentsTab.Apply')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('MaintenanceDocumentsTab.Clear')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default DocumentsFilterModal
