/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CopyOutlined,
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import React from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'

import { ColumnsType } from 'antd/lib/table'
import { translate } from '~/utils/locale'
import { format } from 'date-fns'

import Text from 'antd/lib/typography/Text'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'
import CreateTaskToActivity from './Tasks/Create'
import TasksActivityMaintenance from './Tasks'
import FilterActivityTasks from './Filter'
import UpdateActivityTasks from './Update'

interface ActivityTasksProps {
  taskId: string
  created: Date
  status?: string
  plan: string
}

interface ActivityInformationsProps {
  title: string
  system: string
  component: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  initialDate: string
  responsible: {
    id: string
    name: string
  }
  activity: string
  data_font: string
  competence: string
}

interface ActivityProps {
  _id: string
  company_id: string
  activity_id: string
  maintenance_id: string
  task_type_id: string
  is_automatic: boolean
  informations: ActivityInformationsProps
  tasks: ActivityTasksProps[]
}

const ActivityTab: React.FC = () => {
  const { isCreate, setIsCreate } = useShowMaintenance()

  const { isLoading, activityTasks, deleteActivityTask } = useActivityTasks()

  if (isLoading || !activityTasks) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id).then(() => {
      message.success(translate('ContributorTable.copyText'))
    })
  }

  const columns: ColumnsType<ActivityProps> = [
    {
      title: 'ID da Atividade',
      align: 'left',
      dataIndex: 'activity_id',
      key: 'id',
      // defaultSortOrder: 'ascend',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
      render: (id: string) => (
        <Tooltip title={id}>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => handleCopy(id)}
            style={{ padding: 0, height: 'auto' }}
          >
            <Typography.Text ellipsis>{`${id.substring(
              0,
              5,
            )}...`}</Typography.Text>
          </Button>
        </Tooltip>
      ),
    },
    {
      title: translate('MaintenanceSystemTab.Title'),
      key: 'title',
      dataIndex: ['informations', 'title'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (title: string) =>
        title || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'system',
      dataIndex: ['informations', 'system'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (system: string) =>
        system || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceActivity.SelectComponents'),
      key: 'component',
      dataIndex: ['informations', 'component'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (component: string) =>
        component || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Activities'),
      key: 'activity',
      dataIndex: ['informations', 'activity'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (activity: string) =>
        activity || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Competence'),
      dataIndex: ['informations', 'competence'],
      key: 'competence',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (competence: string) =>
        competence || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Responsibles'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Typography.Text>
          {record?.informations?.responsible?.name
            ? record?.informations?.responsible?.name
            : 'Nenhum responsável informado.'}
        </Typography.Text>
      ),
    },
    {
      title: translate('MaintenanceActivity.InitialDate'),
      key: 'initialDate',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Typography.Text>
          {record.informations.initialDate
            ? format(new Date(record?.informations.initialDate), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Typography.Text>
      ),
    },
    {
      title: translate('MaintenanceSystemTab.Periodicity'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityProps) => (
        <>
          {record.informations.periodicity?.type === 'day' ? (
            <>
              {record.informations.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Day')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Days')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.informations.periodicity?.type === 'sem' ? (
            <>
              {record.informations.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Week')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Weeks')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.informations.periodicity?.type === 'men' ? (
            <>
              {record.informations.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Months')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.MonthsPlural')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.informations.periodicity?.type === 'anu' ? (
            <>
              {record.informations.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Year')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.informations.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Years')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Criação de Tarefas',
      key: 'is_automatic',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Typography.Text>
          {record.is_automatic ? (
            <Tag color="geekblue">Automáticas</Tag>
          ) : (
            <Tag color="purple">Manual</Tag>
          )}
        </Typography.Text>
      ),
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record: ActivityProps) => (
        <Space size="small">
          {record.is_automatic === false ? (
            <CreateTaskToActivity data={record} type="button" />
          ) : (
            ''
          )}
          <TasksActivityMaintenance data={record} />
          <UpdateActivityTasks dataActivity={record} />
          <Popconfirm
            title={translate('MaintenanceSystemTab.ConfirmDeleteActivity')}
            onConfirm={() => deleteActivityTask(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button
                ghost
                danger
                shape="circle"
                icon={<DeleteFilled />}
                size="small"
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Card
      bordered={false}
      title={translate('MaintenanceSystemTab.ActivityTab')}
      extra={
        isCreate ? (
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsCreate(false)}
            >
              Adicionar atividade
            </Button>
            <FilterActivityTasks />
          </Space>
        ) : (
          ''
        )
      }
      style={{
        display: 'inline-block',
        width: '100%',
        marginTop: 15,
      }}
    >
      <Table
        rowKey={(record) => record?._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        size="small"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={activityTasks}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </Card>
  )
}

export default ActivityTab
