import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 0.8rem;
  .tag {
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 12px;
  }
`
