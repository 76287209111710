import React from 'react'

import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { translate } from '~/utils/locale'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
// import { Container } from './styles';

interface IExportPlanningReportTask {
  selectedMonth: dayjs.Dayjs
}
const ExportPlanningReportTask: React.FC<IExportPlanningReportTask> = ({
  selectedMonth,
}) => {
  const { exportPlanningReportTasks, urlDownload, loading, text } =
    useActivityTasks()

  const { maintenanceId } = useParams<{ maintenanceId: string }>()

  return (
    <Button
      type="primary"
      key="export"
      icon={<DownloadOutlined />}
      loading={loading}
      onClick={() => exportPlanningReportTasks(selectedMonth, maintenanceId)}
      href={urlDownload}
      target="_blank"
      rel="noopener noreferrer"
      download
      danger={text === translate('ReportTasksExport.downloadText')}
    >
      {text}
    </Button>
  )
}

export default ExportPlanningReportTask
