/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusCircleOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input, Checkbox, Space } from 'antd'
import React, { useState } from 'react'
import { useTicketType } from '~/hooks/HelpDesk/useTicketType'

import { translate } from '~/utils/locale'
import { ContainerButton } from './styles'

const CreateTicketType: React.FC = () => {
  const { createTicketType } = useTicketType()

  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [keepRegister, setKeepRegister] = useState(false)

  function onCreateTicketType(valueForm: any) {
    try {
      createTicketType(valueForm)

      form.resetFields()

      if (!keepRegister) {
        setIsModalVisible(false)
      }
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" icon={<PlusCircleOutlined />} onClick={showModal}>
        {translate('TicketTypeCreate.addButton')}
      </Button>
      <Modal
        title={translate('TicketTypeCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={[
          <Space key="buttons">
            <Checkbox
              key="KeepRegister"
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('TicketTypeCreate.keepRegisterText')}
            </Checkbox>
            <Button key="back" onClick={closeModal}>
              {translate('TicketTypeCreate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="pauseForm"
                htmlType="submit"
                id="btn_create_ticket_type"
              >
                {translate('TicketTypeCreate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          id="pauseForm"
          layout="vertical"
          onFinish={onCreateTicketType}
          form={form}
        >
          <Form.Item
            label={translate('TicketTypeCreate.titleLabel')}
            name="title"
            rules={[
              {
                required: true,
                message: translate('TicketTypeCreate.titleRule'),
              },
            ]}
          >
            <Input
              placeholder={translate('TicketTypeCreate.titlePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            label={translate('TicketTypeCreate.descriptionLabel')}
            name="description"
            rules={[
              {
                required: true,
                message: translate('TicketTypeCreate.descriptionRule'),
              },
            ]}
          >
            <Input.TextArea placeholder="Informe a descrição" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateTicketType
