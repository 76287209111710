/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
/* eslint-disable consistent-return */
import { format, parseISO } from 'date-fns'
import dayjs from 'dayjs'
import React, {
  useContext,
  createContext,
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
  useCallback,
} from 'react'
import { useQuery } from 'react-query'
import { useCompanies } from '~/hooks/Companies/useCompanies'

import api from '~/services/api'

import { translate } from '~/utils/locale'
import { useTaskCreate } from './createTask'

interface CompanyData {
  id: string
  name: string
}

interface Scale {
  company: CompanyData
  contributorsSchedules: any[]
  created_at: string
  customersSchedules: any[]
  id: number
  scheduleTimes: any[]
  dateRange?: string
  title: string
}

interface FilterOptionsProps {
  contactCustomer?: string
  contactCollaborator?: string
  title?: number
  fromDay?: dayjs.Dayjs
  toDay?: dayjs.Dayjs
}

interface ScaleListTaskContextData {
  scalesRefetch: () => void
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  scalesData: Scale[]
  scalesTotal: number
  setPageSize: Dispatch<SetStateAction<number>>
  setNumberPage: Dispatch<SetStateAction<number>>
  filterOptions: FilterOptionsProps
  isLoading: boolean
  isFetching: boolean
}

const ScaleListTaskContext = createContext<ScaleListTaskContextData>(
  {} as ScaleListTaskContextData,
)

export const ScaleListTaskProvider: React.FC = ({ children }) => {
  const { clientData } = useTaskCreate()

  const { selectedCompany } = useCompanies()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:scalesTaskFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })
  const [scalesTotal, setScalesTotal] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [numberPage, setNumberPage] = useState(1)

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['scales task', selectedCompany, pageSize, numberPage, filterOptions],
    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/schedules`,
          {
            params: {
              limit: pageSize,
              offset: paginationOffset,
              contactCustomer: clientData?.name,
              contactCollaborator: filterOptions?.contactCollaborator,
              title: filterOptions?.title,
              fromDay: filterOptions?.fromDay?.toString(),
              toDay: filterOptions?.toDay?.toString(),
            },
          },
        )

        const scales = response.data.results

        scales.forEach((scale: Scale) => {
          // eslint-disable-next-line func-names
          if (scale.scheduleTimes.length > 0) {
            scale.scheduleTimes.sort(function (a: any, b: any) {
              return Date.parse(a.day) - Date.parse(b.day)
            })

            const firstValue = parseISO(scale.scheduleTimes[0].day)
            const lastValue = parseISO(
              scale.scheduleTimes[scale.scheduleTimes.length - 1].day,
            )
            const stringFirstValue = format(firstValue, 'dd/MM/yyyy')
            const stringLastValue = format(lastValue, 'dd/MM/yyyy')

            // eslint-disable-next-line
            scale.dateRange = `${translate('useListScales.dateRangeOne')} ${stringFirstValue} - ${stringLastValue}`;
          } else {
            // eslint-disable-next-line
            scale.dateRange = translate('useListScales.dateRangeTwo');
          }
        })

        setScalesTotal(response.data.total)

        return scales
      } catch (err: any) {
        console.log('')
      }
    },
    {
      staleTime: 5000, // 5 segundos
    },
  )

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:scalesTaskFilterOptions')
    setFilterOptions({})
    setNumberPage(1)
  }, [])

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contactCustomer: newFilterOptions?.contactCustomer,
      contactCollaborator: newFilterOptions?.contactCollaborator,
      title: newFilterOptions?.title,
      fromDay: dayjs(newFilterOptions?.fromDay).startOf('day'),
      toDay: dayjs(newFilterOptions?.toDay).endOf('day'),
    }

    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:scalesTaskFilterOptions',
      JSON.stringify(newFilters),
    )
    setNumberPage(1)
  }, [])

  return (
    <ScaleListTaskContext.Provider
      value={{
        scalesData: data,
        filterOptions,
        scalesTotal,
        setNumberPage,
        setPageSize,
        handleUpdateFilters,
        handleResetFilters,
        isLoading,
        scalesRefetch: refetch,
        isFetching,
      }}
    >
      {children}
    </ScaleListTaskContext.Provider>
  )
}

export function useScaleListTask(): ScaleListTaskContextData {
  const context = useContext(ScaleListTaskContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
