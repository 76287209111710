export default {
  pt: {
    MaintenanceTabsAcitivtyView: {
      iconTooltip: 'Tarefas criadas automaticamente',
    },
  },
  en: {
    MaintenanceTabsAcitivtyView: {
      iconTooltip: 'Automatically created tasks',
    },
  },
  es: {
    MaintenanceTabsAcitivtyView: {
      iconTooltip: 'Tareas creadas automáticamente',
    },
  },
}
