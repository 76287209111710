/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { message } from 'antd'
import moment from 'moment'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface EquipmentsProps {
  id: string
  name: string
  created_at: Date
  expiration_date: Date
  identifier: number
  is_enabled: boolean
  category: {
    id: string
    title: string
  }
}

interface EquipmentId {
  id: string
}

interface FilterOptionsProps {
  equipments_id?: EquipmentId[]
  collaborator_id?: string
  customer_id?: string
  category?: string
  is_enabled?: boolean
  fromExpirationDate?: string
  toExpirationDate?: string
}

interface ListEquipmentsContextData {
  listEquipments?: EquipmentsProps[]
  createEquipment(
    dataEquipment: object | undefined,
    keepRegister: boolean,
  ): void
  deleteEquipment(equipmentId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  filterOptions: FilterOptionsProps
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  setPageSize(value: any): void
  setNumberPage(value: any): void
  equipmentsTotal?: number
}

const ListEquipmentsContext = createContext<ListEquipmentsContextData>(
  {} as ListEquipmentsContextData,
)

export const ListEquipmentsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const history = useHistory()

  const [pageSize, setPageSize] = useState<number>(10)
  const [numberPage, setNumberPage] = useState<number>(1)

  const [equipmentsTotal, setEquipmentsTotal] = useState<number>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:equipmentsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: listEquipments,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `lista de equipamentos${selectedCompany}`,
      selectedCompany,
      numberPage,
      pageSize,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/equipments`,
          {
            params: {
              offset: paginationOffset,
              limit: pageSize,
              ...(filterOptions?.equipments_id &&
                filterOptions?.equipments_id?.join() !== '' && {
                  equipments_id: filterOptions?.equipments_id?.join(),
                }),
              collaborator_id: filterOptions?.collaborator_id,
              customer_id: filterOptions?.customer_id,
              category: filterOptions?.category,
              is_enabled: filterOptions?.is_enabled,
              ...(filterOptions?.fromExpirationDate &&
                filterOptions?.fromExpirationDate !== '' && {
                  fromExpirationDate: moment
                    .utc(filterOptions?.fromExpirationDate)
                    .format(),
                }),
              ...(filterOptions?.toExpirationDate &&
                filterOptions?.toExpirationDate !== '' && {
                  toExpirationDate: moment
                    .utc(filterOptions?.toExpirationDate)
                    .format(),
                }),
            },
          },
        )

        const { data } = response

        setEquipmentsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('ListEquipmentsHooks.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createEquipment(dataEquipment: object, keepRegister: boolean) {
    try {
      const response = await api.post(
        `/company/${selectedCompany}/equipment`,
        dataEquipment,
      )

      refetch()
      message.success(translate('ListEquipmentsHooks.createSuccessMessage'))

      if (!keepRegister) {
        history.push(
          `/equipamentos/visualizar/${response.data.id}/equipamento/`,
        )
      }
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ListEquipmentsHooks.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteEquipment(equipmentId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/equipment/${equipmentId}`)

      refetch()
      message.success(translate('ListEquipmentsHooks.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('ListEquipmentsHooks.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      equipments_id: newFilterOptions?.equipments_id,
      collaborator_id: newFilterOptions?.collaborator_id,
      customer_id: newFilterOptions?.customer_id,
      category: newFilterOptions?.category,
      is_enabled: newFilterOptions?.is_enabled,
      fromExpirationDate: newFilterOptions?.fromExpirationDate,
      toExpirationDate: newFilterOptions?.toExpirationDate,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:equipmentsFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:equipmentsFilterOptions')
    setFilterOptions({})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch, filterOptions])

  return (
    <ListEquipmentsContext.Provider
      value={{
        listEquipments,
        createEquipment,
        deleteEquipment,
        isLoading,
        refetch,
        isFetching,
        filterOptions,
        handleResetFilters,
        handleUpdateFilters,
        setPageSize,
        setNumberPage,
        equipmentsTotal,
      }}
    >
      {children}
    </ListEquipmentsContext.Provider>
  )
}

export function useListEquipments(): ListEquipmentsContextData {
  const context = useContext(ListEquipmentsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
