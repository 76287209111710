/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface AddressProps {
  _id: string
  zip_code: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  id: string
  state: string
  location: { x: number; y: number }
}

interface Financial {
  task_value?: number
  amount_hours: number
  amount_hours_additional?: number
  number_km: number
  number_km_additional?: number
}

interface FinancialReceive {
  receive?: number
  amount_hours_receive: number
  amount_hours_additional_receive?: number
  number_km_receive: number
  number_km_additional_receive?: number
}

interface Task {
  _id: string
  internal_code: string
  financial: Financial
  financialReceive: FinancialReceive
  responsible?: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
      title: string
    }
  }
}

interface ContactProps {
  name: string
  email: string
  celular: string
  phone: string
  _id: string
}

export interface ParentClientsProps {
  _id: string
  cnpj: string
  cpf: string
  fantasyName: string
  companyName: string
  municipal_registration?: string
  state_registration?: string
  contact: ContactProps
  address: AddressProps
  financial: Financial
  tasks: Task[]
}

interface FilterOptionsProps {
  fantasyName?: string
}

interface ListParentClientsContextData {
  parentClients?: ParentClientsProps[]
  createParentClients(dataClient: object | undefined): void
  deleteParentClient(customerId: string): void
  deleteParentClients(customerId: string): void
  updateChildrenParentClient(dataClient: object, customerId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  setPageSize(value: any): void
  setNumberPage(value: any): void
  clientsTotal?: number
  companyName?: string
  setCompanyName(value: string): void
  cnpjText?: string
  setCnpjText(value: string): void
  cpfText?: string
  setCpfText(value: string): void
  viewValues?: string
  setViewValues(value: string): void
}

const ListParentClientsContext = createContext<ListParentClientsContextData>(
  {} as ListParentClientsContextData,
)

export const ListParentClientsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [pageSize, setPageSize] = useState<number>(10)
  const [numberPage, setNumberPage] = useState<number>(1)

  const [clientsTotal, setClientsTotal] = useState<number>()

  const [viewValues, setViewValues] = useState('receive')

  const [companyName, setCompanyName] = useState('')
  const [cnpjText, setCnpjText] = useState('')
  const [cpfText, setCpfText] = useState('')

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:parentClientsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: parentClients,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `lista de clientes principais${selectedCompany}`,
      selectedCompany,
      numberPage,
      pageSize,
      filterOptions,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(
          `/company/${selectedCompany}/parentCustomers`,
          {
            params: {
              offset: paginationOffset,
              limit: pageSize,
              fantasyName: filterOptions?.fantasyName,
            },
          },
        )

        const { data } = response

        setClientsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListClients.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createParentClients(dataClient: object) {
    try {
      await api.post(`/company/${selectedCompany}/parentCustomer`, dataClient)

      refetch()
      message.success(translate('useListClients.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListClients.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteParentClient(customerId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/parentCustomer/${customerId}`,
      )

      refetch()
      message.success(translate('useListClients.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListClients.deleteErrorMessage'),
        locale,
      )
    }
  }

  async function deleteParentClients(customerId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/parentCustomer/${customerId}`,
      )

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListClients.deleteErrorMessage'),
        locale,
      )
    }
  }

  async function updateChildrenParentClient(
    dataClient: object,
    customerId: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/parentCustomer/${customerId}`,
        dataClient,
      )

      refetch()
    } catch (err: any) {
      ShowError(err.message, translate('useClients.updateErrorMessage'), locale)
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      fantasyName: newFilterOptions?.fantasyName,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      'parentClientsFilterOptions',
      JSON.stringify(newFilters),
    )
    // setNumberPage(1);
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('parentClientsFilterOptions')
    setFilterOptions({})
    // setNumberPage(1);
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <ListParentClientsContext.Provider
      value={{
        parentClients,
        createParentClients,
        deleteParentClient,
        deleteParentClients,
        isLoading,
        refetch,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        isFetching,
        setPageSize,
        setNumberPage,
        clientsTotal,
        companyName,
        setCompanyName,
        cnpjText,
        setCnpjText,
        cpfText,
        setCpfText,
        viewValues,
        setViewValues,
        updateChildrenParentClient,
      }}
    >
      {children}
    </ListParentClientsContext.Provider>
  )
}

export function useListParentClients(): ListParentClientsContextData {
  const context = useContext(ListParentClientsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
