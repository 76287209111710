import React, { useState } from 'react'

import { FloatButton, Layout } from 'antd'

import { useLocation, useParams } from 'react-router-dom'
import { Container } from './styles'

import Sidebar from '~/components/DefaultLayout/Sidebar'
import Navbar from '~/components/DefaultLayout/Navbar'

import AppProvider from '~/hooks/AppProvider'
import { useServiceStatus } from '~/hooks/ServiceStatus/useServiceStatus'
const { Header, Content } = Layout

const DefaultLayout: React.FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const location = useLocation()
  const { serviceStatus } = useServiceStatus()

  const { ticketId } = useParams<{ ticketId: string }>()

  const localização = location.pathname

  const handleCollapseSidebar = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <AppProvider>
      <Container
        path={localização}
        ticketId={ticketId}
        serviceStatus={serviceStatus}
      >
        <Layout>
          <Sidebar
            isCollapsed={isCollapsed}
            onCollapseSidebar={handleCollapseSidebar}
          />
          <Layout className="site-layout">
            <Header className="site-layout-header">
              <Navbar
                onCollapseSidebar={handleCollapseSidebar}
                isCollapsed={isCollapsed}
              />
            </Header>
            <Content className="site-layout-content">{children}</Content>
            <FloatButton.BackTop />
          </Layout>
        </Layout>
      </Container>
    </AppProvider>
  )
}

export default DefaultLayout
