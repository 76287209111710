/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface UsersProps {
  id: string
  active: boolean
  created_at: Date
  email: string
  updated_at: Date
  initial_time: string
  username: string
  contact: {
    name: string
  }
}

interface CollaboratorId {
  id: string
}

interface FilterOptionsProps {
  contributors_id?: CollaboratorId[]
  contact?: string
  email?: string
  group?: string
}

interface UsersListContextData {
  users?: UsersProps[]
  createUsers(dataUser: object | undefined): void
  deleteUsers(userId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  filterOptions: FilterOptionsProps
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  usersTotal?: number
  setPageSize: Dispatch<SetStateAction<number>>
  setNumberPage: Dispatch<SetStateAction<number>>
}

const UsersListContext = createContext<UsersListContextData>(
  {} as UsersListContextData,
)

export const UsersListProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [pageSize, setPageSize] = useState(10)
  const [numberPage, setNumberPage] = useState(1)

  const [usersTotal, setUsersTotal] = useState<number>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:usersFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: users,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `usuários${selectedCompany}`,
      selectedCompany,
      filterOptions,
      pageSize,
      numberPage,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(`/company/${selectedCompany}/users`, {
          params: {
            limit: pageSize,
            offset: paginationOffset,
            ...(filterOptions?.contributors_id &&
              filterOptions?.contributors_id?.join() !== '' && {
                contributors_id: filterOptions?.contributors_id?.join(),
              }),
            contact: filterOptions?.contact,
            email: filterOptions?.email,
            group: filterOptions?.group,
          },
        })

        const { data } = response

        setUsersTotal(response.data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListUsers.getErrorMessage'),
          locale,
        )
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createUsers(dataUser: object) {
    try {
      await api.post(`/company/${selectedCompany}/user`, dataUser)

      refetch()
      message.success(translate('useListUsers.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListUsers.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteUsers(userId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/user/${userId}`)

      refetch()
      message.success(translate('useListUsers.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListUsers.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      contributors_id: newFilterOptions?.contributors_id,
      contact: newFilterOptions?.contact,
      email: newFilterOptions?.email,
      group: newFilterOptions?.group,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:usersFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:usersFilterOptions')
    setFilterOptions({})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <UsersListContext.Provider
      value={{
        users,
        createUsers,
        deleteUsers,
        isLoading,
        refetch,
        isFetching,
        filterOptions,
        handleResetFilters,
        handleUpdateFilters,
        usersTotal,
        setNumberPage,
        setPageSize,
      }}
    >
      {children}
    </UsersListContext.Provider>
  )
}

export function useListUsers(): UsersListContextData {
  const context = useContext(UsersListContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
