/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd'

const apiErrors = [
  {
    message: 'Email address already in use',
    pt_BR: 'Erro: Este e-mail já está sendo utilizado.',
    es_ES: 'Error: Este correo electrónico ya se está utilizando.',
    en_US: 'Error: This email is already being used.',
  },
  {
    message: 'Too many requests',
    pt_BR: 'Erro: Muitas requisições.',
    es_ES: 'Error: Demasiadas solicitudes.',
    en_US: 'Error: Too many requests.',
  },
  {
    message: 'JWT token is missing',
    pt_BR: 'Erro: Requisição sem token.',
    es_ES: 'Error: Solicitud sin token.',
    en_US: 'Error: Request without token.',
  },
  {
    message: 'Invalid JWT token',
    pt_BR: 'Erro: Token inválido.',
    es_ES: 'Error: Token no válido.',
    en_US: 'Error: Invalid Token.',
  },
  {
    message: 'User not found',
    pt_BR: 'Erro: Usuário não encontrado.',
    es_ES: 'Error: Usuario no encontrado.',
    en_US: 'Error: User not found.',
  },
  {
    message: 'You need to inform the old password to set a new password',
    pt_BR:
      'Erro: Você precisa informar a senha antiga para cadastrar uma nova senha.',
    es_ES:
      'Error: Debe ingresar la contraseña anterior para registrar una nueva contraseña.',
    en_US:
      'Error: You need to enter the old password to register a new password.',
  },
  {
    message: 'Old password does not match',
    pt_BR: 'Erro: Senha antiga não combina.',
    es_ES: 'Error: La contraseña anterior no coincide.',
    en_US: 'Error: Old password does not match.',
  },
  {
    message: 'Phone already in use',
    pt_BR: 'Erro: Este telefone já está sendo utilizado.',
    es_ES: 'Error: Este teléfono ya está en uso.',
    en_US: 'Error: This phone is already in use.',
  },
  {
    message: 'Phone formatting is wrong',
    pt_BR: 'Erro: Telefone com formato incorreto.',
    es_ES: 'Error: Teléfono con formato incorrecto.',
    en_US: 'Error: Phone with incorrect format.',
  },
  {
    message: 'This plan does not exist',
    pt_BR: 'Erro: Este plano não existe.',
    es_ES: 'Error: Este plan no existe.',
    en_US: 'Error: This plan does not exist.',
  },
  {
    message: 'Token expired',
    pt_BR: 'Erro: Token expirado.',
    es_ES: 'Error: Token caducado.',
    en_US: 'Error: Token expired.',
  },
  {
    message: 'User does not exists',
    pt_BR: 'Erro: Usuário inexistente.',
    es_ES: 'Error: El usuario no existe.',
    en_US: 'Error: User does not exist.',
  },
  {
    message: 'User token does not exists',
    pt_BR: 'Erro: Token inexistente.',
    es_ES: 'Error: El token no existe.',
    en_US: 'Error: Token does not exist.',
  },
  {
    message: 'Contact not found',
    pt_BR: 'Erro: Contato não encontrado.',
    es_ES: 'Error: Contacto no encontrado.',
    en_US: 'Error: Contact not found.',
  },
  {
    message: 'Incorrect username/password combination',
    pt_BR: 'Erro: Combinação de nome de usuário / senha incorretos.',
    es_ES: 'Error: Combinación incorrecta de nombre de usuario/contraseña.',
    en_US: 'Error: Incorrect username/password combination.',
  },
  {
    message: 'This account is not active',
    pt_BR: 'Erro: Esta conta não foi ativada.',
    es_ES: 'Error: Esta cuenta no ha sido activada.',
    en_US: 'Error: This account has not been activated.',
  },
  {
    message: 'Contact does not exist',
    pt_BR: 'Erro: Contato inexistente.',
    es_ES: 'Error: El contacto no existe.',
    en_US: 'Error: Contact does not exist.',
  },
  {
    message: 'CNPJ formatting is wrong',
    pt_BR: 'Erro: CNPJ com formato incorreto.',
    es_ES: 'Error: CNPJ con formato incorrecto.',
    en_US: 'Error: CNPJ with incorrect format.',
  },
  {
    message: 'Could not get content type',
    pt_BR: 'Erro: Não foi possível adquirir o tipo do conteúdo.',
    es_ES: 'Error: No se puede adquirir el tipo de contenido.',
    en_US: 'Error: Unable to acquire content type.',
  },
  {
    message: 'This company is not yours',
    pt_BR: 'Erro: Esta empresa não é sua.',
    es_ES: 'Error: Esta empresa no es tuya.',
    en_US: 'Error: This company is not yours.',
  },
  {
    message: 'Zip code formatting is wrong',
    pt_BR: 'Erro: CEP com formato incorreto.',
    es_ES: 'Error: CEP con formato incorrecto.',
    en_US: 'Error: ZipCode with incorrect format.',
  },
  {
    message: 'This address does not belong to your company',
    pt_BR: 'Erro: Este endereço não pertence a sua empresa.',
    es_ES: 'Error: Esta dirección no pertenece a su empresa.',
    en_US: 'Error: This address does not belong to your company.',
  },
  {
    message: 'Addresses not found',
    pt_BR: 'Erro: Endereço não encontrado.',
    es_ES: 'Error: Dirección no encontrada.',
    en_US: 'Error: Address not found.',
  },
  {
    message: 'That address type does not exist',
    pt_BR: 'Erro: Este tipo de endereço não existe.',
    es_ES: 'Error: Este tipo de dirección no existe.',
    en_US: 'Error: This address type does not exist.',
  },
  {
    message: 'Address not found',
    pt_BR: 'Erro: Endereço não encontrado.',
    es_ES: 'Error: Dirección no encontrada.',
    en_US: 'Error: Address not found.',
  },
  {
    message: 'This address does not belong to this contact',
    pt_BR: 'Erro: Este endereço não pertence a este contato.',
    es_ES: 'Error: Esta dirección no pertenece a este contacto.',
    en_US: 'Error: This address does not belong to this contact.',
  },
  {
    message: 'This document already belongs to another contact',
    pt_BR: 'Erro: Este documento já pertence a outro contato.',
    es_ES: 'Error: Este documento ya pertenece a otro contacto.',
    en_US: 'Error: This document already belongs to another contact.',
  },
  {
    message: 'This CNPJ already belongs to another contact',
    pt_BR: 'Erro: Este CNPJ já pertence a outro contato.',
    es_ES: 'Error: Este CNPJ ya pertenece a otro contacto.',
    en_US: 'Error: This CNPJ already belongs to another contact.',
  },
  {
    message: 'This RG already belongs to another contact',
    pt_BR: 'Erro: Este RG já pertence a outro contato.',
    es_ES: 'Error: Este ID ya pertenece a otro contacto.',
    en_US: 'Error: This ID already belongs to another contact.',
  },
  {
    message: 'This phone already belongs to another contact',
    pt_BR: 'Erro: Este telefone já pertence a outro contato.',
    es_ES: 'Error: Este teléfono ya pertenece a otro contacto.',
    en_US: 'Error: This phone already belongs to another contact.',
  },
  {
    message: 'That person type does not exist',
    pt_BR: 'Erro: Este tipo de pessoa não existe.',
    es_ES: 'Error: Este tipo de persona no existe.',
    en_US: 'Error: This type of person does not exist.',
  },
  {
    message: 'That gender does not exist',
    pt_BR: 'Erro: Este gênero não existe.',
    es_ES: 'Error: Este género no existe.',
    en_US: 'Error: This genre does not exist.',
  },
  {
    message: 'Document formatting is wrong',
    pt_BR: 'Erro: Documento com formato incorreto.',
    es_ES: 'Error: Documento con formato incorrecto.',
    en_US: 'Error: Document with incorrect format.',
  },
  {
    message: 'RG formatting is wrong',
    pt_BR: 'Erro: RG com formato incorreto.',
    es_ES: 'Error: ID con formato incorrecto.',
    en_US: 'Error: ID with incorrect format.',
  },
  {
    message: 'Check the filling of the additional phone',
    pt_BR: 'Erro: Verifique o preenchimento dos telefones adicionais.',
    es_ES: 'Error: Comprobar la finalización de los teléfonos adicionales.',
    en_US: 'Error: Check the completion of additional phones.',
  },
  {
    message: 'This type of phone does not exist',
    pt_BR: 'Erro: Este tipo de telefone não existe.',
    es_ES: 'Error: Este tipo de teléfono no existe.',
    en_US: 'Erro: Este tipo de telefone não existe.',
  },
  {
    message: 'You are a Legal Entity. It is mandatory to add a CNPJ',
    pt_BR: 'Erro: Você é uma pessoa jurídica, é obrigatório adicionar um CNPJ.',
    es_ES: 'Error: Eres una persona jurídica, es obligatorio agregar un CNPJ.',
    en_US: 'Error: You are a legal entity, it is mandatory to add a CNPJ.',
  },
  {
    message: 'This collaborator does not belong to your company',
    pt_BR: 'Erro: Este colaborador não pertence a sua empresa.',
    es_ES: 'Error: Este colaborador no pertenece a su empresa.',
    en_US: 'Error: This contributor does not belong to your company.',
  },
  {
    message: 'Collaborator not found',
    pt_BR: 'Erro: Colaborador não encontrado.',
    es_ES: 'Error: Colaborador no encontrado.',
    en_US: 'Error: Contributor not found.',
  },
  {
    message: 'Email already in use',
    pt_BR: 'Erro: Este e-mail já está sendo utilizado.',
    es_ES: 'Error: Este correo electrónico ya se está utilizando.',
    en_US: 'Error: This email is already being used.',
  },
  {
    message: 'Only integer values ​​are accepted',
    pt_BR: 'Erro: Apenas números inteiros não válidos.',
    es_ES: 'Error: Solo enteros no válidos.',
    en_US: 'Error: Only invalid integers.',
  },
  {
    message: 'This value is not accepted. Rate from 1 to 5 stars',
    pt_BR: 'Erro: Este valor não é válido. Avalie com nota de 1 a 5 estrelas.',
    es_ES:
      'Error: Este valor no es válido. Califíquelo con una calificación de 1 a 5 estrellas.',
    en_US:
      'Error: This value is not valid. Rate it with a rating of 1 to 5 stars.',
  },
  {
    message: 'This evaluation does not belong to your company',
    pt_BR: 'Erro: Esta avaliação não pertence a sua empresa.',
    es_ES: 'Error: Esta reseña no pertenece a su empresa.',
    en_US: 'Error: This review does not belong to your company.',
  },
  {
    message: 'This evaluation does not belong to this collaborator',
    pt_BR: 'Erro: Esta avaliação não pertence a este colaborador.',
    es_ES: 'Error: Esta reseña no pertenece a este colaborador.',
    en_US: 'Error: This review does not belong to this contributor.',
  },
  {
    message: 'Evaluation not found',
    pt_BR: 'Erro: Avaliação não encontrada.',
    es_ES: 'Error: Calificación no encontrada.',
    en_US: 'Error: Rating not found.',
  },
  {
    message: 'This skill does not belong to your company',
    pt_BR: 'Erro: Esta habilidade não pertence a sua empresa.',
    es_ES: 'Error: Esta habilidad no pertenece a tu empresa.',
    en_US: 'Error: This skill does not belong to your company.',
  },
  {
    message: 'Skill not found',
    pt_BR: 'Erro: Habilidade não encontrada.',
    es_ES: 'Error: Habilidad no encontrada.',
    en_US: 'Error: Skill not found.',
  },
  {
    message: 'That level does not exist',
    pt_BR: 'Erro: Este nível não existe.',
    es_ES: 'Error: Este nivel no existe.',
    en_US: 'Error: This level does not exist.',
  },
  {
    message: 'This collaborator skill does not belong to your company',
    pt_BR: 'Erro: Esta habilidade de colaborador não pertence a sua empresa.',
    es_ES: 'Error: Esta habilidad de colaborador no pertenece a su empresa.',
    en_US: 'Error: This contributor skill does not belong to your company.',
  },
  {
    message: 'This skill does not belong to this collaborator',
    pt_BR: 'Erro: Esta habilidade não pertence a este colaborador.',
    es_ES: 'Error: Esta habilidad no pertenece a este colaborador.',
    en_US: 'Error: This skill does not belong to this contributor.',
  },
  {
    message: 'Collaborator skill not found',
    pt_BR: 'Erro: Habilidade de colaborador não encontrada.',
    es_ES: 'Error: Habilidad de colaborador no encontrada.',
    en_US: 'Error: Contributor skill not found.',
  },
  {
    message: 'This collaborator skill does not exist',
    pt_BR: 'Erro: Esta habilidade não existe.',
    es_ES: 'Error: Esta habilidad no existe.',
    en_US: 'Error: This skill does not exist.',
  },
  {
    message: 'The attachment has no title. You need to add one to continue',
    pt_BR:
      'Erro: O documento/anexo não possui um título. Você precisa adicionar um para continuar.',
    es_ES:
      'Error: El documento/archivo adjunto no tiene título. Necesita agregar uno para continuar.',
    en_US:
      'Error: The document/attachment does not have a title. You need to add one to continue.',
  },
  {
    message: 'The file has no attachment',
    pt_BR: 'Erro: Este arquivo não possui documento/anexo.',
    es_ES: 'Error: Este archivo no tiene ningún documento/archivo adjunto.',
    en_US: 'Error: This file has no document/attachment.',
  },
  {
    message: 'That type does not exist',
    pt_BR: 'Erro: Este tipo não existe.',
    es_ES: 'Error: Este tipo no existe.',
    en_US: 'Error: This type does not exist.',
  },
  {
    message: 'This attachment does not belong to your company',
    pt_BR: 'Erro: Este documento/anexo não pertence a sua empresa.',
    es_ES: 'Error: Este documento/archivo adjunto no pertenece a su empresa.',
    en_US: 'Error: This document/attachment does not belong to your company.',
  },
  {
    message: 'This attachment does not belong to this collaborator',
    pt_BR: 'Erro: Este documento/anexo não pertence a este colaborador.',
    es_ES:
      'Error: Este documento/archivo adjunto no pertenece a este colaborador.',
    en_US:
      'Error: This document/attachment does not belong to this contributor.',
  },
  {
    message: 'Collaborator attachment not found',
    pt_BR: 'Erro: Documento/Anexo de colaborador não encontrado.',
    es_ES:
      'Error: No se encontró el documento/archivo adjunto del colaborador.',
    en_US: 'Error: Contributor Document/Attachment not found.',
  },
  {
    message: 'This team does not belong to your company',
    pt_BR: 'Erro: Esta equipe não pertence a sua empresa.',
    es_ES: 'Error: Este equipo no pertenece a su empresa.',
    en_US: 'Error: This team does not belong to your company.',
  },
  {
    message: 'Team not found',
    pt_BR: 'Erro: Equipe não encontrada.',
    es_ES: 'Error: Equipo no encontrado.',
    en_US: 'Error: Team not found.',
  },
  {
    message: 'Collaborator already belongs to this team',
    pt_BR: 'Erro: Este colaborador já pertence a esta equipe.',
    es_ES: 'Error: Este colaborador ya pertenece a este equipo.',
    en_US: 'Error: This contributor already belongs to this team.',
  },
  {
    message: 'This collaborator does not belong to this team',
    pt_BR: 'Erro: Este colaborador não pertence a esta equipe.',
    es_ES: 'Error: Este colaborador no pertenece a este equipo.',
    en_US: 'Error: This contributor does not belong to this team.',
  },
  {
    message: 'This group does not belong to your company',
    pt_BR: 'Erro: Este grupo de permissão não pertence a sua empresa.',
    es_ES: 'Error: Este grupo de permisos no pertenece a su empresa.',
    en_US: 'Error: This permission group does not belong to your company.',
  },
  {
    message: 'Group not found',
    pt_BR: 'Erro: Grupo de permissão não encontrado.',
    es_ES: 'Error: Grupo de permisos no encontrado.',
    en_US: 'Error: Permission group not found.',
  },
  {
    message: 'This group cannot be deleted',
    pt_BR: 'Erro: Este grupo de permissão não pode ser excluido.',
    es_ES: 'Error: Este grupo de permisos no se puede eliminar.',
    en_US: 'Error: This permission group cannot be deleted.',
  },
  {
    message: 'User already belongs to this group',
    pt_BR: 'Erro: Este usuário já pertence a este grupo de permissão.',
    es_ES: 'Error: Este usuario ya pertenece a este grupo de permisos.',
    en_US: 'Error: This user already belongs to this permission group.',
  },
  {
    message: 'This user does not belong to your company',
    pt_BR: 'Erro: Este usuário não pertence a sua empresa.',
    es_ES: 'Error: Este usuario no pertenece a su empresa.',
    en_US: 'Error: This user does not belong to your company.',
  },
  {
    message: 'This user does not exist within this group',
    pt_BR: 'Erro: Este usuário não existe neste grupo.',
    es_ES: 'Error: Este usuario no existe en este grupo.',
    en_US: 'Error: This user does not exist in this group.',
  },
  {
    message: 'Module not found',
    pt_BR: 'Erro: Módulo não encontrado.',
    es_ES: 'Error: Módulo no encontrado.',
    en_US: 'Error: Module not found.',
  },
  {
    message: 'This permission does not belong to your company',
    pt_BR: 'Erro: Esta permissão não pertence a sua empresa.',
    es_ES: 'Error: Este permiso no pertenece a su empresa.',
    en_US: 'Error: This permission does not belong to your company.',
  },
  {
    message: 'This permission does not belong to this module',
    pt_BR: 'Erro: Esta permissão não pertence a este módulo.',
    es_ES: 'Error: Este permiso no pertenece a este módulo.',
    en_US: 'Error: This permission does not belong to this module.',
  },
  {
    message: 'This permission does not belong to this group',
    pt_BR: 'Erro: Esta permissão não pertence a este grupo de permissão.',
    es_ES: 'Error: Este permiso no pertenece a este grupo de permisos.',
    en_US: 'Error: This permission does not belong to this permission group.',
  },
  {
    message: 'This permission cannot be updated',
    pt_BR: 'Erro: Esta permissão não pode ser editada.',
    es_ES: 'Error: Este permiso no se puede editar.',
    en_US: 'Error: This permission cannot be edited.',
  },
  {
    message: 'Permission not found',
    pt_BR: 'Erro: Permissão não encontrada.',
    es_ES: 'Error: Permiso no encontrado.',
    en_US: 'Error: Permission not found.',
  },
  {
    message: 'This permission cannot be deleted',
    pt_BR: 'Erro: Esta permissão não pode ser excluida.',
    es_ES: 'Error: Este permiso no se puede eliminar.',
    en_US: 'Error: This permission cannot be deleted.',
  },
  {
    message: 'This segment does not belong to your company',
    pt_BR: 'Erro: Este segmento não pertence a sua empresa.',
    es_ES: 'Error: Este segmento no pertenece a su empresa.',
    en_US: 'Error: This segment does not belong to your company.',
  },
  {
    message: 'Segment not found',
    pt_BR: 'Erro: Segmento não encontrado.',
    es_ES: 'Error: Segmento no encontrado.',
    en_US: 'Error: Segment not found.',
  },
  {
    message: 'Identifier already in use',
    pt_BR: 'Erro: Este identificador já está sendo utilizado.',
    es_ES: 'Error: Este identificador ya se está utilizando.',
    en_US: 'Error: This identifier is already being used.',
  },
  {
    message: 'This external code already belongs to another contact',
    pt_BR:
      'Erro: Este código externo já está sendo utilizado por outro contato.',
    es_ES:
      'Error: Este código externo ya está siendo utilizado por otro contacto.',
    en_US:
      'Error: This external code is already being used by another contact.',
  },
  {
    message: 'This municipal registration already belongs to another contact',
    pt_BR: 'Erro: Este registro municipal já pertence a outro contato.',
    es_ES: 'Error: Este registro municipal ya pertenece a otro contacto.',
    en_US: 'Error: This municipal record already belongs to another contact.',
  },
  {
    message: 'This state registration already belongs to another contact',
    pt_BR: 'Erro: Este registro estadual já pertence a outro contato.',
    es_ES: 'Error: Este registro de estado ya pertenece a otro contacto.',
    en_US: 'Error: This state record already belongs to another contact.',
  },
  {
    message: 'Municipal registration formatting is wrong',
    pt_BR: 'Erro: Registro municipal com formato incorreto.',
    es_ES: 'Error: Registro Municipal con formato incorrecto.',
    en_US: 'Error: Municipal registry with incorrect format.',
  },
  {
    message: 'State registration formatting is wrong',
    pt_BR: 'Erro: Registro estadual com formato incorreto.',
    es_ES: 'Error: Registro de estado con formato incorrecto.',
    en_US: 'Error: State record with incorrect format.',
  },
  {
    message: 'Additional phone formatting is wrong',
    pt_BR: 'Erro: Telefone adicional com formato incorreto.',
    es_ES: 'Error: Teléfono adicional con formato incorrecto.',
    en_US: 'Error: Additional phone with incorrect format.',
  },
  {
    message: 'This customer does not belong to your company',
    pt_BR: 'Erro: Este cliente não pertence a sua empresa.',
    es_ES: 'Error: Este cliente no pertenece a su empresa.',
    en_US: 'Error: This customer does not belong to your company.',
  },
  {
    message: 'Customer not found',
    pt_BR: 'Erro: Cliente não encontrado.',
    es_ES: 'Error: Cliente no encontrado.',
    en_US: 'Error: Customer not found.',
  },
  {
    message: 'This customer contact does not belong to your company',
    pt_BR: 'Erro: Este contrato de cliente não pertence a sua empresa.',
    es_ES: 'Error: Este acuerdo de cliente no pertenece a su empresa.',
    en_US: 'Error: This customer agreement does not belong to your company.',
  },
  {
    message: 'This contact does not belong to this customer',
    pt_BR: 'Erro: Este contrato não pertence a este cliente.',
    es_ES: 'Error: Este contrato no pertenece a este cliente.',
    en_US: 'Error: This contract does not belong to this customer.',
  },
  {
    message: 'Customer contact not found',
    pt_BR: 'Erro: Contrato de cliente não encontrado.',
    es_ES: 'Error: Acuerdo de cliente no encontrado.',
    en_US: 'Error: Customer Agreement not found.',
  },
  {
    message: 'This schedule does not belong to your company',
    pt_BR: 'Erro: Esta escala não pertence a sua empresa.',
    es_ES: 'Error: Esta báscula no pertenece a su empresa.',
    en_US: 'Error: This scale does not belong to your company.',
  },
  {
    message: 'Schedule not found',
    pt_BR: 'Erro: Escala não encontrada.',
    es_ES: 'Error: Escala no encontrada.',
    en_US: 'Error: Scale not found.',
  },
  {
    message: 'Check-out time cannot be earlier than check-in time',
    pt_BR:
      'Erro: Horário de check-out não pode ser antes do horário de check-in.',
    es_ES:
      'Error: La hora de salida no puede ser anterior a la hora de entrada.',
    en_US: 'Error: Check-out time cannot be earlier than check-in time.',
  },
  {
    message:
      "This type of time does not exist. Valid types are 'work' or 'break'",
    pt_BR:
      'Erro: Este tipo de horário não existe. Tipos de horários válidos são "trabalho" ou "pausa"',
    es_ES:
      'Error: Este tipo de tiempo no existe. Los tipos de tiempo válidos son "trabajo" o "descanso"',
    en_US:
      'Error: This time type does not exist. Valid time types are "work" or "break"',
  },
  {
    message: 'This schedule time does not belong to your company',
    pt_BR: 'Erro: Este horário de escala não pertence a sua empresa.',
    es_ES: 'Error: Este horario de escala no pertenece a su empresa.',
    en_US: 'Error: This stopover schedule does not belong to your company.',
  },
  {
    message: 'This schedule time does not belong to this schedule',
    pt_BR: 'Erro: Este horário não pertence a esta escala.',
    es_ES: 'Error: Este horario no pertenece a esta escala.',
    en_US: 'Error: This timetable does not belong to this scale.',
  },
  {
    message: 'Schedule times not found',
    pt_BR: 'Erro: Horário de escala não encontrado.',
    es_ES: 'Error: No se encontró la hora del programa.',
    en_US: 'Error: Schedule time not found.',
  },
  {
    message: 'Schedule time not found',
    pt_BR: 'Erro: Horário de escala não encontrado.',
    es_ES: 'Error: No se encontró la hora del programa.',
    en_US: 'Error: Schedule time not found.',
  },
  {
    message: 'Customer already belongs to this schedule',
    pt_BR: 'Erro: Este cliente já pertence a esta escala.',
    es_ES: 'Error: Este cliente ya pertenece a esta báscula.',
    en_US: 'Error: This client already belongs to this scale.',
  },
  {
    message: 'This customer does not belong to this schedule',
    pt_BR: 'Erro: Este cliente não pertence a esta escala.',
    es_ES: 'Error: Este cliente no pertenece a este rango.',
    en_US: 'Error: This client does not belong to this range.',
  },
  {
    message: 'Collaborator already belongs to this schedule',
    pt_BR: 'Erro: Este colaborador já pertence a esta escala.',
    es_ES: 'Error: Este colaborador ya pertenece a esta escala.',
    en_US: 'Error: This contributor already belongs to this scale.',
  },
  {
    message: 'This collaborator does not belong to this schedule',
    pt_BR: 'Erro: Este colaborador não pertence a esta escala.',
    es_ES: 'Error: Este colaborador no pertenece a esta escala.',
    en_US: 'Error: This contributor does not belong to this scale.',
  },
  {
    message: 'Team already belongs to this schedule',
    pt_BR: 'Erro: Esta equipe já pertence a esta escala.',
    es_ES: 'Error: Este equipo ya pertenece a esta escala.',
    en_US: 'Error: This team already belongs to this scale.',
  },
  {
    message: 'This team does not belong to this schedule',
    pt_BR: 'Erro: Esta equipe não pertence a escala escala.',
    es_ES: 'Error: Este equipo no pertenece a la escala escala.',
    en_US: 'Error: This team does not belong to the scale scale.',
  },
  {
    message: 'This category does not belong to your company',
    pt_BR: 'Erro: Esta categoria não pertence a sua empresa.',
    es_ES: 'Error: Esta categoría no pertenece a su empresa.',
    en_US: 'Error: This category does not belong to your company.',
  },
  {
    message: 'Category not found',
    pt_BR: 'Erro: Categoria não encontrada.',
    es_ES: 'Error: Categoría no encontrada.',
    en_US: 'Error: Category not found.',
  },
  {
    message: 'This equipment does not belong to your company',
    pt_BR: 'Erro: Este equipamento não pertence a sua empresa.',
    es_ES: 'Error: Este equipo no pertenece a su empresa.',
    en_US: 'Error: This equipment does not belong to your company.',
  },
  {
    message: 'Equipment not found',
    pt_BR: 'Erro: Equipamento não encontrado.',
    es_ES: 'Error: Equipo no encontrado.',
    en_US: 'Error: Equipment not found.',
  },
  {
    message:
      'This equipment you want to associate with this equipment does not belong to your company',
    pt_BR:
      'Erro: Este equipamento que você quer associar não pertence a sua empresa.',
    es_ES: 'Error: Este equipo que desea asociar no pertenece a su empresa.',
    en_US:
      'Error: This equipment you want to associate does not belong to your company.',
  },
  {
    message: 'You are not allowed to create associated-equipments',
    pt_BR:
      'Erro: Você não possui autoriazação para criar equipamentos associados.',
    es_ES: 'Error: No tienes autorización para crear equipos asociados.',
    en_US:
      'Error: You do not have authorization to create associated equipment.',
  },
  {
    message: 'Associated equipment not found',
    pt_BR: 'Erro: Equipamento associado não encontado.',
    es_ES: 'Error: Equipo asociado no encontrado.',
    en_US: 'Error: Associated equipment not found.',
  },
  {
    message: 'This association does not belong to your company',
    pt_BR: 'Erro: Esta associação não pertence a sua empresa.',
    es_ES: 'Error: Esta asociación no pertenece a su empresa.',
    en_US: 'Error: This association does not belong to your company.',
  },
  {
    message: 'This associated equipment does not belong to this equipment',
    pt_BR: 'Erro: Este equipamento associado não pertence a este equipamento.',
    es_ES: 'Error: Este equipo asociado no pertenece a este equipo.',
    en_US:
      'Error: This associated equipment does not belong to this equipment.',
  },
  {
    message: 'You are not allowed to delete associated-equipments',
    pt_BR:
      'Erro: Você não possui autoriazação para excluir equipamentos associados.',
    es_ES: 'Error: No tienes autorización para eliminar equipos asociados.',
    en_US:
      'Error: You do not have authorization to delete associated equipment.',
  },
  {
    message: 'Association not found',
    pt_BR: 'Erro: Associação não encontrada.',
    es_ES: 'Error: Asociación no encontrada.',
    en_US: 'Error: Association not found.',
  },
  {
    message: 'This specification template does not belong to your company',
    pt_BR: 'Erro: Este template de especificação não pertence a sua empresa.',
    es_ES: 'Error: Esta plantilla de especificación no pertenece a su empresa.',
    en_US:
      'Error: This specification template does not belong to your company.',
  },
  {
    message: 'Specification template not found',
    pt_BR: 'Erro: Template de especificação não encontrado.',
    es_ES: 'Error: Plantilla de especificaciones no encontrada.',
    en_US: 'Error: Spec template not found.',
  },
  {
    message: 'This equipment specification does not belong to your company',
    pt_BR:
      'Erro: Esta especificação de equipamento não pertence a sua empresa.',
    es_ES: 'Error: Esta especificación de equipo no pertenece a su empresa.',
    en_US:
      'Error: This equipment specification does not belong to your company.',
  },
  {
    message: 'This specification does not belong to this equipment',
    pt_BR: 'Erro: Esta especificação não pertence a este equipamento.',
    es_ES: 'Error: Esta especificación no pertenece a este equipo.',
    en_US: 'Error: This specification does not belong to this equipment.',
  },
  {
    message: 'Equipment specification not found',
    pt_BR: 'Erro: Especificação de equipamento não encontrada.',
    es_ES: 'Error: No se encontró la especificación del equipo.',
    en_US: 'Error: Equipment specification not found.',
  },
  {
    message: 'This attachment does not belong to this equipment',
    pt_BR: 'Erro: Este documento/anexo não pertence a este equipamento.',
    es_ES: 'Error: Este documento/archivo adjunto no pertenece a este equipo.',
    en_US: 'Error: This document/attachment does not belong to this equipment.',
  },
  {
    message: 'Equipment attachment not found',
    pt_BR: 'Erro: Documento/Anexo de equipamento não encontado.',
    es_ES: 'Error: Documento/Anexo del equipo no encontrado.',
    en_US: 'Error: Document/Annex of equipment not found.',
  },
  {
    message: 'It is necessary to inform where the equipment is located',
    pt_BR: 'Erro: É necessário informar onde o equipamento será alocado.',
    es_ES: 'Error: Es necesario informar donde se asignará el equipo.',
    en_US:
      'Error: It is necessary to inform where the equipment will be allocated.',
  },
  {
    message: 'This stock does not belong to your company',
    pt_BR: 'Erro: Este estoque não pertence a sua empresa.',
    es_ES: 'Error: Esta acción no pertenece a su empresa.',
    en_US: 'Error: This stock does not belong to your company.',
  },
  {
    message: 'Stock not found',
    pt_BR: 'Erro: Estoque não encontrado.',
    es_ES: 'Error: Stock no encontrado.',
    en_US: 'Error: Stock not found.',
  },
  {
    message:
      "Tolerance time formatting is wrong. Formatting must be from 'HH:mm:ss' to 'HHH:mm:ss'",
    pt_BR: 'Erro: Tempo de tolerancia com formato errado.',
    es_ES: 'Error: Tiempo de tolerancia de formato incorrecto.',
    en_US: 'Error: Wrong format tolerance time.',
  },
  {
    message:
      "Runtime formatting is wrong. Formatting must be from 'HH:mm:ss' to 'HHH:mm:ss'",
    pt_BR: 'Erro: Tempo de execução com formato errado.',
    es_ES: 'Error: Tiempo de ejecución con formato incorrecto.',
    en_US: 'Error: Runtime with wrong format.',
  },
  {
    message:
      'The field accepts numbers only. The hour can contain 2-3 digits, minute and second can only contain 2 digits',
    pt_BR:
      'Erro: O campo aceita apenas números. A hora pode conter de 2 a 3 dígitos, minutos e segundos podem apenas conter 2 dígitos.',
    es_ES:
      'Error: El campo solo acepta números. La hora puede contener de 2 a 3 dígitos, los minutos y los segundos solo pueden contener 2 dígitos.',
    en_US:
      'Error: The field only accepts numbers. The hour can contain 2 to 3 digits, minutes and seconds can only contain 2 digits.',
  },
  {
    message: 'This task type does not belong to your company',
    pt_BR: 'Erro: Este tipo de tarefa não pertence a sua empresa.',
    es_ES: 'Error: Este tipo de tarea no pertenece a su empresa.',
    en_US: 'Error: This task type does not belong to your company.',
  },
  {
    message: 'Task type not found',
    pt_BR: 'Erro: Tipo de tarefa não encontrado.',
    es_ES: 'Error: Tipo de tarea no encontrado.',
    en_US: 'Error: Task type not found.',
  },
  {
    message: 'This mandatory does not exist',
    pt_BR: 'Erro: Este mandante não existe.',
    es_ES: 'Error: Este cliente no existe.',
    en_US: 'Error: This client does not exist.',
  },
  {
    message: 'This reason for break does not belong to your company',
    pt_BR: 'Erro: Este motivo de pausa não pertence a sua empresa.',
    es_ES: 'Error: Este motivo de pausa no pertenece a su empresa.',
    en_US: 'Error: This pause reason does not belong to your company.',
  },
  {
    message: 'Reason for break not found',
    pt_BR: 'Erro: Motivo de pausa não encontrado.',
    es_ES: 'Error: Motivo de pausa no encontrado.',
    en_US: 'Error: Pause reason not found.',
  },
  {
    message: 'Break reason not found',
    pt_BR: 'Erro: Motivo de pausa não encontrado.',
    es_ES: 'Error: Motivo de pausa no encontrado.',
    en_US: 'Error: Pause reason not found.',
  },
  {
    message: 'External code already in use',
    pt_BR: 'Erro: Código externo já está sendo utilizado.',
    es_ES: 'Error: Ya se está utilizando el código externo.',
    en_US: 'Error: External code is already being used.',
  },
  {
    message:
      "Duration time formatting is wrong. Formatting must be from 'HH:mm:ss' to 'HHH:mm:ss'",
    pt_BR: 'Erro: Tempo de duração com formato errado.',
    es_ES: 'Error: Formato de duración de tiempo incorrecto.',
    en_US: 'Error: Wrong time duration format.',
  },
  {
    message: 'This type of priority does not exist',
    pt_BR: 'Erro: Este tipo de prioridade não existe.',
    es_ES: 'Error: Este tipo de prioridad no existe.',
    en_US: 'Error: This priority type does not exist.',
  },
  {
    message: 'This checkin type does not exist',
    pt_BR: 'Erro: Este tipo de checkin não existe.',
    es_ES: 'Error: Este tipo de registro no existe.',
    en_US: 'Error: This checkin type does not exist.',
  },
  {
    message: 'This responsible type does not exist',
    pt_BR: 'Erro: Este tipo de responsável não existe.',
    es_ES: 'Error: Este tipo de responsable no existe.',
    en_US: 'Error: This type of responsible person does not exist.',
  },
  {
    message:
      'Cannot create task without address. Please provide at least your zip code and location number',
    pt_BR:
      'Erro: Não é possível criar tarefa sem endereço. Por gentileza providencie ao menos o CEP e número.',
    es_ES:
      'Error: No se puede crear la tarea sin dirección. Proporcione al menos el código postal y el número.',
    en_US:
      'Error: Unable to create task without address. Please provide at least the zip code and number.',
  },
  {
    message: 'This task does not belong to your company',
    pt_BR: 'Erro: Esta tarefa não pertence a sua empresa.',
    es_ES: 'Error: Esta tarea no pertenece a su empresa.',
    en_US: 'Error: This task does not belong to your company.',
  },
  {
    message: 'Task not found',
    pt_BR: 'Erro: Tarefa não encontrada.',
    es_ES: 'Error: Tarea no encontrada.',
    en_US: 'Error: Task not found.',
  },
  {
    message: 'This equipment already belongs to this task',
    pt_BR: 'Erro: Este equipamento já pertence a esta tarefa.',
    es_ES: 'Error: Este equipo ya pertenece a esta tarea.',
    en_US: 'Error: This equipment already belongs to this task.',
  },
  {
    message: 'This equipment does not belong to this task',
    pt_BR: 'Erro: Este equipamento não pertence a esta tarefa.',
    es_ES: 'Error: Este equipo no pertenece a esta tarea.',
    en_US: 'Error: This equipment does not belong to this task.',
  },
  {
    message:
      'There is no one responsible for this task. You must add one before starting the task execution',
    pt_BR:
      'Erro: Não existe um responsável pela tarefa. Você precisa aicionar um antes de iniciar a execução da tarefa.',
    es_ES:
      'Error: No hay ningún responsable de la tarea. Debe activar uno antes de comenzar la ejecución de la tarea.',
    en_US:
      'Error: There is no one responsible for the task. You need to trigger one before starting the task execution.',
  },
  {
    message: 'This collaborator is not responsible for this task',
    pt_BR: 'Erro: Este colaborador não é o responsável por essa tarefa.',
    es_ES: 'Error: Este colaborador no es responsable de esta tarea.',
    en_US: 'Error: This contributor is not responsible for this task.',
  },
  {
    message: 'You cannot change the status of a task that has been Canceled',
    pt_BR:
      'Erro: Você não pode mudar o status de uma tarefa que foi cancelada.',
    es_ES:
      'Error: No puede cambiar el estado de una tarea que ha sido cancelada.',
    en_US:
      'Error: You cannot change the status of a task that has been cancelled.',
  },
  {
    message: 'You cannot change the status of a task that has been Finished',
    pt_BR:
      'Erro: Você não pode mudar o status de uma tarefa que foi finalizada.',
    es_ES:
      'Error: No puede cambiar el estado de una tarea que se ha completado.',
    en_US:
      'Error: You cannot change the status of a task that has been completed.',
  },
  {
    message: 'It is not possible to pause a task that has not started',
    pt_BR: 'Erro: Não é possível pausar uma tarefa que não foi iniciada.',
    es_ES: 'Error: No se puede pausar una tarea que no ha comenzado.',
    en_US: 'Error: Unable to pause a task that has not started.',
  },
  {
    message: 'You cannot resume work on a task that has not been paused',
    pt_BR: 'Erro: Você não pode retomar uma tarefa que não foi pausada.',
    es_ES: 'Error: No puede reanudar una tarea que no ha sido pausada.',
    en_US: 'Error: You cannot resume a task that has not been paused.',
  },
  {
    message: 'It is not possible to end a task that is not running',
    pt_BR:
      'Erro: Não é possível finalizar uma tarefa que não está em andamento.',
    es_ES: 'Error: No se puede finalizar una tarea que no está en curso.',
    en_US: 'Error: Unable to end a task that is not in progress.',
  },
  {
    message: 'Reports not found',
    pt_BR: 'Erro: Relatórios não encontrados.',
    es_ES: 'Error: Informes no encontrados.',
    en_US: 'Error: Reports not found.',
  },
  {
    message: 'Reports was not exported',
    pt_BR: 'Erro: Relatórios não foram exportados.',
    es_ES: 'Error: Los informes no se exportaron.',
    en_US: 'Error: Reports were not exported.',
  },
  {
    message: 'This company is not yours',
    pt_BR: 'Erro: Esta empresa não pertence a você.',
    es_ES: 'Error: Esta empresa no te pertenece.',
    en_US: 'Error: This company does not belong to you.',
  },
  {
    message: 'Status not found',
    pt_BR: 'Erro: Status não encontrados.',
    es_ES: 'Error: Estado no encontrado.',
    en_US: 'Error: Status not found.',
  },
  {
    message: 'This status does not belong to your company',
    pt_BR: 'Erro: Este status não pertence à sua empresa.',
    es_ES: 'Error: Este estado no pertenece a su empresa.',
    en_US: 'Error: This status does not belong to your company.',
  },
  {
    message: 'You are not allowed to edit collaborator-status',
    pt_BR: 'Erro: Você não tem permissão para editar o status do colaborador.',
    es_ES: 'Error: No tienes permiso para editar el estado de colaborador.',
    en_US: 'Error: You do not have permission to edit contributor status.',
  },
  {
    message: 'This status cannot be updated',
    pt_BR: 'Erro: Esse status não pode ser atualizado.',
    es_ES: 'Error: Este estado no se puede actualizar.',
    en_US: 'Error: This status cannot be updated.',
  },
  {
    message: 'This file is not compatible',
    pt_BR: 'Erro: Este arquivo não é compatível.',
    es_ES: 'Error: Este archivo no es compatible.',
    en_US: 'Error: This file is not supported.',
  },
]

const apiGeneralWarnings = [
  {
    message: 'You are not allowed to create reports',
    pt_BR: 'Erro: Você não tem permissão para criar relatórios.',
    es_ES: 'Error: No tiene permiso para crear informes.',
    en_US: 'Error: You do not have permission to create reports.',
  },
  {
    message: 'You are not allowed to edit tasks-status',
    pt_BR: 'Erro: Você não possui permissão para editar status da tarefa.',
    es_ES: 'Error: No tiene permiso para editar el estado de la tarea.',
    en_US: 'Error: You do not have permission to edit task status.',
  },
  {
    message: 'You are not allowed to delete tasks-equipments',
    pt_BR:
      'Erro: Você não possui permissão para excluir equipamentos das tarefas.',
    es_ES: 'Error: No tiene permiso para excluir equipos de las tareas.',
    en_US: 'Error: You do not have permission to exclude equipment from tasks.',
  },
  {
    message: 'You are not allowed to edit tasks-equipments',
    pt_BR:
      'Erro: Você não possui permissão para editar equipamentos de tarefas.',
    es_ES: 'Error: No tienes permiso para editar equipos de tareas.',
    en_US: 'Error: You do not have permission to edit task equipment.',
  },
  {
    message: 'You are not allowed to edit tasks',
    pt_BR: 'Erro: Você não possui permissão para editar tarefas.',
    es_ES: 'Error: No tienes permiso para editar tareas.',
    en_US: 'Error: You do not have permission to edit tasks.',
  },
  {
    message: 'You are not allowed to delete tasks',
    pt_BR: 'Erro: Você não possui permissão para excluir tarefas.',
    es_ES: 'Error: No tienes permiso para eliminar tareas.',
    en_US: 'Error: You do not have permission to delete tasks.',
  },
  {
    message: 'You are not allowed to edit tasks-reports',
    pt_BR: 'Erro: Você não possui permissão para editar relatórios de tarefa.',
    es_ES: 'Error: No tiene permiso para editar informes de tareas.',
    en_US: 'Error: You do not have permission to edit task reports.',
  },
  {
    message: 'You are not allowed to create tasks',
    pt_BR: 'Erro: Você não possui permissão para criar tarefas.',
    es_ES: 'Error: No tienes permiso para crear tareas.',
    en_US: 'Error: You do not have permission to create tasks.',
  },
  {
    message: 'You are not allowed to delete breaks-reasons',
    pt_BR: 'Erro: Você não possui permissão para excluir motivos de pausa.',
    es_ES: 'Error: No tiene permiso para eliminar motivos de descanso.',
    en_US: 'Error: You do not have permission to delete break reasons.',
  },
  {
    message: 'You are not allowed to edit breaks-reasons',
    pt_BR: 'Erro: Você não possui permissão para editar motivos de pausa.',
    es_ES: 'Error: No tiene permiso para editar motivos de descanso.',
    en_US: 'Error: You do not have permission to edit break reasons.',
  },
  {
    message: 'You are not allowed to create reasons-breaks',
    pt_BR: 'Erro: Você não possui permissão para criar motivos de pausa.',
    es_ES: 'Error: No tiene permiso para crear motivos de descanso.',
    en_US: 'Error: You do not have permission to create break reasons.',
  },
  {
    message: 'You are not allowed to delete tasks-types',
    pt_BR: 'Erro: Você não possui permissão para excluir tipos de tarefa.',
    es_ES: 'Error: No tiene permiso para eliminar tipos de tareas.',
    en_US: 'Error: You do not have permission to delete task types.',
  },
  {
    message: 'You are not allowed to edit tasks-types',
    pt_BR: 'Erro: Você não possui permissão para editar tipos de tarefa.',
    es_ES: 'Error: No tiene permiso para editar tipos de tareas.',
    en_US: 'Error: You do not have permission to edit task types.',
  },
  {
    message: 'You are not allowed to create tasks-types',
    pt_BR: 'Erro: Você não possui permissão para criar tipos de tarefa.',
    es_ES: 'Error: No tiene permiso para crear tipos de tareas.',
    en_US: 'Error: You do not have permission to create task types.',
  },
  {
    message: 'You are not allowed to create movements',
    pt_BR: 'Erro: Você não possui permissão para criar movimentações.',
    es_ES: 'Error: No tienes permiso para crear movimientos.',
    en_US: 'Error: You do not have permission to create moves.',
  },
  {
    message: 'You are not allowed to delete equipments-attachments',
    pt_BR:
      'Erro: Você não possui permissão para excluir documentos/anexos de equipamentos.',
    es_ES:
      'Error: No tiene permiso para eliminar documentos/archivos adjuntos del equipo.',
    en_US: 'You are not allowed to delete equipments-attachments',
  },
  {
    message: 'You are not allowed to edit equipments-attachments',
    pt_BR:
      'Erro: Você não possui permissão para editar documentos/anexos de equipamentos.',
    es_ES:
      'Error: No tiene permiso para editar documentos/archivos adjuntos del equipo.',
    en_US:
      'Error: You do not have permission to edit equipment documents/attachments.',
  },
  {
    message: 'You are not allowed to delete equipments-specifications',
    pt_BR:
      'Erro: Você não possui permissão para excluir especificações de equipamentos.',
    es_ES:
      'Error: No tiene permiso para eliminar las especificaciones del equipo.',
    en_US:
      'Error: You do not have permission to delete equipment specifications.',
  },
  {
    message: 'You are not allowed to create equipments-attachments',
    pt_BR:
      'Erro: Você não possui permissão para criar documentos/anexos de equipamentos.',
    es_ES: 'Error: No tiene permiso para crear documentos/adjuntos de equipo.',
    en_US:
      'Error: You do not have permission to create equipment documents/attachments.',
  },
  {
    message: 'You are not allowed to edit equipments-specifications',
    pt_BR:
      'Erro: Você não possui permissão para editar especificações de equipamentos.',
    es_ES:
      'Error: No tiene permiso para editar las especificaciones del equipo.',
    en_US:
      'Error: You do not have permission to edit equipment specifications.',
  },
  {
    message: 'You are not allowed to edit specifications-templates',
    pt_BR:
      'Erro: Você não possui permissão para editar templates de especificação.',
    es_ES:
      'Error: No tiene permiso para editar plantillas de especificaciones.',
    en_US:
      'Erro: Você não possui permissão para editar templates de especificação.',
  },
  {
    message: 'You are not allowed to delete specifications-templates',
    pt_BR:
      'Erro: Você não possui permissão para excluir templates de especificação.',
    es_ES:
      'Error: No tiene permiso para eliminar plantillas de especificaciones.',
    en_US:
      'Error: You do not have permission to delete specification templates.',
  },
  {
    message: 'You are not allowed to create equipments-specifications',
    pt_BR:
      'Erro: Você não possui permissão para criar especificações de equipamentos.',
    es_ES: 'Error: No tiene permiso para crear especificaciones de equipo.',
    en_US:
      'Error: You do not have permission to create equipment specifications.',
  },
  {
    message: 'You are not allowed to create specifications-templates',
    pt_BR:
      'Erro: Você não possui permissão para criar templates de especificação.',
    es_ES: 'Error: No tiene permiso para crear plantillas de especificaciones.',
    en_US:
      'Error: You do not have permission to create specification templates.',
  },
  {
    message: 'You are not allowed to delete equipments',
    pt_BR: 'Erro: Você não possui permissão para excluir equipamentos.',
    es_ES: 'Error: No tienes permiso para eliminar equipos.',
    en_US: 'Error: You do not have permission to delete equipment.',
  },
  {
    message: 'You are not allowed to edit equipments',
    pt_BR: 'Erro: Você não possui permissão para editar equipamentos.',
    es_ES: 'Error: No tienes permiso para editar equipos.',
    en_US: 'Error: You do not have permission to edit equipment.',
  },
  {
    message: 'You are not allowed to edit categories',
    pt_BR: 'Erro: Você não possui permissão para editar categorias.',
    es_ES: 'Error: No tienes permiso para editar categorías.',
    en_US: 'Error: You do not have permission to edit categories.',
  },
  {
    message: 'You are not allowed to delete categories',
    pt_BR: 'Erro: Você não possui permissão para excluir categorias.',
    es_ES: 'Error: No tienes permiso para eliminar categorías.',
    en_US: 'Error: You do not have permission to delete categories.',
  },
  {
    message: 'You are not allowed to create equipments',
    pt_BR: 'Erro: Você não possui permissão para criar equipamentos.',
    es_ES: 'Error: No tienes permiso para crear equipos.',
    en_US: 'Error: You do not have permission to create equipment.',
  },
  {
    message: 'You are not allowed to create categories',
    pt_BR: 'Erro: Você não possui permissão para criar categorias.',
    es_ES: 'Error: No tienes permiso para crear categorías.',
    en_US: 'Error: You do not have permission to create categories.',
  },
  {
    message: 'You are not allowed to delete contributors-schedules',
    pt_BR:
      'Erro: Você não possui permissão para excluir escalas de colaboradores.',
    es_ES: 'Error: No tiene permiso para eliminar las listas de colaboradores.',
    en_US: 'Error: You do not have permission to delete contributor rosters.',
  },
  {
    message: 'You are not allowed to create contributors-schedules',
    pt_BR:
      'Erro: Você não possui permissão para criar escalas de colaboradores.',
    es_ES: 'Error: No tiene permiso para crear listas de colaboradores.',
    en_US: 'Error: You do not have permission to create contributor rosters.',
  },
  {
    message: 'You are not allowed to delete customers-schedules',
    pt_BR: 'Erro: Você não possui permissão para excluir escalas de clientes.',
    es_ES: 'Error: No tiene permiso para eliminar listas de clientes.',
    en_US: 'Error: You do not have permission to delete customer rosters.',
  },
  {
    message: 'You are not allowed to create customers-schedules',
    pt_BR: 'Erro: Você não possui permissão para criar escalas de clientes.',
    es_ES: 'Error: No tiene permiso para crear listas de clientes.',
    en_US: 'Error: You do not have permission to create customer rosters.',
  },
  {
    message: 'You are not allowed to delete schedule-times',
    pt_BR: 'Erro: Você não possui permissão para excluir horários de escala.',
    es_ES: 'Error: No tiene permiso para eliminar tiempos de escala.',
    en_US: 'Error: You do not have permission to delete stopover times.',
  },
  {
    message: 'You are not allowed to edit schedule-times',
    pt_BR: 'Erro: Você não possui permissão para editar horários de escala.',
    es_ES: 'Error: No tiene permiso para editar los tiempos de escala.',
    en_US: 'Error: You do not have permission to edit stopover times.',
  },
  {
    message: 'You are not allowed to create schedule-times',
    pt_BR: 'Erro: Você não possui permissão para criar horários de escala.',
    es_ES: 'Error: No tiene permiso para crear horarios de escala.',
    en_US: 'Error: You do not have permission to create stopover schedules.',
  },
  {
    message: 'You are not allowed to edit schedules',
    pt_BR: 'Erro: Você não possui permissão para editar escalas.',
    es_ES: 'Error: No tienes permiso para editar escalas.',
    en_US: 'Error: You do not have permission to edit scales.',
  },
  {
    message: 'You are not allowed to delete schedules',
    pt_BR: 'Erro: Você não possui permissão para excluir escalas.',
    es_ES: 'Error: No tiene permiso para eliminar escalas.',
    en_US: 'Error: You do not have permission to delete scales.',
  },
  {
    message: 'You are not allowed to edit customers-contacts',
    pt_BR: 'Erro: Você não possui permissão para editar contratos de clientes.',
    es_ES: 'Error: No tiene permiso para editar contratos de clientes.',
    en_US: 'Error: You do not have permission to edit customer contracts.',
  },
  {
    message: 'You are not allowed to delete customers-contacts',
    pt_BR:
      'Erro: Você não possui permissão para excluir contratos de clientes.',
    es_ES: 'Error: No tiene permiso para eliminar contratos de clientes.',
    en_US: 'Error: You do not have permission to delete customer contracts.',
  },
  {
    message: 'You are not allowed to create schedules',
    pt_BR: 'Erro: Você não possui permissão para criar escalas.',
    es_ES: 'Error: No tienes permiso para crear escalas.',
    en_US: 'Error: You do not have permission to create scales.',
  },
  {
    message: 'You are not allowed to edit customers',
    pt_BR: 'Erro: Você não possui permissão para editar clientes.',
    es_ES: 'Error: No tiene permiso para editar clientes.',
    en_US: 'Error: You do not have permission to edit clients.',
  },
  {
    message: 'You are not allowed to delete customers',
    pt_BR: 'Erro: Você não possui permissão para excluir clientes.',
    es_ES: 'Error: No tiene permiso para eliminar clientes.',
    en_US: 'Error: You do not have permission to delete clients.',
  },
  {
    message: 'You are not allowed to create customers-contacts',
    pt_BR: 'Erro: Você não possui permissão para criar contratos de clientes.',
    es_ES: 'Error: No tiene permiso para crear contratos de clientes.',
    en_US: 'Error: You do not have permission to create customer contracts.',
  },
  {
    message: 'You are not allowed to edit customers-segments',
    pt_BR: 'Erro: Você não possui permissão para editar segmentos de clientes.',
    es_ES: 'Error: No tiene permiso para editar segmentos de clientes.',
    en_US: 'Error: You do not have permission to edit customer segments.',
  },
  {
    message: 'You are not allowed to delete customers-segments',
    pt_BR:
      'Erro: Você não possui permissão para excluir segmentos de clientes.',
    es_ES: 'Error: No tiene permiso para eliminar segmentos de clientes.',
    en_US: 'Error: You do not have permission to delete customer segments.',
  },
  {
    message: 'You are not allowed to delete groups-permissions',
    pt_BR: 'Erro: Você não possui permissão para excluir grupos de permissão.',
    es_ES: 'Error: No tiene permiso para eliminar grupos de permisos.',
    en_US: 'Error: You do not have permission to delete permission groups.',
  },
  {
    message: 'You are not allowed to create customers-segments',
    pt_BR: 'Erro: Você não possui permissão para criar segmentos de clientes.',
    es_ES: 'Error: No tiene permiso para crear segmentos de clientes.',
    en_US: 'Error: You do not have permission to create customer segments.',
  },
  {
    message: 'You are not allowed to edit groups-permissions',
    pt_BR: 'Erro: Você não possui permissão para editar grupos de permissão.',
    es_ES: 'Error: No tiene permiso para editar grupos de permisos.',
    en_US: 'Error: You do not have permission to edit permission groups.',
  },
  {
    message: 'You are not allowed to create groups-permissions',
    pt_BR: 'Erro: Você não possui permissão para criar grupos de permissão.',
    es_ES: 'Error: No tiene permiso para crear grupos de permisos.',
    en_US: 'Error: You do not have permission to create permission groups.',
  },
  {
    message: 'You are not allowed to delete groups',
    pt_BR: 'Erro: Você não possui permissão para excluir grupos de permissão.',
    es_ES: 'Error: No tiene permiso para eliminar grupos de permisos.',
    en_US: 'Error: You do not have permission to delete permission groups.',
  },
  {
    message: 'You are not allowed to edit groups',
    pt_BR: 'Erro: Você não possui permissão para editar grupos de permissão.',
    es_ES: 'Error: No tiene permiso para editar grupos de permisos.',
    en_US: 'Error: You do not have permission to edit permission groups.',
  },
  {
    message: 'You are not allowed to delete contributors-teams',
    pt_BR:
      'Erro: Você não possui permissão para excluir equipes de colaboradores.',
    es_ES: 'Error: No tienes permiso para eliminar equipos de colaboradores.',
    en_US: 'Error: You do not have permission to delete teams of contributors.',
  },
  {
    message: 'You are not allowed to create groups',
    pt_BR: 'Erro: Você não possui permissão para criar grupos de permissões.',
    es_ES: 'Error: No tiene permiso para crear grupos de permisos.',
    en_US: 'Error: You do not have permission to create permission groups.',
  },
  {
    message: 'You are not allowed to create contributors-teams',
    pt_BR:
      'Erro: Você não possui permissão para criar equipes de colaboradores.',
    es_ES: 'Error: No tienes permiso para crear equipos de colaboradores.',
    en_US: 'Error: You do not have permission to create teams of contributors.',
  },
  {
    message: 'You are not allowed to edit teams',
    pt_BR: 'Erro: Você não possui permissão para editar equipes.',
    es_ES: 'Error: No tienes permiso para editar equipos.',
    en_US: 'Error: You do not have permission to edit teams.',
  },
  {
    message: 'You are not allowed to delete teams',
    pt_BR: 'Erro: Você não possui permissão para excluir equipes.',
    es_ES: 'Error: No tienes permiso para eliminar equipos.',
    en_US: "Error: You don't have permission to delete teams.",
  },
  {
    message: 'You are not allowed to delete contributors-attachments',
    pt_BR:
      'Erro: Você não possui permissão para excluir documentos/anexos de colaboradores.',
    es_ES:
      'Error: No tiene permiso para eliminar documentos/adjuntos de colaboradores.',
    en_US:
      'Error: You do not have permission to delete contributor documents/attachments.',
  },
  {
    message: 'You are not allowed to create teams',
    pt_BR: 'Erro: Você não possui permissão para criar equipes.',
    es_ES: 'Error: No tienes permiso para crear equipos.',
    en_US: 'Error: You do not have permission to create teams.',
  },
  {
    message: 'You are not allowed to edit contributors-attachments',
    pt_BR:
      'Erro: Você não possui permissão para editar documentos/anexos de colaboradores.',
    es_ES:
      'Error: No tiene permiso para editar documentos/adjuntos de colaboradores.',
    en_US:
      'Error: You do not have permission to edit contributor documents/attachments.',
  },
  {
    message: 'You are not allowed to create contributors-attachments',
    pt_BR:
      'Erro: Você não possui permissão para criar documentos/anexos de colaboradores.',
    es_ES:
      'Error: No tiene permiso para crear documentos/adjuntos de colaborador.',
    en_US:
      'Error: You do not have permission to create contributor documents/attachments.',
  },
  {
    message: 'You are not allowed to edit contributors-skills',
    pt_BR:
      'Erro: Você não possui permissão para editar habilidades de colaboradores.',
    es_ES:
      'Error: No tienes permiso para editar las habilidades del colaborador.',
    en_US: 'Error: You do not have permission to edit contributor skills.',
  },
  {
    message: 'You are not allowed to delete contributors-skills',
    pt_BR:
      'Erro: Você não possui permissão para excluir habilidades de colaboradores.',
    es_ES: 'Error: No tienes permiso para eliminar habilidades de colaborador.',
    en_US: 'Error: You do not have permission to delete contributor skills.',
  },
  {
    message: 'You are not allowed to edit contributors-evaluations',
    pt_BR:
      'Erro: Você não possui permissão para editar avaliações de colaboradores.',
    es_ES: 'Error: No tienes permiso para editar reseñas de colaboradores.',
    en_US: 'Error: You do not have permission to edit contributor reviews.',
  },
  {
    message: 'You are not allowed to delete contributors-evaluations',
    pt_BR:
      'Erro: Você não possui permissão para excluir avaliações de colaboradores.',
    es_ES: 'Error: No tienes permiso para eliminar reseñas de colaboradores.',
    en_US: 'Error: You do not have permission to delete contributor reviews.',
  },
  {
    message: 'You are not allowed to create contributors-skills',
    pt_BR:
      'Erro: Você não possui permissão para criar habilidades de colaboradores.',
    es_ES: 'Error: No tiene permiso para crear habilidades de colaborador.',
    en_US: 'Error: You do not have permission to create contributor skills.',
  },
  {
    message: 'You are not allowed to create contributors-evaluations',
    pt_BR:
      'Erro: Você não possui permissão para criar avaliações de colaboradores.',
    es_ES: 'Error: No tienes permiso para crear reseñas de colaboradores.',
    en_US: 'Error: You do not have permission to create contributor reviews.',
  },
  {
    message: 'You are not allowed to edit contributors',
    pt_BR: 'Erro: Você não possui permissão para editar colaboradores.',
    es_ES: 'Error: No tienes permiso para editar colaboradores.',
    en_US: 'Error: You do not have permission to edit contributors.',
  },
  {
    message: 'You are not allowed to delete contributors',
    pt_BR: 'Erro: Você não possui permissão para excluir colaboradores.',
    es_ES: 'Error: No tienes permiso para eliminar colaboradores.',
    en_US: 'Error: You do not have permission to delete contributors.',
  },
  {
    message: 'You are not allowed to edit contacts-addresses',
    pt_BR: 'Erro: Você não possui permissão para editar endereços de contatos.',
    es_ES: 'Error: No tienes permiso para editar direcciones de contacto.',
    en_US: 'Error: You do not have permission to edit contact addresses.',
  },
  {
    message: 'You are not allowed to delete contacts-addresses',
    pt_BR:
      'Erro: Você não possui permissão para excluir endereços de contatos.',
    es_ES: 'Error: No tiene permiso para eliminar direcciones de contacto.',
    en_US: 'Error: You do not have permission to delete contact addresses.',
  },
  {
    message: 'You are not allowed to create contributors',
    pt_BR: 'Erro: Você não possui permissão para criar colaboradores.',
    es_ES: 'Error: No tienes permiso para crear colaboradores.',
    en_US: 'Error: You do not have permission to create collaborators.',
  },
  {
    message: 'You are not allowed to create contacts-addresses',
    pt_BR: 'Erro: Você não possui permissão para criar endereços de contatos.',
    es_ES: 'Error: No tiene permiso para crear direcciones de contacto.',
    en_US: 'Error: You do not have permission to create contact addresses.',
  },
  {
    message: 'You are not allowed to edit addresses',
    pt_BR: 'Erro: Você não possui permissão para editar endereços.',
    es_ES: 'Error: No tienes permiso para editar direcciones.',
    en_US: 'Error: You do not have permission to edit addresses.',
  },
  {
    message: 'You are not allowed to delete addresses',
    pt_BR: 'Erro: Você não possui permissão para excluir endereços.',
    es_ES: 'Error: No tiene permiso para eliminar direcciones.',
    en_US: 'Error: You do not have permission to delete addresses.',
  },
  {
    message: 'You are not allowed to create addresses',
    pt_BR: 'Erro: Você não possui permissão para criar endereços.',
    es_ES: 'Error: No tiene permiso para crear direcciones.',
    en_US: 'Error: You do not have permission to create addresses.',
  },
  {
    message: 'You are not allowed to edit companies',
    pt_BR: 'Erro: Você não possui permissão para editar empresas.',
    es_ES: 'Error: No tiene permiso para editar empresas.',
    en_US: 'Error: You do not have permission to edit companies.',
  },
  {
    message: 'You are not allowed to delete companies',
    pt_BR: 'Erro: Você não possui permissão para excluir empresas.',
    es_ES: 'Error: No tiene permiso para eliminar empresas.',
    en_US: 'Error: You do not have permission to delete companies.',
  },
  {
    message: 'You are not allowed to edit contacts',
    pt_BR: 'Erro: Você não possui permissão para editar contatos.',
    es_ES: 'Error: No tienes permiso para editar contactos.',
    en_US: 'Error: You do not have permission to edit contacts.',
  },
  {
    message: 'You are not allowed to create companies',
    pt_BR: 'Erro: Você não possui permissão para criar empresas.',
    es_ES: 'Error: No tiene permiso para crear empresas.',
    en_US: 'Error: You do not have permission to create companies.',
  },
  {
    message: 'You are not allowed to edit users',
    pt_BR: 'Erro: Você não possui permissão para editar usuários.',
    es_ES: 'Error: No tienes permiso para editar usuarios.',
    en_US: 'Error: You do not have permission to edit users.',
  },
  {
    message: 'You are not allowed to delete users',
    pt_BR: 'Erro: Você não possui permissão para excluir usuários.',
    es_ES: 'Error: No tiene permiso para eliminar usuarios.',
    en_US: 'Error: You do not have permission to delete users.',
  },
  {
    message: 'You are not allowed to create contributors-status',
    pt_BR: 'Erro: Você não tem permissão para criar status de colaborador.',
    es_ES: 'Error: No tiene permiso para crear el estado de colaborador.',
    en_US: 'Error: You do not have permission to create contributor status.',
  },
  {
    message: 'You are not allowed to edit contributors-status',
    pt_BR:
      'Erro: Você não tem permissão para editar o status de colaboradores.',
    es_ES:
      'Error: No tienes permiso para editar el estado de los colaboradores.',
    en_US:
      'Error: You do not have permission to edit the status of contributors.',
  },
  {
    message: 'You are not allowed to delete contributors-status',
    pt_BR:
      'Erro: Você não tem permissão para excluir o status de colaboradores.',
    es_ES: 'Error: No tiene permiso para eliminar el estado de colaborador.',
    en_US: 'Error: You do not have permission to delete contributor status.',
  },
]

const apiVisualizationsWarnings = [
  {
    message: 'You are not allowed to view breaks-reasons',
    pt_BR: 'Erro: Você não possui permissões para visualizar motivos de pausa.',
    es_ES: 'Error: No tiene permisos para ver los motivos de las pausas.',
    en_US: 'Error: You do not have permissions to view break reasons.',
  },
  {
    message: 'You are not allowed to view movements',
    pt_BR: 'Erro: Você não possui permissões para visualizar movimentações.',
    es_ES: 'Error: No tienes permisos para ver movimientos.',
    en_US: "Error: You don't have permissions to view moves.",
  },
  {
    message: 'You are not allowed to view reports',
    pt_BR: 'Erro: Você não possui permissão para visualizar relatórios.',
    es_ES: 'Error: No tiene permiso para ver los informes.',
    en_US: 'Error: You do not have permission to view reports.',
  },
  {
    message: 'You are not allowed to view tasks',
    pt_BR: 'Erro: Você não possui permissão para visualizar tarefas.',
    es_ES: 'Error: No tienes permiso para ver las tareas.',
    en_US: 'Error: You do not have permission to view tasks.',
  },
  {
    message: 'You are not allowed to view tasks-types',
    pt_BR: 'Erro: Você não possui permissão para visualizar tipos de tarefa.',
    es_ES: 'Error: No tiene permiso para ver los tipos de tareas.',
    en_US: 'Error: You do not have permission to view task types.',
  },
  {
    message: 'You are not allowed to view equipments-attachments',
    pt_BR:
      'Erro: Você não possui permissão para visualizar documentos/anexos de equipamentos.',
    es_ES:
      'Error: No tiene permiso para ver los documentos/archivos adjuntos del equipo.',
    en_US:
      'Error: You do not have permission to view equipment documents/attachments.',
  },
  {
    message: 'You are not allowed to view equipments-specifications',
    pt_BR:
      'Erro: Você não possui permissão para visualizar especificações de equipamentos.',
    es_ES: 'Error: No tiene permiso para ver las especificaciones del equipo.',
    en_US:
      'Error: You do not have permission to view equipment specifications.',
  },
  {
    message: 'You are not allowed to view specifications-templates',
    pt_BR:
      'Erro: Você não possui permissão para visualizar templates de especificação.',
    es_ES:
      'Error: No tiene permiso para ver las plantillas de especificaciones.',
    en_US: 'Error: You do not have permission to view specification templates.',
  },
  {
    message: 'You are not allowed to view equipments',
    pt_BR: 'Erro: Você não possui permissão para visualizar equipamentos.',
    es_ES: 'Error: No tienes permiso para ver el equipo.',
    en_US: 'Error: You do not have permission to view equipment.',
  },
  {
    message: 'You are not allowed to view categories',
    pt_BR: 'Erro: Você não possui permissão para visualizar categorias.',
    es_ES: 'Error: No tienes permiso para ver las categorías.',
    en_US: 'Error: You do not have permission to view categories.',
  },
  {
    message: 'You are not allowed to view schedules',
    pt_BR: 'Erro: Você não possui permissão para visualizar escalas.',
    es_ES: 'Error: No tienes permiso para ver las escalas.',
    en_US: 'Error: You do not have permission to view scales.',
  },
  {
    message: 'You are not allowed to view customers-contacts',
    pt_BR:
      'Erro: Você não possui permissão para visualizar contratos de clientes.',
    es_ES: 'Error: No tiene permiso para ver los contratos de los clientes.',
    en_US: 'Error: You do not have permission to view customer contracts.',
  },
  {
    message: 'You are not allowed to view customers',
    pt_BR: 'Erro: Você não possui permissão para visualizar clientes.',
    es_ES: 'Error: No tiene permiso para ver clientes.',
    en_US: 'Error: You do not have permission to view customers.',
  },
  {
    message: 'You are not allowed to view customers-segments',
    pt_BR:
      'Erro: Você não possui permissão para visualizar segmentos de clientes.',
    es_ES: 'Error: No tiene permiso para ver segmentos de clientes.',
    en_US: 'Error: You do not have permission to view customer segments.',
  },
  {
    message: 'You are not allowed to view groups-permissions',
    pt_BR:
      'Erro: Você não possui permissão para visualizar grupos de permissão.',
    es_ES: 'Error: No tiene permiso para ver grupos de permisos.',
    en_US: 'Error: You do not have permission to view permission groups.',
  },
  {
    message: 'You are not allowed to view groups',
    pt_BR:
      'Erro: Você não possui permissão para visualizar grupos de permissão.',
    es_ES: 'Error: No tiene permiso para ver grupos de permisos.',
    en_US: 'Error: You do not have permission to view permission groups.',
  },
  {
    message: 'You are not allowed to view teams',
    pt_BR: 'Erro: Você não possui permissão para visualizar equipes.',
    es_ES: 'Error: No tienes permiso para ver equipos.',
    en_US: "Error: You don't have permission to view teams.",
  },
  {
    message: 'You are not allowed to view contributors-skills',
    pt_BR:
      'Erro: Você não possui permissão para visualizar habilidades de colaboradores.',
    es_ES:
      'Error: No tienes permiso para ver las habilidades de los colaboradores.',
    en_US: 'Error: You do not have permission to view contributor skills.',
  },
  {
    message: 'You are not allowed to view contributors-evaluations',
    pt_BR:
      'Erro: Você não possui permissão para visualizar as avaliações de colaboradores.',
    es_ES:
      'Error: No tienes permiso para ver las reseñas de los colaboradores.',
    en_US: 'Error: You do not have permission to view contributor reviews.',
  },
  {
    message: 'You are not allowed to view contributors',
    pt_BR: 'Erro: Você não possui permissão para visualizar colaboradores.',
    es_ES: 'Error: No tienes permiso para ver colaboradores.',
    en_US: 'Error: You do not have permission to view contributors.',
  },
  {
    message: 'You are not allowed to view contacts-addresses',
    pt_BR:
      'Erro: Você não possui permissão para visualizar endereços de contatos.',
    es_ES: 'Error: No tiene permiso para ver las direcciones de contacto.',
    en_US: 'Error: You do not have permission to view contact addresses.',
  },
  {
    message: 'You are not allowed to view addresses',
    pt_BR: 'Erro: Você não possui permissão para visualizar endereços.',
    es_ES: 'Error: No tienes permiso para ver direcciones.',
    en_US: 'Error: You do not have permission to view addresses.',
  },
  {
    message: 'You are not allowed to view contacts',
    pt_BR: 'Erro: Você não possui permissão para visualizar contatos.',
    es_ES: 'Error: No tienes permiso para ver contactos.',
    en_US: 'Error: You do not have permission to view contacts.',
  },
  {
    message: 'You are not allowed to view users',
    pt_BR: 'Erro: Você não possui permissão para visualizar usuários.',
    es_ES: 'Error: No tienes permiso para ver usuarios.',
    en_US: 'Error: You do not have permission to view users.',
  },
  {
    message: 'You are not allowed to view contributors-status',
    pt_BR:
      'Erro: Você não tem permissão para visualizar status de colaboradores.',
    es_ES:
      'Error: No tienes permiso para ver los estados de los colaboradores.',
    en_US: 'Error: You do not have permission to view contributor statuses.',
  },
]

export const ShowError = (
  errorMessage: string,
  backupMessage: string,
  locale: string,
): any => {
  const erro = apiErrors.find((x) => x.message === errorMessage)
  if (erro) {
    if (locale === 'pt-BR') {
      message.error(erro.pt_BR)
    } else if (locale === 'es-ES') {
      message.error(erro.es_ES)
    } else if (locale === 'en-US') {
      message.error(erro.en_US)
    }
  } else {
    VerifyGeneralWarnings(errorMessage, backupMessage, locale)
  }
}

const VerifyGeneralWarnings = (
  errorMessage: string,
  backupMessage: string,
  locale: string,
): any => {
  const erro = apiGeneralWarnings.find((x) => x.message === errorMessage)
  if (erro) {
    if (locale === 'pt-BR') {
      message.warning(erro.pt_BR)
    } else if (locale === 'es-ES') {
      message.warning(erro.es_ES)
    } else if (locale === 'en-US') {
      message.warning(erro.en_US)
    }
  } else {
    VerifyPermissionsWarnings(errorMessage, backupMessage, locale)
  }
}

const VerifyPermissionsWarnings = (
  errorMessage: string,
  backupMessage: string,
  locale: string,
): any => {
  const erro = apiVisualizationsWarnings.find((x) => x.message === errorMessage)
  if (erro) {
    if (locale === 'pt-BR') {
      console.log(erro.pt_BR)
    } else if (locale === 'es-ES') {
      console.log(erro.es_ES)
    } else if (locale === 'en-US') {
      console.log(erro.en_US)
    }
  } else {
    message.error(backupMessage)
  }
}
