export default {
  pt: {
    MaintenancePlanningTableLegend: {
      statusReceived: 'Enviada',
      statusViewed: 'Visualizada',
      statusEnRoute: 'A caminho',
      statusWorking: 'Executando',
      statusCheckin: 'Aceita',
      statusBreak: 'Pausada',
      statusCanceled: 'Cancelada',
      statusFinished: 'Finalizada',
      statusUndefined: 'Status não definido',
      taskNotFilled: 'Tarefa não atribuida',
    },
  },
  en: {
    MaintenancePlanningTableLegend: {
      statusReceived: 'Submitted',
      statusViewed: 'Viewed',
      statusEnRoute: 'On the way',
      statusWorking: 'Running',
      statusCheckin: 'Accepted',
      statusBreak: 'Paused',
      statusCanceled: 'Canceled',
      statusFinished: 'Finished',
      statusUndefined: 'Status not defined',
      taskNotFilled: 'Unassigned task',
    },
  },
  es: {
    MaintenancePlanningTableLegend: {
      statusReceived: 'Enviada',
      statusViewed: 'Vista',
      statusEnRoute: 'En camino',
      statusWorking: 'Ejecutando',
      statusCheckin: 'Aceptado',
      statusBreak: 'En pausa',
      statusCanceled: 'Cancelada',
      statusFinished: 'Terminada',
      statusUndefined: 'Estado no definido',
      taskNotFilled: 'Tarea no asignada',
    },
  },
}
