/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { Helmet } from 'react-helmet'

import { Container } from './styles'

import { ListGroupProvider } from '~/hooks/Groups/useListGroup'
import ContributorTabs from '~/components/DataManipulation/Contributor/ContributorTabs'
import { translate } from '~/utils/locale'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'

const NewContributor: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>{translate('NewContributor.helmetTitle')}</title>
      </Helmet>

      <ListGroupProvider>
        <TeamsProvider>
          <ContributorTabs isCreation />
        </TeamsProvider>
      </ListGroupProvider>
    </Container>
  )
}

export default NewContributor
