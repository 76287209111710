/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import React, { useContext, createContext, useState } from 'react'

import { useQuery } from 'react-query'
import api from '../../../services/api'
import { useCompanies } from '../../Companies/useCompanies'
import { ShowError } from '~/utils/errors/apiErrors'
import { TaskListProps } from './tasksStatusModel'
import { useFilterKanban } from './useFilterKanban'
import { useLocale } from '~/hooks/locale/useLocale'
import { useAuth } from '~/hooks/Auth/AuthContext'

interface TaskCanceledContextData {
  taskCanceled?: TaskListProps[]
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  tasksCanceledTotal?: number
  loadDataCanceled: () => void
  hasMore: boolean
}

const TaskCanceledContext = createContext<TaskCanceledContextData>(
  {} as TaskCanceledContextData,
)

export const TaskCanceledProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { paramsFilter, filterOptions } = useFilterKanban()
  const { locale } = useLocale()
  const { user } = useAuth()

  const [tasksCanceledTotal, setTasksCanceledTotal] = useState<number>()

  const [hasMore, setHasMore] = useState(true)

  const [limit, setLimit] = useState<number>(20)
  const limitDefault = 5

  const {
    data: taskCanceled,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`tarefas canceladas${selectedCompany}`, selectedCompany, filterOptions],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/tasks`, {
          params: {
            offset: 0,
            limit,
            current_status: 'canceled',
            role: user?.role,
            rolePermissions: user?.rolePermission,
            responsibleContributors: paramsFilter?.responsibleContributors,
            responsibleTeams: paramsFilter?.responsibleTeams,
            address: paramsFilter?.address,
            customers_id: paramsFilter?.customers_id,
            internal_code: paramsFilter?.internal_code,
            external_code: paramsFilter?.external_code,
            task_type_id: paramsFilter?.task_type_id,
            priority: paramsFilter?.priority,
            fromDate: paramsFilter?.fromDate,
            toDate: paramsFilter?.toDate,
          },
        })

        const { data } = response

        setTasksCanceledTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, 'Erro ao visualizar o status da tarefa.', locale)
      }
    },
  )

  function loadDataCanceled() {
    setLimit(limit + limitDefault)
    setHasMore(false)

    refetch()
  }

  return (
    <TaskCanceledContext.Provider
      value={{
        taskCanceled,
        isLoading,
        refetch,
        isFetching,
        tasksCanceledTotal,
        loadDataCanceled,
        hasMore,
      }}
    >
      {children}
    </TaskCanceledContext.Provider>
  )
}

export function useTaskCanceled(): TaskCanceledContextData {
  const context = useContext(TaskCanceledContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
