/* eslint-disable no-constant-condition */
import React, { useState } from 'react'

import {
  Menu,
  Avatar,
  Dropdown,
  Button,
  Grid,
  Row,
  Col,
  Divider,
  Space,
  Tooltip,
  Tour,
} from 'antd'
import {
  LogoutOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  QuestionOutlined,
  PictureOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'

import { Link, useLocation, useParams } from 'react-router-dom'
import NewCompanyModal from '~/components/DefaultLayout/NewCompanyModal'
import SelectCompany from '~/components/DefaultLayout/SelectCompany'
import LocaleSelector from '~/components/DataManipulation/Misc/LocaleSelector'

import { useAuth } from '~/hooks/Auth/AuthContext'

import { stringToHslColor } from '~/utils/stringToHslColor'
import { createLetterAvatar } from '~/utils/letter'
import { translate } from '~/utils/locale'
import DropdownNotifications from '~/components/DataManipulation/Misc/DropdownNotifications'
import { useProfile } from '~/hooks/Auth/useProfile'
import ServiceStatus from '~/components/DataManipulation/ServiceStatus'
import { useServiceStatus } from '~/hooks/ServiceStatus/useServiceStatus'
import CompanyLogo from '../CompanyLogo'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useTour } from '~/hooks/Tour/useTour'
import { FaMoneyBill } from 'react-icons/fa'
// import Payment from './Payment'

interface NavbarProps {
  onCollapseSidebar: () => void
  isCollapsed: boolean
}

const { useBreakpoint } = Grid

const Navbar: React.FC<NavbarProps> = ({ onCollapseSidebar, isCollapsed }) => {
  const screens = useBreakpoint()
  const { signOut, user } = useAuth()
  const { profile } = useProfile()
  const { currentCompanyData } = useCompanies()
  const { serviceStatus } = useServiceStatus()

  const location = useLocation()
  const { ticketId } = useParams<{ ticketId: string }>()

  const [isNewCompanyModalOpen, setIsNewCompanyModalOpen] = useState(false)

  const enabledCompany = localStorage.getItem('@Gstor:enabledCompany')

  const [isCompanyLogoModalOpen, setIsCompanyLogoModalOpen] = useState(false)

  const [open, setOpen] = useState<boolean>(false)

  const { initialSteps, setIsTour } = useTour()

  // const payment = localStorage.getItem('@Gstor:payment')

  function handleOpenNewCompany() {
    setIsNewCompanyModalOpen(true)
  }

  function handleCloseNewCompany() {
    setIsNewCompanyModalOpen(false)
  }

  function handleOpenCompanyLogo() {
    setIsCompanyLogoModalOpen(true)
  }

  function handleCloseCompanyLogo() {
    setIsCompanyLogoModalOpen(false)
  }

  function onClose() {
    setIsTour(false)
    setOpen(false)
  }

  const menu = (
    <Menu style={{ padding: '0.5rem' }} key="DropdownMenu">
      <Space direction="vertical">
        <Text>{profile && profile.name}</Text>
        <Text type="secondary">{profile && profile.email}</Text>
      </Space>

      <CompanyLogo
        isOpen={isCompanyLogoModalOpen}
        onRequestClose={handleCloseCompanyLogo}
        imageData={currentCompanyData?.company.logo_url}
      />

      <Menu.Item icon={<PictureOutlined />} onClick={handleOpenCompanyLogo}>
        Logo
      </Menu.Item>

      <Menu.Item icon={<FaMoneyBill />}>
        <Link to="pagamento/visualizar">Assinatura</Link>
      </Menu.Item>

      {/* <Menu.Item disabled icon={<UserOutlined />}>
                    Meu Perfil
                  </Menu.Item>

                  <Menu.Item disabled icon={<SettingOutlined />}>
                    Configurações
                  </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item icon={<LogoutOutlined />} onClick={signOut} danger>
        {translate('Navbar.exitButtonText')}
      </Menu.Item>
    </Menu>
  )

  const local = location.pathname

  return (
    <Row gutter={10}>
      <Col
        flex="none"
        style={{
          display: 'flex',
          alignItems: 'left',
          alignSelf: 'center',
        }}
      >
        {screens.sm && (
          <Button
            type="default"
            icon={isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={onCollapseSidebar}
          />
        )}
      </Col>

      {enabledCompany === 'true' ? (
        <>
          {local !== '/helpdesk' &&
          local !== '/ticket' &&
          local !== '/novoTicket' &&
          local !== `/ticket/${ticketId}` ? (
            <Col>
              <Link to="/novaTarefa">
                <Button type="primary">
                  {translate('Navbar.createTaskButtonText')}
                </Button>
              </Link>
            </Col>
          ) : (
            <>
              <Col>
                <p style={{ color: 'white' }}>HelpDesk</p>
              </Col>

              <Col>
                <Divider type="vertical" style={{ background: 'white' }} />
              </Col>

              <Col>
                <Link to="/novoTicket">
                  <Button type="primary">Novo Ticket</Button>
                </Link>
              </Col>
            </>
          )}

          {local !== '/helpdesk' &&
          local !== '/ticket' &&
          local !== '/novoTicket' &&
          local !== `/ticket/${ticketId}` ? (
            <Link to="/ajuda">
              <Tooltip title="Ajuda">
                <Button
                  icon={<QuestionOutlined />}
                  shape="circle"
                  size="small"
                />
              </Tooltip>
            </Link>
          ) : (
            <Link to="/ajuda">
              <Tooltip title="Ajuda">
                <Button
                  icon={<QuestionOutlined />}
                  shape="circle"
                  ghost
                  size="small"
                  style={{ color: 'white' }}
                />
              </Tooltip>
            </Link>
          )}
        </>
      ) : (
        ''
      )}

      <Col>
        <Tooltip title="Tour Gstor">
          <Button
            icon={<UnorderedListOutlined />}
            shape="circle"
            size="small"
            onClick={() => setOpen(true)}
          />
        </Tooltip>
      </Col>

      <Col flex="auto">
        <Row align="middle" justify="end" gutter={[18, 24]}>
          {enabledCompany === 'true' ? (
            <>
              {(serviceStatus?.executionTask === false ||
                serviceStatus?.newTask === false) && (
                <Col>
                  <ServiceStatus />
                </Col>
              )}
              <Col>
                <DropdownNotifications />
              </Col>
            </>
          ) : (
            ''
          )}

          {/* {payment ||
          payment === 'CONFIRMED' ||
          payment === 'RECEIVED' ||
          payment === 'ACTIVE' ? (
            <Payment />
          ) : (
            ''
          )} */}

          {screens.sm && <Divider type="vertical" />}
          <Col>
            <LocaleSelector />
          </Col>
          <Col flex={screens.sm ? '200px' : 'auto'}>
            <SelectCompany onRequestOpen={handleOpenNewCompany} />
            <NewCompanyModal
              isOpen={isNewCompanyModalOpen}
              onRequestClose={handleCloseNewCompany}
            />
          </Col>
          {screens.sm && <Divider type="vertical" />}
          <Col>
            <Dropdown dropdownRender={() => menu} placement="bottomLeft">
              <div>
                <Avatar
                  alt={user.email}
                  style={{
                    background: stringToHslColor(`${profile && profile.name}`),
                  }}
                >
                  {createLetterAvatar(`${profile && profile.name}}`)}
                </Avatar>

                <DownOutlined style={{ marginLeft: '0.25rem' }} />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Col>

      <Tour
        open={open}
        onClose={onClose}
        steps={initialSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </Row>
  )
}

export default Navbar
