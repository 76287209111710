/* eslint-disable no-param-reassign */
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: { Authorization: '' },
})

api.interceptors.request.use(async (config) => {
  try {
    const token = localStorage.getItem('@Gstor:token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  } catch (error) {
    console.log(error)
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response.status === 400 &&
      error.response.data?.validation.query.message === '"role" is required'
    ) {
      localStorage.removeItem('@Gstor:token')
      localStorage.removeItem('@Gstor:user')
      localStorage.removeItem('@Gstor:currentCompany')
      localStorage.removeItem('@Gstor:contributorFilterOptions')
      localStorage.removeItem('@Gstor:scalesFilterOptions')
      window.location.replace('/')
    }
    if (error.response.status === 401) {
      if (error.response.data?.message === 'Invalid JWT token') {
        localStorage.removeItem('@Gstor:token')
        localStorage.removeItem('@Gstor:user')
        localStorage.removeItem('@Gstor:currentCompany')
        localStorage.removeItem('@Gstor:contributorFilterOptions')
        localStorage.removeItem('@Gstor:scalesFilterOptions')
        window.location.replace('/')
      }
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.message)
  },
)

export default api
