import React from 'react'
import MaintenanceTabs from '~/components/DataManipulation/Maintenance/Tabs'
import { ActivityProvider } from '~/hooks/Maintenance/Activity/useActivity'
import { ActivityTasksProvider } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'
import { DatabookProvider } from '~/hooks/Maintenance/Databook/useDatabok'
import { SystemProvider } from '~/hooks/Maintenance/System/useSystem'
import { ListMaintenanceProvider } from '~/hooks/Maintenance/useMaintenance'

import { MaintenanceShowProvider } from '~/hooks/Maintenance/useShowMaintenance'
import { ClientsListTaskProvider } from '~/hooks/Tasks/Create/clientsListTask'
import { ContributorsTaskProvider } from '~/hooks/Tasks/Create/useContributorTask'
import { TaskTypeProvider } from '~/hooks/Tasks/TaskType/useTaskType'

// import { Container } from './styles';

const ShowMaintenance: React.FC = () => {
  return (
    <MaintenanceShowProvider>
      <ClientsListTaskProvider>
        <ContributorsTaskProvider>
          <ListMaintenanceProvider>
            <ActivityProvider>
              <SystemProvider>
                <DatabookProvider>
                  <TaskTypeProvider>
                    <ActivityTasksProvider>
                      <MaintenanceTabs />
                    </ActivityTasksProvider>
                  </TaskTypeProvider>
                </DatabookProvider>
              </SystemProvider>
            </ActivityProvider>
          </ListMaintenanceProvider>
        </ContributorsTaskProvider>
      </ClientsListTaskProvider>
    </MaintenanceShowProvider>
  )
}

export default ShowMaintenance
