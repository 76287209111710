/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import TimeInput from '~/components/DataManipulation/Misc/TimeInput'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import api from '~/services/api'
import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { formatPhoneNumber } from 'react-phone-number-input'
import { useLocale } from '~/hooks/locale/useLocale'
import { ClientsProps } from '~/hooks/Clients/useClients'

// import { Container } from './styles';

interface CreateTaskActivityProps {
  _id: string
  maintenance_id: string
  task_type_id: string
  informations: {
    initialDate: string
    responsible: {
      id: string
      name: string
    }
    activity: string
  }
}

interface TaskActivityProps {
  data: CreateTaskActivityProps | any
  type: 'button' | 'insideTable'
  selectedDate?: dayjs.Dayjs
  divValue?: string
  divStyle?: React.CSSProperties
}

interface AdditionalPhonesProps {
  phone: string
  type: string
  is_whatsapp: boolean
  formattedPhone: string
}

const CreateTaskToActivity: React.FC<TaskActivityProps> = ({
  data,
  selectedDate,
  type,
  divValue,
  divStyle,
}) => {
  const { maintenance } = useShowMaintenance()
  const { refetch } = useActivityTasks()
  const { taskType } = useTaskType()
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [customerData, setCustomerData] = useState<ClientsProps>(
    {} as ClientsProps,
  )

  const taskTypeData = taskType?.find((task) => task._id === data.task_type_id)

  const initialValues = {
    initialDate: dayjs(data.informations.initialDate),
    responsible: data.informations.responsible?.name,
    task_type_id: taskTypeData?.title,
    duration_time: taskTypeData?.runtime,
    description: data.informations.activity,
    priority: 'low',
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const [loading, setLoading] = useState(false)

  const CreateTaskActivity = async (valueForm: any) => {
    setLoading(true)

    const address = maintenance?.customer?.address
      ? {
          zip_code: maintenance?.customer?.address?.zip_code,
          street: maintenance?.customer?.address?.street,
          number: maintenance?.customer?.address?.number,
          complement: maintenance?.customer?.address?.complement,
          neighborhood: maintenance?.customer?.address?.neighborhood,
          city: maintenance?.customer?.address?.city,
          state: maintenance?.customer?.address?.state,
          location: {
            x: maintenance?.customer?.address?.location?.x,
            y: maintenance?.customer?.address?.location?.y,
          },
        }
      : customerData.address[0].address

    const dataTask = {
      customer_id: maintenance?.customer?.id,
      responsible: data.informations.responsible
        ? {
            id: data?.informations?.responsible?.id,
            type: 'collaborator',
          }
        : undefined,
      task_type_id: data.task_type_id,
      duration_time: valueForm?.duration_time,
      description: valueForm.description,
      priority: valueForm.priority,
      note: 'Nenhuma nota fornecida',
      date: dayjs(valueForm.initialDate),
      address,
    }

    try {
      const createTaskResponse = await api.post(
        `/company/${selectedCompany}/task`,
        dataTask,
      )

      const taskData = createTaskResponse.data

      await api.post(`/company/${selectedCompany}/addTaskToActivity`, {
        activity_task_id: data._id,
        maintenance_id: maintenance?._id,
        tasks: [
          {
            taskId: taskData._id,
            created: taskData.date,
            status: taskData.current_status,
            plan: maintenance?._id,
          },
        ],
      })

      message.success('Tarefa criada com sucesso!')
      refetch()
      setIsModalVisible(false)
    } catch (err) {
      // console.log('ERRO: ', err)
      message.error('Houve um erro ao criar a tarefa')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (selectedDate) {
        form.setFieldValue('initialDate', selectedDate)
      }

      try {
        const response = await api.get(
          `/company/${selectedCompany}/customer/${maintenance?.customer?.id}`,
        )

        const { data } = response

        if (data.contact.phone) {
          data.contact.phone = `+${data.contact.phone}`
          data.contact.formattedPhone = formatPhoneNumber(data.contact.phone)

          if (data.contact.formattedPhone.replace(/[0-9]/g, '') === '') {
            if (data.contact.formattedPhone.length === 10) {
              const forceFormat = data.contact.formattedPhone.match(
                /^(\d{2})(\d{4})(\d{4})$/,
              )

              if (forceFormat) {
                data.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            } else if (data.contact.formattedPhone.length === 9) {
              const forceFormat = data.contact.formattedPhone.match(
                /^(\d{2})(\d{4})(\d{3})$/,
              )

              if (forceFormat) {
                data.contact.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
              }
            }
          }
        }

        if (
          data.contact.person_type === 'legalPerson' ||
          data.contact.person_type === 'physicalPerson'
        ) {
          if (data.contact.document) {
            const match = data.contact.document.match(
              /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
            )

            if (match) {
              data.contact.formattedDocument = `${match[1]}.${match[2]}.${match[3]}-${match[4]}`
            }
          }
        } else {
          data.contact.formattedDocument = data.contact.document
        }

        if (data.contact.additional_phones) {
          data.contact.additional_phones.forEach(
            (additional_phone: AdditionalPhonesProps) => {
              if (additional_phone.phone) {
                additional_phone.phone = `+${additional_phone.phone}`
                additional_phone.formattedPhone = formatPhoneNumber(
                  additional_phone.phone,
                )

                if (
                  additional_phone.formattedPhone.replace(/[0-9]/g, '') === ''
                ) {
                  if (additional_phone.formattedPhone.length === 10) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{4})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  } else if (additional_phone.formattedPhone.length === 9) {
                    const forceFormat = additional_phone.formattedPhone.match(
                      /^(\d{2})(\d{4})(\d{3})$/,
                    )

                    if (forceFormat) {
                      additional_phone.formattedPhone = `(${forceFormat[1]}) ${forceFormat[2]}-${forceFormat[3]}`
                    }
                  }
                }
              }
            },
          )
        }

        const dataFormatted = {
          billing_email: data.billing_email,
          colaborator: data.colaborator,
          corporate_name: data.corporate_name,
          created_at: data.created_at,
          external_code: data.external_code,
          icms_taxpayer: data.icms_taxpayer,
          is_enabled: data.is_enabled,
          municipal_registration: data.municipal_registration,
          id: data.id,
          state_registration: data.state_registration,
          is_responsible: data.is_responsible,
          notes: data.notes,
          contact: data.contact,
          segment: data.segment,
          collaborator: data.collaborator,
          address: data.contact.contactAddresses,
          phones: data.contact.additional_phones,
          parent_customer: data.parent_customer,
          parent_cnpj: data.parent_cnpj,
          company: data.company,
        }

        setCustomerData(dataFormatted)
      } catch (err: any) {
        ShowError(err.message, translate('useClients.getErrorMessage'), locale)
      }
    }

    fetchData()
  }, [selectedDate, form, maintenance, selectedCompany, locale])

  return (
    <>
      {type === 'button' ? (
        <Tooltip title="Criar tarefa">
          <Button
            ghost
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={showModal}
            size="small"
          />
        </Tooltip>
      ) : (
        <div style={divStyle} onClick={showModal}>
          {divValue}
        </div>
      )}
      <Modal
        title="Criar tarefa para atividade"
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="800px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseCreate.closeButton')}
          </Button>,
          <Button
            key="save"
            type="primary"
            form="formCreateTaskToActivityManual"
            htmlType="submit"
            loading={loading}
            disabled={!customerData && !maintenance?.customer?.address}
          >
            {translate('TasksRegistrationPauseCreate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={CreateTaskActivity}
          form={form}
          id="formCreateTaskToActivityManual"
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('TasksCreateDetails.dateLabel')}
                    <HelpTooltip
                      title={translate('TasksCreateDetails.dateHelpTooltip')}
                    />
                  </Space>
                }
                name="initialDate"
                rules={[
                  {
                    required: true,
                    message: translate('TasksCreateDetails.dateRule'),
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder={translate('TasksFilter.fromDatePlaceholder')}
                  style={{ width: '100%' }}
                  showTime={{ format: 'HH:mm' }}
                  disabledDate={() => !!selectedDate}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Responsável" name="responsible">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksCreateDetails.taskTypeLabel')}
                name="task_type_id"
                rules={[
                  {
                    required: true,
                    message: translate('TasksCreateDetails.taskTypeRule'),
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('TasksCreateDetails.durationLabel')}
                    <HelpTooltip
                      title={translate(
                        'TasksCreateDetails.durationHelpTooltip',
                      )}
                    />
                  </Space>
                }
                name="duration_time"
              >
                <TimeInput
                  // value={time}
                  // onChange={handleTimeChange}
                  placeholder="HH:mm:ss"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksCreateDetails.priorityLabel')}
                name="priority"
              >
                <Select
                  placeholder={translate(
                    'TasksCreateDetails.priorityPlaceholder',
                  )}
                >
                  <Select.Option value="high">
                    {translate('TasksCreateDetails.priorityHigh')}
                  </Select.Option>
                  <Select.Option value="medium">
                    {translate('TasksCreateDetails.priorityMedium')}
                  </Select.Option>
                  <Select.Option value="low">
                    {translate('TasksCreateDetails.priorityLow')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('TasksCreateDetails.descriptionLabel')}
                name="description"
              >
                <Input.TextArea
                  placeholder={translate(
                    'TasksCreateDetails.descriptionPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default CreateTaskToActivity
