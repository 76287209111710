import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import Text from 'antd/lib/typography/Text'
import { Button, Table, Tooltip } from 'antd'
import { LoadingOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'
import NumberFormat from 'react-number-format'

// import { Container } from './styles';

interface ContactsProps {
  phone: string
  _id: string
  responsible_name: string
}

interface ContactsTablePlanningProps {
  dataContact: ContactsProps[]
}

const ContactsTablePlanning: React.FC<ContactsTablePlanningProps> = ({
  dataContact,
}) => {
  const { isFetching } = useActivityTasks()

  const columns: ColumnsType<ContactsProps> = [
    {
      title: 'Colaborador',
      dataIndex: 'responsible_name',
      key: 'collaborator',
    },
    {
      title: 'Celular',
      key: 'phone',
      render: (record: ContactsProps) => (
        <Text style={{ fontSize: 16 }}>
          <NumberFormat
            value={record.phone}
            displayType="text"
            format="+## (##) #####-####"
          />
        </Text>
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (record: ContactsProps) => (
        <Tooltip title="Enviar mensagem no whatsapp">
          <Button
            icon={<WhatsAppOutlined />}
            type="primary"
            style={{ backgroundColor: '#25D366', borderColor: '#25D366' }}
            href={`https://wa.me/${record.phone}`}
            target="_blank"
          >
            Whatsapp
          </Button>
        </Tooltip>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record?._id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      size="small"
      scroll={{ x: 1000 }}
      columns={columns}
      dataSource={dataContact}
      pagination={{
        showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
      }}
    />
  )
}

export default ContactsTablePlanning
