/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { translate } from '~/utils/locale'

import { Container } from './styles'
import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'
import dayjs from 'dayjs'

const FilterActivityTasks: React.FC = () => {
  const {
    handleUpdateFilters,
    handleResetFilters,
    filterOptions,
    activityTasks,
  } = useActivityTasks()
  const { data } = useContributorsTask()
  const { system } = useSystem()

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      system: filterOptions?.system,
      competence: filterOptions?.competence,
      component: filterOptions?.component,
      activity: filterOptions?.activity,
      data_font: filterOptions.data_font,
      type: filterOptions?.type,
      interval: filterOptions?.interval,
      hasTasks: filterOptions?.hasTasks ?? undefined,
      fromDate: dayjs().subtract(3, 'month'),
      toDate: dayjs(new Date()),
    })
  }, [filterOptions, form])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (dataForm: any) => {
    const filters = { ...dataForm }

    if (filters.hasTasks === undefined) {
      delete filters.hasTasks
    }

    handleUpdateFilters(dataForm)
    setVisible(false)
  }

  const onReset = () => {
    handleResetFilters()
    form.resetFields()
    setVisible(false)
  }

  const isFilter = Object.keys(filterOptions).length !== 0

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        loading={!activityTasks}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('TaskTypesFilter.cleanFilterTooltip')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_activity_tasks_maintenance"
      >
        {isFilter
          ? translate('MaintenanceActivity.AppliedFilter')
          : translate('MaintenanceActivity.Filters')}
      </Button>
      <Drawer
        forceRender
        title={translate('MaintenanceActivity.FiltersTitle')}
        width={580}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.fromDateLabel')}
                name="fromDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.fromDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.toDateLabel')}
                name="toDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.toDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceActivity.SelectSystem')}
                name="system"
              >
                <Select
                  placeholder={translate('MaintenanceActivity.SelectSystems')}
                  optionFilterProp="children"
                  showSearch
                >
                  {system?.map((s) => (
                    <Select.Option key={s._id} value={s.name}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('MaintenanceCreate.Component')}
                name="component"
              >
                <Input
                  placeholder={translate('SystemCreate.componentPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item label="Atividade" name="activity">
                <Input.TextArea placeholder="Informe o nome da atividade" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Type')}
                    <HelpTooltip title="Refere-se ao intervalo de repetição do evento ou ação." />
                  </Space>
                }
                name="type"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.TypePlaceholder')}
                >
                  <Select.Option value="day">
                    {translate('MaintenanceCreate.Daily')}
                  </Select.Option>
                  <Select.Option value="sem">
                    {translate('MaintenanceCreate.Weekly')}
                  </Select.Option>
                  <Select.Option value="men">
                    {translate('MaintenanceCreate.Monthly')}
                  </Select.Option>
                  <Select.Option value="anu">
                    {translate('MaintenanceCreate.Yearly')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Interval')}
                    <HelpTooltip title="Representa o número de ciclos de frequência antes de cada repetição" />
                  </Space>
                }
                name="interval"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.EnterInterval')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Equipe/Competência" name="competence">
                <Input placeholder="Informe a equipe/competência" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Executor" name="responsibleId">
                <Select
                  placeholder={translate('MaintenanceActivity.SelectSystems')}
                  optionFilterProp="children"
                  showSearch
                >
                  {data?.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Filtrar por tarefas" name="hasTasks">
                <Select placeholder="Selecione um filtro" allowClear>
                  <Select.Option value="true">
                    Somente com tarefas
                  </Select.Option>
                  <Select.Option value="false">
                    Somente sem tarefas
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Documento" name="data_font">
                <Input placeholder="Informe o documento" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('MaintenanceActivity.Apply')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button onClick={onReset} style={{ width: '100%' }}>
                  {translate('MaintenanceActivity.Clear')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default FilterActivityTasks
