import {
  CopyOutlined,
  MailOutlined,
  ShareAltOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons'
import { Button, Col, message, Modal, Space, Tooltip } from 'antd'
import React, { useState } from 'react'
import { FaTelegramPlane } from 'react-icons/fa'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useTask } from '~/hooks/Tasks/useTask'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

const LinkTask: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { task } = useTask()

  const { selectedCompany } = useCompanies()

  const linkTask = `https://tasks.gstor.com.br/?auth=${selectedCompany},${task?.responsible?.id}`

  const showModal = async () => {
    // const accessToken = process.env.REACT_APP_BITLY_ACCESS_TOKEN
    // const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   body: JSON.stringify({ long_url: linkTask }),
    // })
    // const data = await response.json()
    // setShortUrl(data.link)

    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const copyToClipboard = (): void => {
    navigator.clipboard.writeText(linkTask)
    message.success(translate('TasksTabsDetailsLink.messageSuccess'))
  }

  return (
    <>
      <Tooltip title={translate('TasksTabsDetailsLink.tooltipTitle')}>
        <Button
          icon={<ShareAltOutlined />}
          ghost
          type="primary"
          onClick={showModal}
          shape="circle"
        />
      </Tooltip>

      <Modal
        title={translate('TasksTabsDetailsLink.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={null}
      >
        <Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 20,
            }}
          >
            <Tooltip title={translate('TasksTabsDetailsLink.whatsapp')}>
              <Space direction="vertical" align="center">
                <Button
                  ghost
                  type="primary"
                  shape="circle"
                  icon={<WhatsAppOutlined />}
                  href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    linkTask,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Space>
            </Tooltip>

            <Tooltip title={translate('TasksTabsDetailsLink.telegram')}>
              <Space direction="vertical" align="center">
                <Button
                  ghost
                  type="primary"
                  shape="circle"
                  icon={<FaTelegramPlane />}
                  href={`https://telegram.me/share/url?url=${encodeURIComponent(
                    linkTask,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Space>
            </Tooltip>

            <Tooltip title={translate('TasksTabsDetailsLink.email')}>
              <Space direction="vertical" align="center">
                <Button
                  ghost
                  type="primary"
                  shape="circle"
                  icon={<MailOutlined />}
                  href={`mailto:?subject=Confira este link&body=${encodeURIComponent(
                    linkTask,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Space>
            </Tooltip>

            <Tooltip title={translate('TasksTabsDetailsLink.copyText')}>
              <Space direction="vertical" align="center">
                <Button
                  ghost
                  type="primary"
                  shape="circle"
                  icon={<CopyOutlined />}
                  onClick={copyToClipboard}
                />
              </Space>
            </Tooltip>
          </div>
        </Col>
      </Modal>
    </>
  )
}

export default LinkTask
