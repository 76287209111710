/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
import { Button, Col, DatePicker, Form, InputNumber, Row, Select } from 'antd'

import React, { useState, useEffect } from 'react'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useReportKilometers } from '~/hooks/Report/Kilometers/useReportKilometers'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const ReportFilterKilometer: React.FC = () => {
  const [selectResponsible, setSelectResponsible] = useState('')

  const { data } = useListContributors()
  const { teams } = useTeams()
  const { setDataKm, setSendFilter, filterOptions, handleUpdateFilters } =
    useReportKilometers()

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      responsibleId: filterOptions?.responsibleId,
      teamResponsibleId: filterOptions?.teamResponsibleId,
      kmValue: filterOptions?.kmValue,
    })
  }, [filterOptions, form])

  const onFinish = (dataForm: any) => {
    handleUpdateFilters(dataForm)
    setDataKm(true)
    setSendFilter(true)

    // console.log(dataForm)
  }

  function onSelectResponsible(value: string) {
    setSelectResponsible(value)
  }

  return (
    <Container>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={10}>
          <Col span={4}>
            <Form.Item
              label={translate('ReportKilometersFilter.kmValueLabel')}
              name="kmValue"
              rules={[
                {
                  required: true,
                  message: translate('ReportKilometersFilter.kmValueRule'),
                },
              ]}
            >
              <InputNumber
                placeholder={translate(
                  'ReportKilometersFilter.kmValuePlaceholder',
                )}
                precision={2}
                step={0.01}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={translate('ReportKilometersFilter.fromDateLabel')}
              name="fromDate"
              rules={[
                {
                  required: true,
                  message: translate('ReportKilometersFilter.fromDateRule'),
                },
              ]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={translate('ReportKilometersFilter.toDateLabel')}
              name="toDate"
              rules={[
                {
                  required: true,
                  message: translate('ReportKilometersFilter.toDateRule'),
                },
              ]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={translate('ReportKilometersFilter.responsibleLabel')}
              name="responsible"
            >
              <Select
                placeholder={translate(
                  'ReportKilometersFilter.responsiblePlaceholder',
                )}
                onChange={onSelectResponsible}
              >
                <Select.Option value="colaborador">
                  {translate('ReportKilometersFilter.responsibleContributor')}
                </Select.Option>
                <Select.Option value="team">
                  {translate('ReportKilometersFilter.responsibleTeam')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            {selectResponsible === 'colaborador' ? (
              <Form.Item
                label={translate('ReportKilometersFilter.contributorLabel')}
                name="responsibleId"
              >
                <Select
                  placeholder={translate(
                    'ReportKilometersFilter.contributorPlaceholder',
                  )}
                >
                  {data.map((c) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              ''
            )}
            {selectResponsible === 'team' ? (
              <Form.Item
                label={translate('ReportKilometersFilter.teamLabel')}
                name="teamResponsibleId"
              >
                <Select
                  placeholder={translate(
                    'ReportKilometersFilter.teamPlaceholder',
                  )}
                >
                  {teams?.map((t) => (
                    <Select.Option value={t.id} key={t.id}>
                      {t.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          {translate('ReportKilometersFilter.submitButton')}
        </Button>
      </Form>
    </Container>
  )
}

export default ReportFilterKilometer
