import {
  CloseOutlined,
  FileUnknownOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Table,
  Tag,
} from 'antd'
import { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'
import { ColumnsType } from 'antd/lib/table'
import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import { Container } from '../AddActivity/styles'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import ActivityFilterModal from '../../../Activity/Filter'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'

// import { Container } from './styles';

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  activity?: string
  system?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
  }
  data_font: string
}

const CreateActivityMaintenance: React.FC = () => {
  const { isCreate, setIsCreate, maintenance } = useShowMaintenance()
  const { createActivityTasks } = useActivityTasks()
  const { data } = useContributorsTask()
  const { taskType } = useTaskType()
  const { activity, isFetching, activityTotal } = useActivity()

  const [current, setCurrent] = useState(0)
  const [date, setDate] = useState<Dayjs | null>(null)
  const [responsible, setResponsible] = useState<{
    id: string
    name: string
  } | null>(null)
  const [taskTypeData, setTaskTypeData] = useState<string>('')
  const [isAutomatic, setIsAutomatic] = useState('automatic')

  const [activityId, setActivityId] = useState<React.Key[]>([])
  const [activityData, setActivityData] = useState<ActivityProps[]>([])

  const [type, setType] = useState('')
  const [weekend, setWeekend] = useState('weekend')
  const [interval, setInterval] = useState<number | null>(null)

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    const regex = /^[0-9]*$/

    if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  const options = [
    { label: 'Todos os dias', value: 'everyday' },
    { label: 'Somente durante a semana', value: 'days' },
    { label: 'Sábado e Domingo', value: 'weekend' },
    { label: 'Somente Sábado', value: 'saturday' },
    { label: 'Somente Domingo', value: 'sunday' },
  ]

  const next = () => setCurrent((prev) => prev + 1)
  const prev = () => setCurrent((prev) => prev - 1)

  const columns: ColumnsType<ActivityProps> = [
    {
      title: translate('MaintenanceSystemTab.Title'),
      key: 'title',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (title: string) =>
        title || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'system',
      dataIndex: 'system',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (system: string) =>
        system || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceActivity.SelectComponents'),
      key: 'component',
      dataIndex: 'component',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (component: string) =>
        component || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Activities'),
      key: 'activity',
      dataIndex: 'activity',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (activity: string) =>
        activity || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Competence'),
      dataIndex: 'competence',
      key: 'competence',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (competence: string) =>
        competence || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Periodicity'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityProps) => (
        <>
          {record.periodicity ? (
            <>
              {record.periodicity.type === 'day' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Day')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Days')}
                    </Text>
                  )}
                </>
              ) : null}

              {record.periodicity.type === 'sem' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Week')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Weeks')}
                    </Text>
                  )}
                </>
              ) : null}

              {record.periodicity.type === 'men' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Months')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.MonthsPlural')}
                    </Text>
                  )}
                </>
              ) : null}

              {record.periodicity.type === 'anu' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Year')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Years')}
                    </Text>
                  )}
                </>
              ) : null}
            </>
          ) : (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys: activityId, // Mantém as seleções sincronizadas
    onChange: (selectedRowKeys: React.Key[], selectedRows: ActivityProps[]) => {
      setActivityId(selectedRowKeys)
      setActivityData(selectedRows)
    },
    getCheckboxProps: (record: ActivityProps) => ({
      disabled: record.title === 'Disabled User', // Exemplo de desativação
      name: record.title,
    }),
  }

  const steps = [
    {
      title: 'Data de Início da Atividade',
      content: (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="Selecione a data de início da atividade"
                name="date"
              >
                <DatePicker
                  style={{ width: '100%' }}
                  value={date}
                  format="DD/MM/YYYY"
                  onChange={(e: Dayjs | null) => setDate(e)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Executor/Fornecedor" name="responsible">
                <Select
                  placeholder="Executor/Fornecedor da Atividade"
                  onChange={(value) => {
                    const selectedItem = data?.find((c) => c.id === value)
                    if (selectedItem) {
                      setResponsible({
                        id: selectedItem.id,
                        name: selectedItem.contact.name,
                      })
                    } else {
                      setResponsible(null)
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  {data?.map((c) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Tarefas criadas" name="created">
                <Select
                  defaultValue="automatic"
                  style={{ width: '100%' }}
                  onChange={(value) => setIsAutomatic(value)}
                >
                  <Select.Option value="automatic">
                    Automaticamente
                  </Select.Option>
                  <Select.Option value="manual">Manualmente</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Tipo de tarefa" name="task_type_id">
                <Select
                  style={{ width: '100%' }}
                  placeholder="Selecione o tipo de tarefa"
                  onChange={(value) => setTaskTypeData(value)}
                  optionFilterProp="children"
                  showSearch
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 8,
                        }}
                      >
                        <Link
                          to="/tiposDeTarefas/novo"
                          target="_blank"
                          style={{ width: '100%' }}
                        >
                          <Button type="primary" style={{ width: '100%' }}>
                            Criar tipo de tarefa
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                >
                  {taskType?.map((t) => (
                    <Select.Option value={t._id} key={t._id}>
                      {t.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },

    {
      title: 'Selecionar atividade',
      content: (
        <Table
          rowKey={(record) => record?._id}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: activityTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
          columns={columns}
          size="middle"
          dataSource={activity}
          scroll={{ x: 1000 }}
        />
      ),
    },
    {
      title: 'Confirmar Periodicidade',
      content: (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Type')}
                    <HelpTooltip title="Refere-se ao intervalo de repetição do evento ou ação." />
                  </Space>
                }
                name="type"
              >
                <Select
                  onChange={(e) => setType(e)}
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.TypePlaceholder')}
                  defaultValue={
                    activityData ? activityData[0]?.periodicity?.type : ''
                  }
                >
                  <Select.Option value="day">
                    {translate('MaintenanceCreate.Daily')}
                  </Select.Option>
                  <Select.Option value="sem">
                    {translate('MaintenanceCreate.Weekly')}
                  </Select.Option>
                  <Select.Option value="men">
                    {translate('MaintenanceCreate.Monthly')}
                  </Select.Option>
                  <Select.Option value="anu">
                    {translate('MaintenanceCreate.Yearly')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Interval')}
                    <HelpTooltip title="Representa o número de ciclos de frequência antes de cada repetição" />
                  </Space>
                }
                name="interval"
              >
                <InputNumber
                  onChange={(e: number | null) => setInterval(e)}
                  onKeyPress={handleKeyPress}
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.EnterInterval')}
                  defaultValue={
                    activityData ? activityData[0]?.periodicity?.interval : 1
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {type === 'day' && interval === 1 ? (
            <Row>
              <Form.Item label="Atividades aos Finais de Semana" name="weekend">
                <Radio.Group
                  optionType="button"
                  options={options}
                  defaultValue="everyday"
                  onChange={(value) => setWeekend(value.target.value)}
                />
              </Form.Item>
            </Row>
          ) : (
            ''
          )}

          {type && interval && (
            <Container>
              {type === 'day' ? (
                <>
                  {weekend === 'everyday' ? (
                    <Tag className="tag" color="geekblue">
                      Atividade Diária - A cada {interval} dia em todos os dias
                      da semana <br />
                    </Tag>
                  ) : (
                    ''
                  )}

                  {weekend === 'days' ? (
                    <Tag className="tag" color="geekblue">
                      Atividade Diária - A cada {interval} dia durante semana{' '}
                      <br />
                    </Tag>
                  ) : (
                    ''
                  )}

                  {interval === 1 ? (
                    <>
                      {weekend === 'weekend' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos finais
                          de semana <br />
                        </Tag>
                      ) : (
                        ''
                      )}

                      {weekend === 'saturday' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos sábados
                          <br />
                        </Tag>
                      ) : (
                        ''
                      )}

                      {weekend === 'sunday' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos
                          domingos
                          <br />
                        </Tag>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <Tag className="tag" color="geekblue">
                      Atividade Diária - A cada {interval} dias <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'sem' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="purple">
                      Atividade Semanal - A cada uma semana <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="purple">
                      Atividade Semanal - A cada {interval} semanas <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'men' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="green">
                      Atividade Mensal - A cada um mês <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="green">
                      Atividade Mensal - A cada {interval} meses <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'anu' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="magenta">
                      Atividade Anual - A cada um ano <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="magenta">
                      Atividade Anual - A cada {interval} anos <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}
            </Container>
          )}
        </>
      ),
    },
  ]

  useEffect(() => {
    if (activityData.length > 0) {
      setType(activityData[0]?.periodicity?.type || '')
      setInterval(activityData[0]?.periodicity?.interval || null)
    } else {
      setType('')
      setInterval(null)
    }
  }, [activityData])

  const activityInfo = activityData[0]

  function AddActivity() {
    const dataActivityMain = {
      activity_id: activityInfo._id,
      maintenance_id: maintenance?._id,
      task_type_id: taskTypeData,
      is_automatic: isAutomatic === 'automatic',
      informations: {
        title: activityInfo.title,
        system: activityInfo.system,
        component: activityInfo.component,
        periodicity: {
          interval,
          type,
          weekend,
          date,
        },
        initialDate: date,
        responsible: {
          id: responsible?.id,
          name: responsible?.name,
        },
        activity: activityInfo.activity,
        data_font: activityInfo.data_font,
        competence: activityInfo.competence,
      },
    }

    try {
      createActivityTasks(dataActivityMain)

      setIsCreate(true)
      setActivityId([])
    } catch (err) {}
  }

  return (
    <Card
      bordered={false}
      title="Adicionar Nova Atividade"
      extra={
        <Space>
          {current === 1 ? (
            <>
              <ActivityFilterModal />
              <Link to="/atividades/novo" target="_blank">
                <Button type="primary" icon={<PlusOutlined />}>
                  {translate('MaintenanceSystemTab.NewActivity')}
                </Button>
              </Link>
            </>
          ) : (
            ''
          )}

          {!isCreate ? (
            <Button icon={<CloseOutlined />} onClick={() => setIsCreate(true)}>
              Cancelar
            </Button>
          ) : (
            ''
          )}

          {current === 2 ? (
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={AddActivity}
            >
              Salvar
            </Button>
          ) : (
            ''
          )}
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
        marginTop: 15,
      }}
    >
      <Steps current={current}>
        {steps.map((item, index) => (
          <Steps.Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form
        layout="vertical"
        style={{ marginTop: 24 }}
        id="createActivityMaintenance"
      >
        {steps[current].content}
      </Form>
      <Space key="FooterProps">
        <Space>
          {current > 0 && <Button onClick={() => prev()}>Voltar</Button>}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={
                (current === 0 && !date) ||
                (current === 0 && !taskTypeData) ||
                (current === 1 && activityId.length === 0)
              }
            >
              Próximo
            </Button>
          )}
        </Space>

        <Space>
          {/* {current === 1 ? (
                <>
                  <ActivityFilterModal />
                  <Link to="/atividades/novo" target="_blank">
                    <Button type="primary" icon={<PlusOutlined />}>
                      {translate('MaintenanceSystemTab.NewActivity')}
                    </Button>
                  </Link>
                </>
              ) : (
                ''
              )}

              {activityId.length !== 0 && current === 2 ? (
                <Button
                  type="primary"
                  onClick={AddActivity}
                  disabled={!type || !interval}
                >
                  {translate('MaintenanceSystemTab.AddActivity')}
                </Button>
              ) : (
                ''
              )} */}
        </Space>
      </Space>
    </Card>
  )
}

export default CreateActivityMaintenance
