/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React, { useState } from 'react'

import {
  Popconfirm,
  Space,
  Table,
  Button,
  Tooltip,
  Collapse,
  message,
  Typography,
} from 'antd'
import {
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import AboutTypeTasks from '../About'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface MandatoryProps {
  is_active: boolean
  name: string
  type: string
  value: boolean
}

interface TaskTypeProps {
  description: string
  is_enabled: boolean
  runtime: string
  tolerance_time: string
  _id: string
  createdAt: string
  title: string
  amount_to_pay: number
  amount_to_receive: number
  mandatory: MandatoryProps[]
}

const TypeTasksTable: React.FC = () => {
  const {
    isLoading,
    taskType,
    deleteTaskType,
    deleteTaskTypes,
    setNumberPage,
    setPageSize,
    tasksTypeTotal,
  } = useTaskType()

  const { Panel } = Collapse

  const [isDelete, setIsDelete] = useState(false)
  const [taskTypesId, setTaskTypesId] = useState<React.Key[]>([])

  const typeTasksFormatted = taskType?.map((task: TaskTypeProps) => {
    const dataFormat = {
      ...task,
      runtimeFormat: task?.runtime?.substring(0, 5),
      toleranceFormat: task?.tolerance_time?.substring(0, 5),
    }

    return dataFormat
  })
  const columns: ColumnsType<TaskTypeProps> = [
    {
      title: translate('TasksRegistrationTypeTasksTable.taskTypeColumn'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.toleranceColumn'),
      key: 'tolerance',
      dataIndex: 'toleranceFormat',
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.runtimeColumn'),
      key: 'runtime',
      dataIndex: 'runtimeFormat',
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.descriptionColumn'),
      key: 'description',
      dataIndex: 'description',
      render: (description: string, record) => (
        <>
          <Collapse bordered={false}>
            <Panel
              header="Ver descrição"
              key={record._id}
              style={{ padding: -10, border: 'none', borderRadius: 5 }}
            >
              {description || (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </Panel>
          </Collapse>
        </>
      ),
    },
    {
      title: translate('TasksRegistrationTypeTasksTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <AboutTypeTasks data={record} />
          <Popconfirm
            title={translate(
              'TasksRegistrationTypeTasksTable.deletePopconfirm',
            )}
            onConfirm={() => deleteTaskType(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationTypeTasksTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setIsDelete(selectedRowKeys.length > 0)
      setTaskTypesId(selectedRowKeys)
    },
    getCheckboxProps: (record: TaskTypeProps) => ({
      disabled: record?.title === 'Disabled User', // Column configuration not to be checked
      name: record?.title,
    }),
  }

  function DeleteTaskTypes() {
    const taskTypes = taskTypesId.map((m) => {
      return deleteTaskTypes(m.toString())
    })

    message.success('Tipos de tarefa deletados com sucesso!')
    setIsDelete(false)
    return taskTypes
  }

  return (
    <>
      {isDelete && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Popconfirm
            title="Deseja excluir os tipos de tarefa?"
            onConfirm={DeleteTaskTypes}
          >
            <Tooltip
              title={translate('TasksRegistrationTypeTasksTable.deleteTooltip')}
            >
              <Button
                ghost
                danger
                size="middle"
                shape="circle"
                icon={<DeleteFilled />}
                style={{ marginBottom: 10 }}
              />
            </Tooltip>
          </Popconfirm>

          {taskTypesId.length !== 1 ? (
            <Typography.Title level={5} type="danger">
              {taskTypesId.length} tipos de tarefa selecionados
            </Typography.Title>
          ) : (
            <Typography.Title level={5} type="danger">
              1 tipo de tarefa selecionado
            </Typography.Title>
          )}
        </div>
      )}
      <Table
        rowKey={(record) => record._id}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        size="small"
        columns={columns}
        dataSource={typeTasksFormatted}
        scroll={{ x: 1000 }}
        pagination={{
          total: tasksTypeTotal,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          defaultPageSize: 10,
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          onChange: (page, pageSize) => {
            setPageSize(pageSize!)
            setNumberPage(page)
          },
        }}
      />
    </>
  )
}

export default TypeTasksTable
