import React, { useState } from 'react'

import { Tooltip, Button, Modal, Form, Row, Col, Select } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useUsers } from '~/hooks/Users/useUsers'
import { translate } from '~/utils/locale'
import { useTeams } from '~/hooks/Teams/useListTeams'

interface UsersProps {
  id: string
  active: boolean
  created_at: Date
  role: string
  rolePermission: string
  email: string
  updated_at: Date
  initial_time: string
  username: string
}

interface Users {
  dataUsers: UsersProps
}

const UpdateUserPermission: React.FC<Users> = ({ dataUsers }) => {
  const { updateUsers, refetch } = useUsers()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { teams } = useTeams()
  const [role, setRole] = useState(dataUsers.role)

  const initialData = {
    email: dataUsers.email,
    username: dataUsers.username,
  }

  async function updateUser(valueForm: object) {
    try {
      await updateUsers(valueForm)

      refetch()

      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const selectOptions = [
    { value: 'financial', label: 'Financeiro' },
    { value: 'allowed', label: 'Permitido' },
    { value: 'denied', label: 'Negado' },
  ]

  return (
    <>
      <Tooltip title={translate('UsersUpdate.editTooltip')}>
        <Button
          type="primary"
          ghost
          shape="circle"
          icon={<EditOutlined />}
          onClick={showModal}
        />
      </Tooltip>

      <Modal
        title={translate('UsersUpdate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
        footer={[
          <Button key="close" onClick={closeModal}>
            {translate('UsersUpdate.closeButton')}
          </Button>,
          <Button form="editUsers" key="save" type="primary" htmlType="submit">
            {translate('UsersUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          id="editUsers"
          initialValues={initialData}
          onFinish={updateUser}
        >
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item label="Tipo de usuário" name="role">
                <Select
                  options={selectOptions}
                  placeholder="Selecione o tipo de permissão"
                  onChange={(value) => {
                    setRole(value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {role === 'denied' && (
                <Form.Item
                  label={translate('TasksFilter.teamLabel')}
                  name="rolePermission"
                >
                  <Select
                    placeholder="Equipe Permitida"
                    optionFilterProp="children"
                    showSearch
                  >
                    {teams?.map((team) => (
                      <Select.Option value={team.id} key={team.id}>
                        {team.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateUserPermission
