import {
  Button,
  Card,
  Col,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import React, { useState } from 'react'
import { FiCalendar, FiFile, FiTarget, FiUsers } from 'react-icons/fi'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'
import { DeleteFilled, ProfileOutlined, RobotOutlined } from '@ant-design/icons'

import { Container } from './styles'

import { format } from 'date-fns'
import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'

interface ActivityTasksProps {
  taskId: string
  created: Date
  status?: string
  plan: string
}

interface ActivityProps {
  _id: string
  title: string
  company_id: string
  activity_id: string
  maintenance_id: string
  task_type_id: string
  is_automatic: boolean
  system: string
  component: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  initialDate: string
  responsible: {
    id: string
    name: string
  }
  activity: string
  data_font: string
  competence: string
  tasks: ActivityTasksProps[]
}

interface Activity {
  activity: ActivityProps
  typeOfDisplay: 'text' | 'button'
}

const ViewActivity: React.FC<Activity> = ({ activity, typeOfDisplay }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { deleteActivityTask } = useActivityTasks()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {typeOfDisplay === 'text' ? (
        <Space>
          <Typography.Text
            onClick={showModal}
            style={{
              color: '#1677ff',
              cursor: 'pointer',
              textDecoration: 'none',
              outline: 'none',
              transition: 'color 0.3s',
            }}
          >
            {activity?.title}
          </Typography.Text>
          {activity?.is_automatic && (
            <Tooltip
              title={`${translate('MaintenanceTabsAcitivtyView.iconTooltip')}`}
            >
              <RobotOutlined style={{ color: '#1677ff' }} />
            </Tooltip>
          )}
        </Space>
      ) : (
        <Button
          ghost
          type="primary"
          shape="circle"
          icon={<ProfileOutlined />}
          onClick={showModal}
        />
      )}
      <Modal
        title={`${translate('MaintenanceSystemTab.ViewActivity')} - ${
          activity.title
        }`}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        footer={[
          <Space key="space">
            <Popconfirm
              title={translate('MaintenanceSystemTab.ConfirmDeleteActivity')}
              onConfirm={() => deleteActivityTask(activity._id)}
            >
              <Tooltip
                title={translate('TasksRegistrationPauseTable.deleteTooltip')}
              >
                <Button ghost danger shape="circle" icon={<DeleteFilled />} />
              </Tooltip>
            </Popconfirm>
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>
          </Space>,
        ]}
      >
        <Tabs>
          <Tabs.TabPane
            key="details"
            tab={translate('MaintenanceSystemTab.Details')}
          >
            <Container>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiFile />
                      <Text strong>
                        {translate('MaintenanceSystemTab.ActivityTitle')}
                      </Text>
                    </div>
                    {activity.title ? (
                      <Text style={{ fontSize: 16 }}>{activity.title}</Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiTarget />
                      <Text strong>
                        {translate('MaintenanceSystemTab.DataSource')}
                      </Text>
                    </div>
                    {activity.data_font ? (
                      <Text style={{ fontSize: 16 }}>{activity.data_font}</Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        Nenhum documento informado.
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiUsers />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Competence')}
                      </Text>
                    </div>
                    {activity.competence ? (
                      <Text style={{ fontSize: 16 }}>
                        {activity.competence}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiUsers />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Responsibles')}
                      </Text>
                    </div>
                    {activity?.responsible?.name ? (
                      <Text style={{ fontSize: 16 }}>
                        {activity?.responsible?.name}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiCalendar />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Periodicity')}
                      </Text>
                    </div>
                    {activity.periodicity ? (
                      <>
                        {activity.periodicity?.type === 'day' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {activity.periodicity.interval === 1
                              ? translate('MaintenanceSystemTab.Day')
                              : translate('MaintenanceSystemTab.Days')}{' '}
                            {activity.periodicity.weekend === 'days'
                              ? 'Somente durante a semana'
                              : ''}
                            {activity.periodicity.weekend === 'weekend'
                              ? 'e aos finais de semana'
                              : ''}
                            {activity.periodicity.weekend === 'saturday'
                              ? 'e aos sabádos'
                              : ''}
                            {activity.periodicity.weekend === 'sunday'
                              ? 'e aos domingos'
                              : ''}
                          </Text>
                        ) : (
                          ''
                        )}

                        {activity.periodicity?.type === 'sem' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Weeks')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {activity.periodicity?.type === 'men' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.MonthsPlural')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {activity.periodicity?.type === 'anu' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {activity.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Years')}
                          </Text>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <Text>{translate('TasksTabsDetails.noToText')}</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiCalendar />
                      <Text strong>
                        {translate('MaintenanceActivity.InitialDate')}
                      </Text>
                    </div>

                    {activity.initialDate
                      ? format(new Date(activity?.initialDate), 'dd/MM/yyyy')
                      : 'Nenhuma data informada'}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className="text">
                    <div>
                      <FiFile />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Activities')}
                      </Text>
                    </div>
                    {activity.activity ? (
                      <Text style={{ fontSize: 16 }}>{activity.activity}</Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        Nenhuma descrição informada.
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Tabs.TabPane>

          <Tabs.TabPane key="components" tab="Componentes e Atividades">
            <Card
              style={{ marginTop: 16 }}
              title={`Atividade do componente - ${activity.component}`}
            >
              {activity.activity}
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  )
}

export default ViewActivity
