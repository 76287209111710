/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-promise-reject-errors */
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tour,
} from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { LatLngExpression } from 'leaflet'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import cep from 'cep-promise'
import geolocationApi from '~/services/geolocationApi'

import { translate } from '~/utils/locale'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'

import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'
import { useTaskAddressFieldsCreate } from '~/hooks/Tasks/Create/Fields/useFieldsTaskAddress'
import AddressClientCreateTask from './Client'
import axios from 'axios'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'

interface MapUpdateFunctionProps {
  center: LatLngExpression
  zoom: number
}

interface Teste {
  form: any
}

const AddressCreateTask: React.FC<Teste> = ({ form }) => {
  const {
    setNumber,
    zipcode,
    setZipcode,
    city,
    setCity,
    state,
    setState,
    street,
    setStreet,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
    longitude,
    setLongitude,
    latitude,
    setLatitude,
    type,
    setType,
  } = useTaskAddressFieldsCreate()

  // console.log(zipcode)

  const {
    createCEPTaskRef,
    createNumberTaskRef,
    openAddressTask,
    setOpenAddressTask,
    createAddressSteps,
  } = useTaskTour()

  const [mapValidate, setMapValidate] = useState(false)
  const [status, setStatus] = useState('' as ValidateStatus)
  const [zoomLevel, setZoomLevel] = useState(3)

  const [hidden, setHidden] = useState(true)

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenAddressTask(false)
  }

  const formatComma = (value: string, field: 'latitude' | 'longitude') => {
    const valueFormatted = value.replace(/,/g, '.')

    if (
      valueFormatted !== '-' &&
      valueFormatted !== '.' &&
      valueFormatted !== '-.'
    ) {
      if (field === 'latitude') {
        setLatitude(valueFormatted)
      } else if (field === 'longitude') {
        setLongitude(valueFormatted)
      }
    }
  }

  const handleLinkChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value

    try {
      const urlObj = new URL(url)

      if (urlObj.pathname.includes('/@')) {
        const pathParts = urlObj.pathname.split('/')
        const locationPart = pathParts.find((part) => part.startsWith('@'))

        if (locationPart) {
          const [lat, lng] = locationPart
            .slice(1)
            .split(',')
            .map((coord) => parseFloat(coord))
          if (!isNaN(lat) && !isNaN(lng)) {
            setLatitude(lat)
            setLongitude(lng)
            setZoomLevel(13)

            console.log(lat, lng)

            const urlApi = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`

            try {
              const response = await axios.get(urlApi)
              const address = response.data.address

              setCity(address.city ? address.city : address.municipality)
              setState(address.state)
              setNeighborhood(address.suburb)
              setStreet(address.road)
              setZipcode(address.postcode ? address.postcode : '00000000')

              form.setFieldsValue({
                city: address.city ? address.city : address.municipality,
                state: address.state,
                neighborhood: address.suburb,
                street: address.road,
                zip_code: address.postcode ? address.postcode : '00000000',
              })
            } catch (error) {
              console.error('Error fetching geocode data', error)
            }
          }
        }
      }
    } catch (error) {
      console.error('Link inválido:', error)
    }
  }

  // const addressClient = clients?.find((client) => client.id === clientData?.id)

  // function handleAddress(value: any) {
  //   setAddressInfo(
  //     addressClient?.contact.contactAddresses.find((item) => item.id === value),
  //   )
  //   setMapValidate(true)

  //   return addressClient?.contact.contactAddresses.find(
  //     (item) => item.id === value,
  //   )
  // }

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Distrito Federal', value: 'DF' },
  ]

  const searchCEP = useCallback(
    async (address: string) => {
      try {
        setStatus('validating')
        const cepInfo = await cep(address)

        form.setFieldsValue({
          state: cepInfo.state,
          city: cepInfo.city,
          neighborhood: cepInfo.neighborhood,
          street: cepInfo.street,
        })

        setState(cepInfo.state)
        setCity(cepInfo.city)
        setStreet(cepInfo.street)
        setNeighborhood(cepInfo.neighborhood)

        setStatus('success')
      } catch (error) {
        message.error(translate('TasksCreateAddress.zipcodeSearchErrorMessage'))
        form.resetFields()
        setState('')
        setCity('')
        setStreet('')
        setStatus('error')
      }
    },
    [form],
  )

  useEffect(() => {
    const loadMap = async () => {
      if (status === 'success') {
        try {
          const params = {
            street,
            city,
            state,
            format: 'json',
          }

          const response = await geolocationApi.get('search', { params })

          if (response.data.length > 0) {
            setHidden(true)
            form.setFieldsValue({
              latitude: response.data[0].lat,
              longitude: response.data[0].lon,
            })

            setLatitude(response.data[0].lat)
            setLongitude(response.data[0].lon)

            setZoomLevel(13)
          } else {
            setHidden(false)

            form.setFieldsValue({
              latitude: '',
              longitude: '',
            })

            setZoomLevel(3)

            message.warning(
              translate('TasksCreateAddress.geolocationWarningMessage'),
              10,
            )
          }

          setMapValidate(true)
        } catch (error) {
          message.error(translate('TasksCreateAddress.geolocationErrorMessage'))
          setMapValidate(true)
        }
      }
    }

    loadMap()
  }, [city, state, street, form, status])

  function ChangeView({ center, zoom }: MapUpdateFunctionProps) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  function HandleTypeAddress(value: string) {
    setType(value)
  }

  // function SaveAddressTaskOnClient() {
  //   const address = {
  //     zip_code: form.getFieldValue('zip_code').replace(/[^0-9]/g, ''),
  //     street: form.getFieldValue('street'),
  //     number: form.getFieldValue('number'),
  //     complement: form.getFieldValue('complement'),
  //     neighborhood: form.getFieldValue('neighborhood'),
  //     city: form.getFieldValue('city'),
  //     state: form.getFieldValue('state'),
  //     location: `(${form.getFieldValue('latitude')}, ${form.getFieldValue(
  //       'longitude',
  //     )})`,
  //   }

  //   createAddressTaskOnClient(address, clientData?.contactId)
  // }

  return (
    <>
      <Row>
        <Col span={8}>
          <Form.Item label="Adicionar endereço">
            <Select defaultValue="manual" onChange={HandleTypeAddress}>
              <Select.Option value="manual">Manualmente</Select.Option>
              <Select.Option value="client">Endereço do Cliente</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {type === 'manual' ? (
        <Fragment>
          <Row gutter={14}>
            <Col span={4} ref={createCEPTaskRef}>
              <Form.Item
                label={translate('TasksCreateAddress.zipcodeLabel')}
                name="zip_code"
                hasFeedback
                validateStatus={status}
                rules={[
                  {
                    required: true,
                    message: 'Informe o cep.',
                  },
                ]}
              >
                <Input
                  placeholder="00000-000"
                  onChange={(e) => {
                    const cepInput = e.target.value.replace(/\D/g, '')
                    if (cepInput.length === 8) {
                      searchCEP(e.target.value)
                      setZipcode(e.target.value)
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Form.Item
                  label={translate('TasksCreateAddress.zipcodeButtonLabel')}
                >
                  <Button key="submit" type="primary">
                    {translate('TasksCreateAddress.zipcodeButton')}
                  </Button>
                </Form.Item>
              </a>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksCreateAddress.stateLabel')}
                name="state"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder={translate('TasksCreateAddress.statePlaceholder')}
                  onChange={(e) => setState(String(e))}
                  className="menu_input"
                  options={stateOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksCreateAddress.cityLabel')}
                name="city"
              >
                <Input
                  placeholder={translate('TasksCreateAddress.cityPlaceholder')}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={8}>
              <Form.Item
                label={translate('TasksCreateAddress.neighborhoodLabel')}
                name="neighborhood"
              >
                <Input
                  placeholder={translate(
                    'TasksCreateAddress.neighborhoodPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('TasksCreateAddress.streetLabel')}
                name="street"
              >
                <Input
                  placeholder={translate(
                    'TasksCreateAddress.streetPlaceholder',
                  )}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8} ref={createNumberTaskRef}>
              <Form.Item
                label={translate('TasksCreateAddress.numberLabel')}
                name="number"
                rules={[
                  {
                    required: true,
                    message: translate('TasksCreateAddress.numberRule'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder={translate(
                    'TasksCreateAddress.numberPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={8}>
              <Form.Item
                label={translate('TasksCreateAddress.complementLabel')}
                name="complement"
              >
                <Input
                  placeholder={translate(
                    'TasksCreateAddress.complementPlaceholder',
                  )}
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <Space>
                    <p>Link Google Maps</p>
                    <HelpTooltip title="Se você não souber o CEP, pode encontrá-lo facilmente no Google Maps. Basta buscar pela localização e inserir a url neste campo." />
                  </Space>
                }
              >
                <Input
                  onBlur={handleLinkChange}
                  placeholder="Adicione o link do google maps"
                />
              </Form.Item>
            </Col>

            <Col span={4.8}>
              <Form.Item
                hidden={hidden}
                label={translate('TasksCreateAddress.latitudeLabel')}
                name="latitude"
                initialValue={latitude}
                rules={[
                  {
                    required: !hidden,
                    message: translate('TasksCreateAddress.latitudeRule'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => formatComma(e.target.value, 'latitude')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <Form.Item
                hidden={hidden}
                label={translate('TasksCreateAddress.longitudeLabel')}
                name="longitude"
                initialValue={longitude}
                rules={[
                  {
                    required: !hidden,
                    message: translate('TasksCreateAddress.longitudeRule'),
                  },
                ]}
              >
                <Input
                  onChange={(e) => formatComma(e.target.value, 'longitude')}
                />
              </Form.Item>
            </Col>
            <Col span={4.8}>
              <a
                href="https://www.latlong.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Form.Item
                  label={translate('TasksCreateAddress.coordinateLabel')}
                  hidden={hidden}
                >
                  <Button type="primary">
                    {translate('TasksCreateAddress.coordinateButton')}
                  </Button>
                </Form.Item>
              </a>
            </Col>
          </Row>

          <MapContainer
            center={[latitude, longitude]}
            zoom={zoomLevel}
            scrollWheelZoom={false}
            style={{ height: '350px', width: '100%' }}
          >
            <ChangeView center={[latitude, longitude]} zoom={zoomLevel} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {latitude !== -14.235004 && longitude !== -51.925282 && (
              <Marker position={[latitude, longitude]} />
            )}
          </MapContainer>
        </Fragment>
      ) : (
        <AddressClientCreateTask form={form} />
      )}

      <Tour
        open={openAddressTask}
        onClose={onClose}
        steps={createAddressSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default AddressCreateTask
