import { Card, Tabs, Typography } from 'antd'
import React from 'react'

import Initial from './Tasks/Initial'
import TasksMapDashboard from './Tasks/Maps/Tasks'
import ContributorsDashboardMap from './Tasks/Maps/Contributors'

import { translate } from '~/utils/locale'
import DashboardTasksFilter from './Tasks/Filter'

// import { Container } from './styles';

const { TabPane } = Tabs

const DashboardTabs: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<'34' | '35' | '36'>('34')

  const tabContent: Record<'34' | '35' | '36', React.ReactNode> = {
    '34': <Initial />,
    '35': <TasksMapDashboard />,
    '36': <ContributorsDashboardMap />,
  }

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography.Title level={3}>
            {translate('DashboardMain.dashboardTitle')}
          </Typography.Title>
          <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key as '34' | '35' | '36')}
            size="small"
            style={{ marginRight: '2rem' }}
          >
            <TabPane tab="Dashboard" key="34" />
            <TabPane tab="Mapa de Tarefas" key="35" />
            <TabPane tab="Mapa de Colaboradores" key="36" />
          </Tabs>
        </div>
      }
      bordered={false}
      style={{
        width: '100%',
        height: '100%',
        padding: 15,
        // backgroundColor: 'transparent',
      }}
      extra={
        activeTab === '35' || activeTab === '36' ? '' : <DashboardTasksFilter />
      }
    >
      {tabContent[activeTab]}
    </Card>
  )
}

export default DashboardTabs
