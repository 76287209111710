/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { translate } from '~/utils/locale'

import { useActivityTasks } from '~/hooks/Maintenance/ActivityTasks/useActivityTasks'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Container } from '../../Details/styles'

interface ActivityPlanProps {
  title: string
  system: string
  component: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  initialDate: string
  responsible: {
    id: string
    name: string
  }
  activity: string
  data_font: string
  competence: string
  _id: string
  activity_id: string
  maintenance_id: string
  task_type_id: string
  is_automatic: boolean
}

interface ActivityTasks {
  dataActivity: ActivityPlanProps
  setModalTask: (task: boolean) => void
}

const UpdateActivityTasksPlan: React.FC<ActivityTasks> = ({
  dataActivity,
  setModalTask,
}) => {
  const { updateActivityTasks } = useActivityTasks()

  const { data } = useContributorsTask()
  const { taskType } = useTaskType()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const [date, setDate] = useState<Dayjs | null>(
    dayjs(dataActivity.initialDate),
  )
  const [responsible, setResponsible] = useState<{
    id: string
    name: string
  } | null>(dataActivity.responsible)

  const [taskTypeData, setTaskTypeData] = useState<string | undefined>(
    dataActivity?.task_type_id,
  )

  const [type, setType] = useState(dataActivity?.periodicity.type)
  const [weekend, setWeekend] = useState(dataActivity.periodicity.weekend)
  const [interval, setInterval] = useState<number | undefined>(
    dataActivity?.periodicity.interval,
  )

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    const regex = /^[0-9]*$/

    if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  const options = [
    { label: 'Todos os dias', value: 'everyday' },
    { label: 'Somente durante a semana', value: 'days' },
    { label: 'Sábado e Domingo', value: 'weekend' },
    { label: 'Somente Sábado', value: 'saturday' },
    { label: 'Somente Domingo', value: 'sunday' },
  ]

  const initialValues = {
    date: dayjs(dataActivity.initialDate),
    responsible: dataActivity.responsible?.name,
    competence: dataActivity.competence,
    title: dataActivity.title,
    activity: dataActivity.activity,
    system: dataActivity.system,
    component: dataActivity.component,
    interval: dataActivity.periodicity.interval,
    type: dataActivity.periodicity.type,
    task_type_id: dataActivity.task_type_id,
    weekend: dataActivity.periodicity.weekend,
    created: dataActivity?.is_automatic ? 'automatic' : 'manual',
  }

  function UpdateActivity(valueForm: any) {
    setModalTask(false)

    const dataActivityMain = {
      activity_id: dataActivity.activity_id,
      task_type_id: taskTypeData,
      maintenance_id: dataActivity.maintenance_id,
      is_automatic: valueForm === 'automatic',
      informations: {
        title: valueForm.title,
        system: valueForm.system,
        component: valueForm.component,
        periodicity: {
          interval,
          type,
          weekend,
          date,
        },
        initialDate: date,
        responsible: {
          id: responsible?.id,
          name: responsible?.name,
        },
        activity: valueForm.activity,
        data_font: valueForm.data_font,
        competence: valueForm.competence,
      },
    }

    try {
      setModalTask(false)
      updateActivityTasks(dataActivityMain, dataActivity._id)

      setIsModalVisible(false)
    } catch (err) {}
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title="Editar atividade">
        <Button onClick={showModal} icon={<EditOutlined />} type="primary">
          Editar
        </Button>
      </Tooltip>
      <Modal
        key={dataActivity?._id}
        title="Editar Atividade"
        width="850px"
        style={{
          top: 50,
        }}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Space size="middle" key="footer">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              form="updateActivityTasksMaintenance"
            >
              Salvar
            </Button>
          </Space>,
        ]}
      >
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={UpdateActivity}
          id="updateActivityTasksMaintenance"
        >
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item label="Data de início da atividade" name="date">
                <DatePicker
                  style={{ width: '100%' }}
                  value={date}
                  format="DD/MM/YYYY"
                  onChange={(e: Dayjs | null) => setDate(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Executor/Fornecedor" name="responsible">
                <Select
                  placeholder="Executor/Fornecedor da Atividade"
                  onChange={(value) => {
                    const selectedItem = data?.find((c) => c.id === value)
                    if (selectedItem) {
                      setResponsible({
                        id: selectedItem.id,
                        name: selectedItem.contact.name,
                      })
                    } else {
                      setResponsible(null)
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  {data?.map((c) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.contact.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Equipe/Competência" name="competence">
                <Input placeholder="Informe a equipe/competência" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Tarefas criadas" name="created">
                <Select defaultValue="automatic" style={{ width: '100%' }}>
                  <Select.Option value="automatic">
                    Automaticamente
                  </Select.Option>
                  <Select.Option value="manual">Manualmente</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Tipo de tarefa" name="task_type_id">
                <Select
                  style={{ width: '100%' }}
                  placeholder="Selecione o tipo de tarefa"
                  onChange={(value) => setTaskTypeData(value)}
                  optionFilterProp="children"
                  showSearch
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 8,
                        }}
                      >
                        <Link
                          to="/tiposDeTarefas/novo"
                          target="_blank"
                          style={{ width: '100%' }}
                        >
                          <Button type="primary" style={{ width: '100%' }}>
                            Criar tipo de tarefa
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                >
                  {taskType?.map((t) => (
                    <Select.Option value={t._id} key={t._id}>
                      {t.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Atividade" name="title">
                <Input placeholder="Informe o nome da atividade" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Descrição" name="activity">
                <Input placeholder="Informe a descrição da atividade" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Sistema" name="system">
                <Input placeholder="Informe o sistema" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Componente" name="component">
                <Input placeholder="Informe o componente" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Type')}
                    <HelpTooltip title="Refere-se ao intervalo de repetição do evento ou ação." />
                  </Space>
                }
                name="type"
              >
                <Select
                  onChange={(e) => setType(e)}
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.TypePlaceholder')}
                >
                  <Select.Option value="day">
                    {translate('MaintenanceCreate.Daily')}
                  </Select.Option>
                  <Select.Option value="sem">
                    {translate('MaintenanceCreate.Weekly')}
                  </Select.Option>
                  <Select.Option value="men">
                    {translate('MaintenanceCreate.Monthly')}
                  </Select.Option>
                  <Select.Option value="anu">
                    {translate('MaintenanceCreate.Yearly')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Interval')}
                    <HelpTooltip title="Representa o número de ciclos de frequência antes de cada repetição" />
                  </Space>
                }
                name="interval"
              >
                <InputNumber
                  onChange={(e: any) => setInterval(e)}
                  onKeyPress={handleKeyPress}
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.EnterInterval')}
                />
              </Form.Item>
            </Col>
          </Row>

          {type === 'day' && interval === 1 ? (
            <Row>
              <Form.Item label="Atividades aos Finais de Semana" name="weekend">
                <Radio.Group
                  optionType="button"
                  options={options}
                  onChange={(value) => setWeekend(value.target.value)}
                />
              </Form.Item>
            </Row>
          ) : (
            ''
          )}

          {type && interval && (
            <Container>
              {type === 'day' ? (
                <>
                  {weekend === 'everyday' ? (
                    <Tag className="tag" color="geekblue">
                      Atividade Diária - A cada {interval} dia em todos os dias
                      da semana <br />
                    </Tag>
                  ) : (
                    ''
                  )}

                  {weekend === 'days' ? (
                    <Tag className="tag" color="geekblue">
                      Atividade Diária - A cada {interval} dia durante semana{' '}
                      <br />
                    </Tag>
                  ) : (
                    ''
                  )}

                  {interval === 1 ? (
                    <>
                      {weekend === 'weekend' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos finais
                          de semana <br />
                        </Tag>
                      ) : (
                        ''
                      )}

                      {weekend === 'saturday' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos sábados
                          <br />
                        </Tag>
                      ) : (
                        ''
                      )}

                      {weekend === 'sunday' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos
                          domingos
                          <br />
                        </Tag>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <Tag className="tag" color="geekblue">
                      Atividade Diária - A cada {interval} dias <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'sem' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="purple">
                      Atividade Semanal - A cada uma semana <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="purple">
                      Atividade Semanal - A cada {interval} semanas <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'men' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="green">
                      Atividade Mensal - A cada um mês <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="green">
                      Atividade Mensal - A cada {interval} meses <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'anu' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="magenta">
                      Atividade Anual - A cada um ano <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="magenta">
                      Atividade Anual - A cada {interval} anos <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}
            </Container>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default UpdateActivityTasksPlan
