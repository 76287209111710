/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns'

import React, { Fragment } from 'react'
import { useTaskDashboard } from '~/hooks/Dashboard/useTaskDashboard'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'

import L from 'leaflet'

import { Container, Content, Loading, PopupHeader, Title } from './styles'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Collapse, Divider, Tag, Tooltip, Typography } from 'antd'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { LoadingOutlined } from '@ant-design/icons'
import { useTeams } from '~/hooks/Teams/useListTeams'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'
import { useDashboard } from '~/hooks/Dashboard/useDashboard'
import moment from 'moment'
import TaskDashboardFilter from '../Filters'

const TasksMapDashboard: React.FC = () => {
  const { tasks } = useTaskDashboard()
  const { teams } = useTeams()
  const { clients } = useListClients()
  const { taskType } = useTaskType()
  const { contribuitors } = useDashboard()

  const dateTask = (date: any) =>
    format(
      new Date(date),
      `dd/MM/yyyy '${translate(
        'TasksOptionsKanbanCards.dateString',
      )}' HH:mm'h'`,
    )

  const colorPin = '#64C77C'

  const filter = JSON.parse(
    localStorage.getItem('@Gstor:tasksDashboardFilterOptions')!,
  )

  const fromDateTask = filter
    ? format(new Date(filter.fromDate), `dd/MM/yyyy`)
    : moment().startOf('day').format(`DD/MM/yyyy`)

  const toDateTask = filter
    ? format(new Date(filter.toDate), `dd/MM/yyyy`)
    : moment().startOf('day').format(`DD/MM/yyyy`)

  const iconAgent = L.divIcon({
    className: '',
    html: `<svg id="Layer_1" data-name="Layer 1" xmlns="httpwww.w3.org/2000/svg" viewBox="0 0 128 128"><defs><style>.cls-1{fill:${colorPin};}.cls-2{fill:${colorPin};}</style></defs><title>b</title><path class="cls-1" d="M64.00178,3.36652c-25.74943,0-43.04956,14.75866-43.04956,36.7246,0,29.11223,37.01485,81.60069,37.38345,82.01113a7.60318,7.60318,0,0,0,11.3233.00579c.37394-.41623,37.3888-52.90469,37.3888-82.01692C107.04778,18.12518,89.74853,3.36652,64.00178,3.36652ZM64,74.73868a28.29593,28.29593,0,1,1,28.296-28.296A28.29592,28.29592,0,0,1,64,74.73868Z"/><path class="cls-2" d="M82.84186,58.57151c-.155.24618-.31.4741-.4741.71116a22.39884,22.39884,0,0,1-36.73256.00913c-.17323-.23705-.33733-.48323-.48323-.72935.01825-.12765.0365-.24618.0547-.3647a4.03615,4.03615,0,0,1,2.16079-2.90834c3.76529-1.87811,12.00714-4.6406,12.00714-4.6406v-2.726l-.22793-.17323a7.86155,7.86155,0,0,1-2.99042-5.00525l-.04557-.29175h-.2188a3.02492,3.02492,0,0,1-2.81719-1.88724,3.275,3.275,0,0,1-.41935-1.61368,3.1367,3.1367,0,0,1,.20967-1.12143,1.58361,1.58361,0,0,1,.61083-.9846l.76586-.45585-.18235-.82056c-1.34018-5.86225,3.04512-11.141,9.06235-11.51483a.5194.5194,0,0,1,.11853-.00913c.10027-.00913.20055-.0182.30088-.0182h.9117c.10027,0,.20055.00907.30082.0182a.51916.51916,0,0,1,.11853.00913c6.02641.37383,10.41171,5.65258,9.07147,11.51483l-.19148.82056.76586.45585a1.54549,1.54549,0,0,1,.61083.9846,3.14584,3.14584,0,0,1,.2188,1.12143,3.37534,3.37534,0,0,1-.41935,1.61368,3.02486,3.02486,0,0,1-2.81719,1.88724h-.2188l-.0547.29175a7.81113,7.81113,0,0,1-2.98129,5.00525l-.22793.17323v2.726s8.24185,2.76249,11.99806,4.6406a4.01318,4.01318,0,0,1,2.16074,2.90834C82.80541,58.31626,82.82361,58.44391,82.84186,58.57151Z"/></svg>`,
    iconSize: [40, 50],
    iconAnchor: [20, 15],
  })

  return (
    <>
      <Title>
        <Typography.Title level={4}>
          {translate('DashboardTasksMapsTaks.filterText')} {fromDateTask} -{' '}
          {toDateTask}
        </Typography.Title>

        <TaskDashboardFilter />
      </Title>

      <Container>
        {tasks ? (
          <MapContainer
            center={[-23.1895062, -45.8630127]}
            zoom={6}
            scrollWheelZoom={false}
            style={{
              height: '100vh',
              width: '100%',
              boxShadow: 'rgba(99, 99, 99, 0.5) 0px 2px 8px 0px',
              borderRadius: 5,
            }}
            id="t"
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {tasks?.map(
              (task) =>
                task.address.location.x &&
                task.address.location.y && (
                  <Marker
                    position={[
                      task.address.location.x,
                      task.address.location.y,
                    ]}
                    icon={iconAgent}
                    key={task._id}
                  >
                    <Popup>
                      <PopupHeader>
                        <Tooltip
                          title={translate(
                            'DashboardMap.viewContributorTooltip',
                          )}
                        >
                          <Link to={`/tarefas/visualizar/tarefa/${task._id}`}>
                            {translate('DashboardMap.taskLabel')} -{' '}
                            {task.internal_code}
                          </Link>
                        </Tooltip>
                      </PopupHeader>
                      <Content>
                        <p>
                          {task.responsible?.type === 'teams' ? (
                            <>
                              {teams?.map((c) => (
                                <Fragment key={c?.id}>
                                  {c?.id === task.responsible?.id ? (
                                    <Text>
                                      {translate(
                                        'DashboardMap.responsibleLabel',
                                      )}{' '}
                                      {c?.title}
                                    </Text>
                                  ) : (
                                    ''
                                  )}
                                </Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                              {contribuitors?.map((d) => (
                                <div key={d?.id}>
                                  {d?.id === task.responsible?.id ? (
                                    <Text>
                                      {translate(
                                        'DashboardMap.responsibleLabel',
                                      )}{' '}
                                      {d.contact?.name}
                                    </Text>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              ))}
                            </>
                          )}
                        </p>

                        <p>
                          {clients?.map((c) => (
                            <Fragment key={c.id}>
                              {c.id === task.customer_id ? (
                                <Text>
                                  {translate('DashboardMap.clientLabel')}{' '}
                                  {c.contact.name}
                                </Text>
                              ) : (
                                ''
                              )}
                            </Fragment>
                          ))}
                        </p>

                        <p>
                          {translate('DashboardMap.dateLabel')}{' '}
                          {dateTask(task.date)}
                        </p>

                        <p>
                          {translate('DashboardMap.statusLabel')}
                          {task.current_status === 'received' ? (
                            <Tag color="yellow">
                              {translate('TasksTabsDetails.statusReceived')}
                            </Tag>
                          ) : (
                            ''
                          )}
                          {task.current_status === 'viewed' ? (
                            <Tag color="green">
                              {translate('TasksTabsDetails.statusViewed')}
                            </Tag>
                          ) : (
                            ''
                          )}
                          {task.current_status === 'working' ? (
                            <Tag color="blue">
                              {translate('TasksTabsDetails.statusWorking')}
                            </Tag>
                          ) : (
                            ''
                          )}
                          {task.current_status === 'break' ? (
                            <Tag color="red">
                              {translate('TasksTabsDetails.statusBreak')}
                            </Tag>
                          ) : (
                            ''
                          )}
                          {task.current_status === 'en-route' ? (
                            <Tag color="red">
                              {translate('TasksTabsDetails.statusEnRoute')}
                            </Tag>
                          ) : (
                            ''
                          )}
                          {task.current_status === 'resume-working' ? (
                            <Tag color="green">
                              {translate(
                                'TasksTabsDetails.statusResumeWorking',
                              )}
                            </Tag>
                          ) : (
                            ''
                          )}
                          {task.current_status === 'canceled' ? (
                            <Tag color="gray">
                              {translate('TasksTabsDetails.statusCanceled')}
                            </Tag>
                          ) : (
                            ''
                          )}
                          {task.current_status === 'finished' ? (
                            <Tag color="geekblue">
                              {translate('TasksTabsDetails.statusFinished')}
                            </Tag>
                          ) : (
                            ''
                          )}
                        </p>

                        <p>
                          {translate('DashboardMap.taskTypeLabel')}
                          {taskType?.map((t) => (
                            <div key={t._id}>
                              {t._id === task.task_type_id ? (
                                <Text>{t.title}</Text>
                              ) : (
                                ''
                              )}
                            </div>
                          ))}
                        </p>

                        <Divider />
                        <Collapse ghost>
                          <Collapse.Panel
                            header={translate('DashboardMap.addressTitle')}
                            key="1"
                            style={{ fontSize: 12 }}
                          >
                            <div>
                              <p>
                                {translate('DashboardMap.zipcodeLabel')}
                                &nbsp;
                                <NumberFormat
                                  value={task.address.zip_code}
                                  displayType="text"
                                  format="#####-###"
                                />
                              </p>
                              <p>
                                {translate('DashboardMap.streetLabel')}
                                &nbsp;
                                {task.address.street}
                                ,&nbsp;
                                {task.address.number}
                                &nbsp; -&nbsp;
                                {task.address.neighborhood}
                              </p>
                              <p>
                                {translate('DashboardMap.cityLabel')}
                                &nbsp;
                                {task.address.city}
                                ,&nbsp;
                                {task.address.state}
                              </p>
                            </div>
                          </Collapse.Panel>
                        </Collapse>
                      </Content>
                    </Popup>
                  </Marker>
                ),
            )}
          </MapContainer>
        ) : (
          <Loading>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </Loading>
        )}
      </Container>
    </>
  )
}

export default TasksMapDashboard
