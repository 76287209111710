/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import Text from 'antd/lib/typography/Text'

import {
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Button,
  Tag,
  Typography,
} from 'antd'
import {
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import { translate } from '~/utils/locale'
import { useListTickets } from '~/hooks/HelpDesk/useListTickets'

interface TicketsProps {
  _id: string
  title: string
  request_type_id: string
  customer: string
  customer_phone: string
  customer_email: string
  priority: string
  date_format: string
  description: string
  status: string
  responsible: {
    id: string
    type: string
  }
  date: string
}

const HelpDeskTable: React.FC = () => {
  const { tickets, isLoading, deleteTicket } = useListTickets()

  const ticketsFormat = tickets?.map((ticket: any) => {
    const dataFormat = {
      ...ticket,
      date_format: format(
        new Date(ticket.date),
        `dd/MM/yyyy '${translate('UsersTable.dateString')}' HH:mm'h'`,
      ),
    }

    return dataFormat
  })

  const columns: ColumnsType<TicketsProps> = [
    {
      title: 'ID Ticket',
      dataIndex: '_id',
      key: '_id',
      render: (_id: string) => (
        <Typography.Link copyable={{ text: _id }} href={`/ticket/${_id}`}>
          {_id.substring(0, 8)}
        </Typography.Link>
      ),
    },
    {
      title: translate('HelpDeskTable.ticketColumn'),
      align: 'left',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('HelpDeskTable.solicitationColumn'),
      key: 'ticketType',
      dataIndex: ['ticketType', 'title'],
      render: (ticketType: string) => (
        <>
          {ticketType || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('HelpDeskTable.clientColumn'),
      key: 'customer',
      dataIndex: 'customer',
    },
    {
      title: translate('HelpDeskTable.priorityColumn'),
      key: 'priority',
      render: (record) => (
        <>
          {record.priority === 'low' ? <Tag color="green">Baixa</Tag> : ''}
          {record.priority === 'medium' ? <Tag color="yellow">Média</Tag> : ''}
          {record.priority === 'high' ? <Tag color="red">Alta</Tag> : ''}
          {!record.priority ? (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => (
        <>
          {record.status === 'new' ? <Tag color="green">Novo</Tag> : ''}
          {record.status === 'working' ? (
            <Tag color="blue">Em Atendimento</Tag>
          ) : (
            ''
          )}
          {record.status === 'completed' ? (
            <Tag color="geekblue">Concluído</Tag>
          ) : (
            ''
          )}
          {record.status === 'canceled' ? <Tag color="red">Cancelado</Tag> : ''}
        </>
      ),
    },
    {
      title: translate('HelpDeskTable.dateColumn'),
      key: 'date',
      dataIndex: 'date_format',
    },
    {
      title: translate('HelpDeskTable.tasksColumn'),
      key: 'tasks',
      render: (record) => (
        <>
          {record.tasks.length === 0 ? (
            <Typography.Link href={`/ticket/${record._id}/tarefas`}>
              {translate('HelpDeskTable.anyTaskText')}
            </Typography.Link>
          ) : (
            <>
              {record.tasks.length === 1 ? (
                <Typography.Link href={`/ticket/${record._id}/tarefas`}>
                  {translate('HelpDeskTable.oneTaskText')}
                </Typography.Link>
              ) : (
                <Typography.Link href={`/ticket/${record._id}/tarefas`}>
                  {record.tasks.length}{' '}
                  {translate('HelpDeskTable.manyTaskText')}
                </Typography.Link>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('HelpDeskTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('HelpDeskTable.viewTooltip')}>
            <Link to={`/ticket/${record._id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('HelpDeskTable.deletePopconfirm')}
            onConfirm={() => deleteTicket(record._id)}
          >
            <Tooltip title={translate('HelpDeskTable.deleteTooltip')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        dataSource={ticketsFormat}
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default HelpDeskTable
