/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'

import dayjs from 'dayjs'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useAuth } from '../Auth/AuthContext'

interface EquipmentsTasks {
  id: string
}

interface TaskDashboardProps {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
  }
  equipments: EquipmentsTasks[]
}

interface FilterOptionsProps {
  responsibleContributors?: string
  responsibleTeams?: string
  address?: string
  customers_id?: string
  internal_code?: string
  current_status?: string
  equipment_id?: string
  external_code?: string
  task_type_id?: string
  priority?: string
  fromDate?: string | number | Date | dayjs.Dayjs | null | undefined
  toDate?: string | number | Date | dayjs.Dayjs | null | undefined
}

interface TaskDashboardContextData {
  tasks?: TaskDashboardProps[]
  isLoading: boolean
  refetch: () => void
  handleUpdateFiltersTask: (newFilterOptions: any) => void
  handleResetFiltersTask: () => void
  filterOptionsTask: FilterOptionsProps
  setTaskCollaborator(value: string): void
  taskCollaborator?: string
  isFetching: boolean
  setPageSize(value: any): void
  setNumberPage(value: any): void
  tasksTotal?: number
}

const TaskDashboardContext = createContext<TaskDashboardContextData>(
  {} as TaskDashboardContextData,
)

export const TaskDashboardProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { user } = useAuth()

  const [taskCollaborator, setTaskCollaborator] = useState<string>()

  const [pageSize, setPageSize] = useState<number>(10)
  const [numberPage, setNumberPage] = useState<number>(1)

  const [tasksTotal, setTasksTotal] = useState<number>()

  const [filterOptionsTask, setFilterOptionsTask] =
    useState<FilterOptionsProps>(() => {
      const filter = localStorage.getItem('@Gstor:tasksDashboardFilterOptions')

      if (filter) {
        return JSON.parse(filter)
      }

      return {} as FilterOptionsProps
    })

  const {
    data: tasks,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `Lista de Tarefas Dashboard${selectedCompany}`,
      selectedCompany,
      filterOptionsTask,
      pageSize,
      numberPage,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(`/company/${selectedCompany}/tasks`, {
          params: {
            offset: paginationOffset,
            limit: pageSize,
            role: user.role,
            rolePermission: user.rolePermission,
            responsibleContributors: filterOptionsTask?.responsibleContributors,
            responsibleTeams: filterOptionsTask?.responsibleTeams,
            address: filterOptionsTask?.address,
            customers_id: filterOptionsTask?.customers_id,
            internal_code: filterOptionsTask?.internal_code,
            current_status: filterOptionsTask?.current_status,
            equipment_id: filterOptionsTask?.equipment_id,
            external_code: filterOptionsTask?.external_code,
            task_type_id: filterOptionsTask?.task_type_id,
            priority: filterOptionsTask?.priority,
            fromDate: filterOptionsTask?.fromDate
              ? dayjs.utc(filterOptionsTask?.fromDate).format()
              : dayjs().startOf('day').format(),
            toDate: filterOptionsTask?.toDate
              ? dayjs.utc(filterOptionsTask?.toDate).format()
              : dayjs().endOf('day').format(),
          },
        })

        const { data } = response

        setTasksTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListTasks.getErrorMessage'),
          locale,
        )
      }
    },
  )

  const handleUpdateFiltersTask = useCallback((newFilterOptions) => {
    const newFilters = {
      responsibleContributors: newFilterOptions?.responsibleContributors,
      responsibleTeams: newFilterOptions?.responsibleTeams,
      address: newFilterOptions?.address,
      customers_id: newFilterOptions?.customers_id,
      internal_code: newFilterOptions?.internal_code,
      current_status: newFilterOptions?.current_status,
      equipment_id: newFilterOptions?.equipment_id,
      external_code: newFilterOptions?.external_code,
      task_type_id: newFilterOptions?.task_type_id,
      priority: newFilterOptions?.priority,
      fromDate: dayjs(newFilterOptions?.fromDate).startOf('day'),
      toDate: dayjs(newFilterOptions?.toDate).endOf('day'),
    }
    setFilterOptionsTask(newFilters)
    localStorage.setItem(
      '@Gstor:tasksDashboardFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFiltersTask = useCallback(() => {
    localStorage.removeItem('@Gstor:tasksDashboardFilterOptions')
    setFilterOptionsTask({})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <TaskDashboardContext.Provider
      value={{
        tasks,
        isLoading,
        refetch,
        handleUpdateFiltersTask,
        handleResetFiltersTask,
        filterOptionsTask,
        setTaskCollaborator,
        taskCollaborator,
        isFetching,
        setPageSize,
        setNumberPage,
        tasksTotal,
      }}
    >
      {children}
    </TaskDashboardContext.Provider>
  )
}

export function useTaskDashboard(): TaskDashboardContextData {
  const context = useContext(TaskDashboardContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
