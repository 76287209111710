/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { translate } from '~/utils/locale'

interface ILegend {
  getStatusStyle: (status: string | undefined) =>
    | {
        backgroundColor: string
        border: string
        color: string
      }
    | {
        backgroundColor: string
        color: string
        border?: undefined
      }
}

const Legend: React.FC<ILegend> = ({ getStatusStyle }) => {
  const statuses = [
    {
      key: 'received',
      label: translate('MaintenancePlanningTableLegend.statusReceived'),
    },
    {
      key: 'viewed',
      label: translate('MaintenancePlanningTableLegend.statusViewed'),
    },
    {
      key: 'en-route',
      label: translate('MaintenancePlanningTableLegend.statusEnRoute'),
    },
    {
      key: 'working',
      label: translate('MaintenancePlanningTableLegend.statusWorking'),
    },
    {
      key: 'check-in',
      label: translate('MaintenancePlanningTableLegend.statusCheckin'),
    },
    {
      key: 'break',
      label: translate('MaintenancePlanningTableLegend.statusBreak'),
    },
    {
      key: 'canceled',
      label: translate('MaintenancePlanningTableLegend.statusCanceled'),
    },
    {
      key: 'finished',
      label: translate('MaintenancePlanningTableLegend.statusFinished'),
    },
    {
      key: undefined,
      label: translate('MaintenancePlanningTableLegend.statusUndefined'),
    },
    {
      key: 'task-not-filled',
      label: translate('MaintenancePlanningTableLegend.taskNotFilled'),
    },
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        marginBottom: '20px',
      }}
    >
      {statuses.map(({ key, label }) => {
        const style = getStatusStyle(key)
        return (
          <div
            key={label}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {key === 'task-not-filled' ? (
              <span>X</span>
            ) : (
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  ...style,
                }}
              />
            )}
            <span
              style={{
                color: key === 'finished' ? 'black' : style.color,
              }}
            >
              {label}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default Legend
