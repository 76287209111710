/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState, useCallback } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

interface ActivityTasksProps {
  taskId: string
  created: Date
  status?: string
  plan: string
}

interface ContactsProps {
  phone: string
  _id: string
  responsible_name: string
}

interface ActivityInformationsProps {
  title: string
  system: string
  component: string
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  initialDate: string
  responsible: {
    id: string
    name: string
  }
  activity: string
  data_font: string
  competence: string
}

interface ActivityProps {
  _id: string
  company_id: string
  activity_id: string
  maintenance_id: string
  task_type_id: string
  is_automatic: boolean
  informations: ActivityInformationsProps
  tasks: ActivityTasksProps[]
  contacts?: ContactsProps[]
}

interface FilterOptionsProps {
  system?: string
  component?: string
  activity?: string
  competence?: string
  data_font?: string
  responsibleId?: string
  interval?: number
  type?: string
  hasTasks?: boolean
  fromDate?: dayjs.Dayjs
  toDate?: dayjs.Dayjs
}

interface ActivityTasksContextData {
  activityTasks?: ActivityProps[]
  createActivityTasks(valueForm: object | undefined): void
  updateActivityTasks(
    valueForm: object | undefined,
    activityTaskId: string | undefined,
  ): void
  createContacts(activityTaskId: string, valueForm: object | undefined): void
  deleteActivityTask(activityTaskId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  exportPlanningReportTasks: (
    selectedMonth: dayjs.Dayjs,
    maintenanceId: string,
  ) => void
  urlDownload?: string
  loading: boolean
  text: string
}

const ActivityTasksContext = createContext<ActivityTasksContextData>(
  {} as ActivityTasksContextData,
)

export const ActivityTasksProvider: React.FC = ({ children }) => {
  const { maintenanceId } = useParams<{ maintenanceId: string }>()

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:ActivityTasksMainFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })
  const [urlDownload, setUrlDownload] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(translate('useReportTasks.textExport'))

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const {
    data: activityTasks,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`activity tasks${selectedCompany}`, selectedCompany, filterOptions],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/activityTasks/${maintenanceId}`,
          {
            params: {
              system: filterOptions.system,
              component: filterOptions.component,
              activity: filterOptions.activity,
              competence: filterOptions.competence,
              data_font: filterOptions.data_font,
              responsibleId: filterOptions.responsibleId,
              interval: filterOptions.interval,
              type: filterOptions.type,
              hasTasks: filterOptions.hasTasks,
              fromDate: filterOptions?.fromDate
                ? dayjs.utc(filterOptions?.fromDate).format()
                : dayjs().subtract(3, 'months').startOf('day').format(),
              toDate: filterOptions?.toDate
                ? dayjs.utc(filterOptions?.toDate).format()
                : dayjs().add(3, 'months').startOf('day').format(),
            },
          },
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useSystem.getErrorMessage'), locale)
      }
    },
  )

  async function createActivityTasks(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/activityTasks`, valueForm)

      message.success(translate('useMaintenance.MaintenanceUpdatedSuccess'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceUpdatedError'),
        locale,
      )
    }
  }

  async function updateActivityTasks(
    valueForm: object,
    activityTaskId: string | undefined,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/activityTask/${activityTaskId}`,
        valueForm,
      )

      message.success(translate('useMaintenance.MaintenanceUpdatedSuccess'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceUpdatedError'),
        locale,
      )
    }
  }

  async function deleteActivityTask(activityTaskId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/activityTasks/${activityTaskId}`,
      )

      refetch()
      message.success(translate('useMaintenance.MaintenanceDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceDeletedError'),
        locale,
      )
    }
  }

  async function exportPlanningReportTasks(
    selectedMonth: dayjs.Dayjs,
    maintenanceId: string,
  ) {
    try {
      setLoading(true)
      setText(translate('useReportTasks.textLoading'))
      const response = await api.post(
        `company/${selectedCompany}/reports/${maintenanceId}/planning/tasks/export`,
        {},
        {
          params: {
            system: filterOptions.system,
            component: filterOptions.component,
            activity: filterOptions.activity,
            competence: filterOptions.competence,
            data_font: filterOptions.data_font,
            responsibleId: filterOptions.responsibleId,
            interval: filterOptions.interval,
            type: filterOptions.type,
            hasTasks: filterOptions.hasTasks,
            fromDate: filterOptions?.fromDate
              ? dayjs.utc(filterOptions?.fromDate).format()
              : dayjs().subtract(3, 'months').startOf('day').format(),
            toDate: filterOptions?.toDate
              ? dayjs.utc(filterOptions?.toDate).format()
              : dayjs().add(3, 'months').startOf('day').format(),
            selectedMonth: selectedMonth.format(),
          },
        },
      )
      setUrlDownload(response.data.url)
      setText(translate('useReportTasks.textDownload'))
      setLoading(false)
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useReportTasks.exportErrorMessage'),
        locale,
      )

      setLoading(false)
      setText(translate('useReportTasks.textExport'))
    }
  }

  async function createContacts(activityTaskId: string, valueForm: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/activityTask/${activityTaskId}/contacts`,
        valueForm,
      )

      message.success('Contato criado com sucesso!')
      refetch()
    } catch (err: any) {
      ShowError(err.message, 'Houve um erro ao criar o contato', locale)
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      system: newFilterOptions.system,
      component: newFilterOptions.component,
      activity: newFilterOptions.activity,
      competence: newFilterOptions.competence,
      data_font: newFilterOptions.data_font,
      responsibleId: newFilterOptions.responsibleId,
      interval: newFilterOptions.interval,
      type: newFilterOptions.type,
      hasTasks: newFilterOptions.hasTasks,
      fromDate: dayjs(newFilterOptions?.fromDate).startOf('day'),
      toDate: dayjs(newFilterOptions?.toDate).endOf('day'),
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:ActivityTasksMainFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:ActivityTasksMainFilterOptions')
    setFilterOptions({})
  }, [])

  return (
    <ActivityTasksContext.Provider
      value={{
        activityTasks,
        createActivityTasks,
        updateActivityTasks,
        deleteActivityTask,
        createContacts,
        isLoading,
        refetch,
        isFetching,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        exportPlanningReportTasks,
        urlDownload,
        loading,
        text,
      }}
    >
      {children}
    </ActivityTasksContext.Provider>
  )
}

export function useActivityTasks(): ActivityTasksContextData {
  const context = useContext(ActivityTasksContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
