/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState, useCallback } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface DatabookProps {
  _id: string
  name: string
  provided_responsible: string
  renovation_responsible: string
  inclusion_date: Date
  renovation_date: Date
  attchment: string
  is_exclusive: boolean
  plan_id: string
  tag: string
}

interface FilterOptionsProps {
  name?: string
  provided_responsible?: string
  renovation_responsible?: string
  plan_id?: string
  tag?: string
}

interface DatabookContextData {
  databook?: DatabookProps[]
  createDatabook(valueForm: object | undefined): void
  updateDatabook(valueForm: object | undefined, databookId: string): void
  deleteDatabook(databookId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  databookTotal?: number
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
}

const DatabookContext = createContext<DatabookContextData>(
  {} as DatabookContextData,
)

export const DatabookProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:databookPrincipalFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [databookTotal, setDatabookTotal] = useState<number>()

  const {
    data: databook,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `databook/gestao${selectedCompany}`,
      selectedCompany,
      databookTotal,
      filterOptions,
    ],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/databooks`,
          {
            params: {
              name: filterOptions?.name,
              provided_responsible: filterOptions?.provided_responsible,
              renovation_responsible: filterOptions?.renovation_responsible,
              plan_id: filterOptions?.plan_id,
              tag: filterOptions?.tag,
            },
          },
        )

        const { data } = response

        setDatabookTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useDatabook.getErrorMessage'), locale)
      }
    },
  )

  async function createDatabook(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/databook`, valueForm)

      refetch()
      message.success(translate('useDatabook.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useDatabook.createErrorMessage'),
        locale,
      )
    }
  }

  async function updateDatabook(valueForm: object, databookId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/databook/${databookId}`,
        valueForm,
      )

      message.success(translate('useDatabook.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useDatabook.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteDatabook(databookId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/databook/${databookId}`)

      refetch()
      message.success(translate('useDatabook.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useDatabook.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      name: newFilterOptions?.name,
      provided_responsible: newFilterOptions?.provided_responsible,
      renovation_responsible: newFilterOptions?.renovation_responsible,
      plan_id: newFilterOptions?.plan_id,
      tag: newFilterOptions?.tag,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:databookPrincipalFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:databookPrincipalFilterOptions')
    setFilterOptions({})
  }, [])

  return (
    <DatabookContext.Provider
      value={{
        databook,
        createDatabook,
        updateDatabook,
        deleteDatabook,
        isLoading,
        refetch,
        isFetching,
        databookTotal,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
      }}
    >
      {children}
    </DatabookContext.Provider>
  )
}

export function useDatabook(): DatabookContextData {
  const context = useContext(DatabookContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
