/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Select,
  Input,
  Tooltip,
  DatePicker,
} from 'antd'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { Container } from './styles'
import { useDashboard } from '~/hooks/Dashboard/useDashboard'

import { translate } from '~/utils/locale'
import { useTaskDashboard } from '~/hooks/Dashboard/useTaskDashboard'
import { useListClients } from '~/hooks/Clients/useListClients'

const TaskDashboardFilter: React.FC = () => {
  const { filterOptions } = useDashboard()

  const { filterOptionsTask, handleResetFiltersTask, handleUpdateFiltersTask } =
    useTaskDashboard()

  const { clients } = useListClients()

  const [open, setOpen] = useState(false)

  const [form] = Form.useForm()

  const { Option } = Select

  const statusOptions = [
    {
      label: translate('TasksFilter.statusReceived'),
      value: 'received',
      id: 1,
    },
    { label: translate('TasksFilter.statusViewed'), value: 'viewed', id: 2 },
    { label: translate('TasksFilter.statusEnRoute'), value: 'en-route', id: 3 },
    { label: translate('TasksFilter.statusBreak'), value: 'break', id: 4 },
    {
      label: translate('TasksFilter.statusCanceled'),
      value: 'canceled',
      id: 5,
    },
    {
      label: translate('TasksFilter.statusFinished'),
      value: 'finished',
      id: 6,
    },
    { label: translate('TasksFilter.statusWorking'), value: 'working', id: 7 },
  ]

  useEffect(() => {
    form.setFieldsValue({
      fromDate: dayjs(filterOptionsTask?.fromDate),
      toDate: dayjs(filterOptionsTask?.toDate),
      customers_id: filterOptionsTask?.customers_id,
      internal_code: filterOptionsTask?.internal_code,
      current_status: filterOptionsTask?.current_status,
      external_code: filterOptionsTask?.external_code,
      priority: filterOptionsTask?.priority,
    })
  }, [filterOptions, filterOptionsTask, form])

  const onFinish = (dataFilter: any) => {
    handleUpdateFiltersTask(dataFilter)
    setOpen(false)
  }

  const onReset = () => {
    handleResetFiltersTask()
    form.resetFields()
    setOpen(false)
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const isFilter = JSON.parse(
    localStorage.getItem('@Gstor:tasksDashboardFilterOptions')!,
  )

  return (
    <Container isFilter={isFilter}>
      <Button
        type="primary"
        onClick={showDrawer}
        icon={
          !isFilter ? (
            <FilterOutlined />
          ) : (
            <Tooltip title={translate('DashboardFilter.cleanFilter')}>
              <CloseOutlined onClick={onReset} />
            </Tooltip>
          )
        }
        id="btn_filter_dashboard"
      >
        {isFilter
          ? translate('DashboardFilter.filterAppliedText')
          : translate('DashboardFilter.filterText')}
      </Button>
      <Drawer
        forceRender
        title={translate('DashboardFilter.drawerTitle')}
        width={580}
        onClose={onClose}
        open={open}
        style={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.fromDateLabel')}
                name="fromDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.fromDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.toDateLabel')}
                name="toDate"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder={translate('TasksFilter.toDatePlaceholder')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.clientLabel')}
                name="customers_id"
              >
                <Select
                  placeholder={translate('TasksFilter.clientPlaceholder')}
                >
                  {clients?.map((client) => (
                    <Option value={client.id} key={client.id}>
                      {client.contact.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.statusLabel')}
                name="current_status"
              >
                <Select
                  placeholder={translate('TasksFilter.statusPlaceholder')}
                >
                  {statusOptions.map((status) => (
                    <Select.Option value={status.value} key={status.id}>
                      {status.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.internalCodeLabel')}
                name="internal_code"
              >
                <Input
                  placeholder={translate('TasksFilter.internalCodePlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.externalCodeLabel')}
                name="external_code"
              >
                <Input
                  placeholder={translate('TasksFilter.externalCodePlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={translate('TasksFilter.priorityLabel')}
                name="priority"
              >
                <Select
                  placeholder={translate('TasksFilter.priorityPlaceholder')}
                >
                  <Option value="high">
                    {translate('TasksFilter.priorityHigh')}
                  </Option>
                  <Option value="medium">
                    {translate('TasksFilter.priorityMedium')}
                  </Option>
                  <Option value="low">
                    {translate('TasksFilter.priorityLow')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  {translate('DashboardFilter.applyButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ width: '100%' }}>
                <Button style={{ width: '100%' }} onClick={onReset}>
                  {translate('DashboardFilter.cleanButton')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Container>
  )
}

export default TaskDashboardFilter
