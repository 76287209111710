export default {
  pt: {
    useDatabook: {
      getErrorMessage: 'Erro ao visualizar os documentos.',
      createSuccessMessage: 'Documento criado com sucesso!',
      createErrorMessage: 'Erro ao criar um documento.',
      updateSuccessMessage: 'Documento atualizado com sucesso!',
      updateErrorMessage: 'Erro ao atualizar o documento.',
      deleteSuccessMessage: 'Documento deletado com sucesso!',
      deleteErrorMessage: 'Erro ao deletar o documento.',
    },
  },
  en: {
    useDatabook: {
      getErrorMessage: 'Error viewing documents.',
      createSuccessMessage: 'Document created successfully!',
      createErrorMessage: 'Error creating the document.',
      updateSuccessMessage: 'Document updated successfully!',
      updateErrorMessage: 'Error updating document.',
      deleteSuccessMessage: 'Document deleted successfully!',
      deleteErrorMessage: 'Error deleting document.',
    },
  },
  es: {
    useDatabook: {
      getErrorMessage: 'Error al ver los documentos.',
      createSuccessMessage: 'Documento creado con éxito!',
      createErrorMessage: 'Error al crear documento.',
      updateSuccessMessage: 'Documento actualizado con éxito!',
      updateErrorMessage: 'Error al actualizar el documento.',
      deleteSuccessMessage: 'Documento eliminado con éxito!',
      deleteErrorMessage: 'Error al eliminar el documento.',
    },
  },
}
